/* eslint-disable */
import { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import { EntitiesEnum } from "../../../Services/LogService";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import MasterHistory from "../MasterHistory";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";

const { Search } = Input;
const Department = (props: any) => {
  const { getDepartment } = useContext(ApplicationContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [departmentForm] = Form.useForm();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(true);
  const [show, setShow] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const [exist, setExist] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [empty, setIsEmpty] = useState<any>("success");
  const [fieldChange, setFieldChange] = useState<boolean>(false);

  const [listParams, setListParams] = useState<any>({
    departmentSearch: "",
    company: "",
    start: 0,
    length: 15,
  });
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getDepartment1 = (listParams: any) => {
    setLoading(true);
    MasterService.getDepartmentOptions(
      listParams.departmentSearch,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r?.id,
            name: r?.name,
            value: r?.id,
            recordStatus: r?.recordStatus,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setLoading(false);
    setShowDrawer("");
    setFieldChange(false);
    departmentForm.resetFields();
  };
  // Message on Submit

  useEffect(() => {
    inputRef.current?.focus();
  }, [exist, shouldRefresh]);

  useEffect(() => {
    setLoading(true);
    getDepartment1(listParams);
    // getUnits();
  }, [shouldRefresh, listParams]);

  const saveDepartment = (saveTog: boolean) => {
    departmentForm.validateFields().then((values) => {
      MasterService.postDepartment(values).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            onDismiss();
          } else {
            departmentForm.resetFields();
            setShouldRefresh(!shouldRefresh);
          }
        } else {
          errorMsg(res?.message);
        }
        // if (res?.status) {
        //   info("Added Successfully");
        //   onDismiss();
        //   departmentForm.resetFields();
        // } else {
        //   errorMsg(res?.message);
        // }
      });
    });
  };

  const searchDepartment = (e: any) => {
    setListParams({
      departmentSearch: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };

  const editRowData = async (item: any) => {
    MasterService.getDepartment(item?.id).then(async (res: any) => {
      setShow(true);
      setUpdateToggle(false);
      setEditData(res);
      // departmentForm.setFieldsValue({ ...item });
      await MasterService.isExist(Masters.Department, item.id).then((res) => {
        if (res === true) {
          setExist(true);
        } else {
          setExist(false);
        }
      });
      departmentForm.setFieldsValue(res);
    });
  };

  const updateData = async () => {
    let values = departmentForm.getFieldsValue(true);
    if (fieldChange) {
      departmentForm.validateFields().then(async () => {
        let val = {
          id: values?.id,
          name: values?.name.toString(),
        };
        await MasterService.postDepartment(val).then((res: any) => {
          if (res?.message) {
            messageApi.error(res?.message);
          } else {
            setSelectedRowKeys("");
            onDismiss();
            setUpdateToggle(true);
            info("Updated Successfully");
            // getDepartment();
          } // getDepartment1(); call Application state 's  getDepartment()
        });
      });
    } else {
      setShow(false);
    }

    // let a = departmentForm.isFieldValidating("name");
    // console.log(a);

    // MasterService.postUnit(val).then(() => {
    //   onDismiss();
    //   setUpdateToggle(true);
    //   info("Updated Successfully");
    // });
  };

  const deleteList = (listId: any) => {
    MasterService.getExistDepartment(Masters.Department, listId).then((res) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getDepartment(listId).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteDepartment(listId).then((res: any) => {
              // errorMsg("Department is Deleted Successfully");
              messageApi.success("Department is deleted successfully.");
              onDismiss();
            });
          } else {
            messageApi.warning(
              "Department is added by admin  can't be deleted"
            );
          }
        });
      }
    });
  };
  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Department Is Assigned  Can't be deleted",
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          {/* <span className="searchDivStyle ms-2">DEPARTMENT </span> */}

          <Search
            placeholder="Department"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchDepartment(e)}
            size="small"
            bordered={false}
          />
        </>
      ),
      dataIndex: "name",
      render: (itm: any, record: any) => (
        <span
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
      width: 240,
    },
    {
      title: (
        <>
          <div className="">Date</div>
        </>
      ),

      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: "  ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            {/* Edit */}
          </Button>
          {
            // <Button icon={<DeleteOutlined />} type="text" danger onClick={() => {
            //   deleteList(record.id)
            // }}>
            //   Delete
            // </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteList(record.id)}
              okText="Yes"
              cancelText="No"
              placement="top"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} type="text" danger size="small">
                {/* Delete */}
              </Button>
            </Popconfirm>
          }
          {
            <Button
              type="text"
              icon={<HistoryOutlined />}
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
              size="small"
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === departmentForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };
  return (
    <>
      {contextHolder}
      <Card
        title={"Department"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {/* <Row style={{ marginBottom: "10px" }} justify="end">
        <Col>
          <Button type="primary">Add New</Button>
        </Col>
      </Row> */}

        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={false}
          maskClosable={false}
          onCancel={() => {
            setSelectedRowKeys("");
            setShow(false);
            setUpdateToggle(true);
            departmentForm.resetFields();
          }}
          zIndex={1005}
          title={
            updateToggle === true ? (
              "Add Department"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Department
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Department"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag> */}
              </>
            )
          }
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subUnit();
          // }}
          footer={
            <>
              {updateToggle === true ? (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setSelectedRowKeys("");
                        setShow(false);
                        departmentForm.resetFields();
                        setUpdateToggle(true);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        saveDepartment(true);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        saveDepartment(false);
                      }}
                    >
                      Save and New
                    </Button>
                  </Space>
                </div>
              ) : (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        departmentForm.resetFields();
                        setUpdateToggle(true);
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>

                    <Button
                      type="primary"
                      ghost
                      onClick={() => updateData()}
                      disabled={!editData?.isEditAllowed}
                    >
                      Update
                    </Button>
                  </Space>
                  {/* )} */}
                  {/* <Popconfirm
                  title={
                    exist === true
                      ? "Do you wan't to update this assigned Unit"
                      : "Update Unit"
                  }
                  // onConfirm={() =>  }
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  onConfirm={() => updateData()}
                  onCancel={() => {
                    setShow(false);
                    departmentForm.resetFields();
                    setUpdateToggle(true);
                  }}
                >
                  <Button type="primary" ghost>
                    Update
                  </Button>
                </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="departmentBasic"
            form={departmentForm}
            size="large"
            layout="vertical"
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="InvAddForm"
          >
            <Form.Item
              label="Enter Department"
              name="name"
              rules={[
                // {
                //   pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                //   message:
                //     "Spaces, digits, and special symbols are not allowed at the beginning or end of the value.",
                // },
                // {
                //   required: true,
                //   message: "Fill the field",
                // },
                // {
                //   pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                //   message:
                //     "Spaces, digits, and special symbols are not allowed  ",
                // },

                {
                  required: true,
                  message: "Please enter department",
                },
                {
                  whitespace: true,
                },
              ]}
            >
              <Input ref={inputRef} onBlur={() => CheckChanges("name")} />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Department}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Department;
