import { Axios } from "axios";
import { useQuery } from "@tanstack/react-query";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
const DASHBOARD_BASE_URL = `${process.env.REACT_APP_BASE_URL}/v1/api/IDashboard`;

export const getDashboardList = async () =>
  await ApiUtility.getResult(`${DASHBOARD_BASE_URL}`, {});

export const useGetDashboard = () => {
  return useQuery({
    queryKey: ["useGetDashboard"],
    queryFn: () => getDashboardList(),
  });
};
export const getProductList = async (payload: any) =>
  await ApiUtility.getResult(`${DASHBOARD_BASE_URL}/GetProducts`, {
    ...payload,
  });

export const useGetProducts = (payload: any = undefined) => {
  return useQuery({
    queryKey: ["useGetProducts", payload],
    queryFn: () => getProductList(payload),
  });
};
export const getExpiryList = async (payload: any) =>
  await ApiUtility.getResult(`${DASHBOARD_BASE_URL}/ExpiryList`, {
    ...payload,
  });

export const useExpiryList = (payload: any = undefined) => {
  return useQuery({
    queryKey: ["useExpiryList", payload],
    queryFn: () => getExpiryList(payload),
  });
};

export const getDashbordChart = async () => {
  return await ApiUtility.getResult(`${DASHBOARD_BASE_URL}/GetChartsData`);
};
export const useDashBoardChart = () => {
  return useQuery({
    queryKey: ["useDashBoardChart"],
    queryFn: () => getDashbordChart(),
  });
};

export const getInstalledApp = async () => {
  return await ApiUtility.getResult(`${DASHBOARD_BASE_URL}/GetInstalled`);
};
export const useInstalledApp = () => {
  return useQuery({
    queryKey: ["useInstalledApp"],
    queryFn: () => getInstalledApp(),
  });
};

export const useGetPcConfigReport = (payload: any = undefined) => {
  return useQuery({
    queryKey: ["useGetPcConfigReport", payload],
    queryFn: () => getGetPcConfigReport(payload),
  });
};
export const getGetPcConfigReport = async (payload: any) =>
  await ApiUtility.getResult(`${DASHBOARD_BASE_URL}/GetPcConfig`, {
    ...payload,
  });
