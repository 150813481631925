import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  message,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import { AuthService } from "../../../Services/AuthService";
import MasterService from "../../Services/MasterService";
import { dayjs } from "../../../Utilities/dayjs";
interface IEditUserProps {
  userData: any;
  setIsDataSaved: any;
  // catData: any;
  setShouldRefresh: any;
  onDismiss: (rec: boolean) => void;
}

const EditUser = (props: IEditUserProps) => {
  console.log("props", props);

  const { userRoles } = useContext(ApplicationContext);
  const [enableEdit, setEnableEdit] = useState(false);
  const [editData, setEditData] = useState<any>({});
  const [status, setStatus] = useState<number>(-1);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [catData, setCatData] = useState([]);
  const getUserCategory = () => {
    //  setLoading("table");
    MasterService.getUserCategoryList().then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            label: r?.name,
            value: r?.id,
          });
        });
        setCatData(data);
      }
    });
  };
  useEffect(() => {
    setEditData({
      ...props.userData,
    });
    getUserCategory();
  }, [props.userData]);

  useEffect(() => {
    form.setFieldsValue({
      ...editData,
      userCategory: {
        label: editData?.userCategory?.name,
        value: editData?.userCategory?.id,
      },
      endDate: editData?.status === 1 ? dayjs(editData?.endDate) : undefined,
    });
    setStatus(editData?.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, enableEdit]);

  const saveUpdatedData = () => {
    form.validateFields().then((values: any) => {
      let user: any = {
        ...values,
        role: values?.role,
        status: values?.status,
        id: props?.userData?.id,
        updateId: props?.userData?.id,
        userCategory: {
          id: values?.userCategory?.value,
          name: values?.userCategory?.label,
        },
        name: {
          first: values?.name,
          last: "",
        },
      };

      AuthService.postUser(user).then((res: any) => {
        if (res?.data?.result) {
          messageApi.success("Updated Successfully", 1);
          form.resetFields();
          props?.setIsDataSaved(true);
          props?.setShouldRefresh((x: boolean) => !x);
          setTimeout(() => props.onDismiss(true), 1000);
        } else messageApi.error(res?.data?.message);
      });
    });
  };
  // console.log("DataDataDataDataData = ", form.getFieldValue("status"));
  console.log("editDataeditDataeditDataeditData = ", editData);

  return (
    <>
      {contextHolder}
      <Row justify={"space-between"} align={"bottom"} className="">
        <Col className="font16 fw600">Basic Details</Col>
        {enableEdit ? (
          <Col>
            <Button
              icon={<SaveOutlined />}
              size="small"
              className="border-none font16"
              onClick={saveUpdatedData}
            />
            <Button
              icon={<CloseOutlined />}
              size="small"
              className="border-none font16"
              onClick={() => {
                setEnableEdit(false);
              }}
            />
          </Col>
        ) : (
          <Col>
            <Button
              icon={<EditOutlined />}
              size="small"
              className="border-none font16"
              onClick={() => setEnableEdit(true)}
            />
          </Col>
        )}
      </Row>

      <Form
        form={form}
        colon={false}
        size="small"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 14 }}
        className="InvDForm"
        autoComplete="off"
        requiredMark={false}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter user email",
            },
          ]}
        >
          {enableEdit ? <Input id="name" autoFocus /> : editData?.name}
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              message: "Please select user role",
            },
          ]}
        >
          {enableEdit ? (
            <Select allowClear options={userRoles} />
          ) : (
            editData?.role
          )}
        </Form.Item>
        <Form.Item
          label="User Category"
          name="userCategory"
          rules={[
            {
              required: true,
              message: "Please select user category",
            },
          ]}
        >
          {enableEdit ? (
            <Select allowClear labelInValue options={catData} />
          ) : (
            editData?.userCategory?.name
          )}
        </Form.Item>
        <Form.Item
          label="Active"
          name="status"
          initialValue={
            editData?.status === 0
              ? { label: "Active", value: 0 }
              : { label: "InActive", value: 1 }
          }
        >
          {enableEdit ? (
            <Select
              onSelect={(value: any) => {
                console.log("DataDataDataDataData = ", value);
                setStatus(value);
              }}
              allowClear
              options={[
                {
                  label: "Active",
                  value: 0,
                },
                {
                  label: "InActive",
                  value: 1,
                },
              ]}
            />
          ) : editData?.status === 0 ? (
            "Active"
          ) : (
            "InActive"
          )}
        </Form.Item>
        <Form.Item label="Email" name="email">
          {enableEdit ? <Input readOnly /> : editData?.email}
        </Form.Item>

        <Form.Item label="Phone Number" name="phoneNumber">
          {enableEdit ? <Input /> : editData?.phoneNumber}
        </Form.Item>

        <Form.Item label="Address" name="address">
          {enableEdit ? <Input /> : editData?.address}
        </Form.Item>
        {status === 1 ? (
          <Form.Item
            label="Inactive Date"
            name="endDate"
            rules={[
              {
                required: true,
                message: "this field is required",
              },
            ]}
          >
            {enableEdit ? (
              <DatePicker style={{ width: "100%" }} />
            ) : (
              dayjs(editData?.endDate)?.format("DD/MM/YYYY")
            )}
          </Form.Item>
        ) : null}
        <Form.Item
          label="Show in Attendance"
          name="isAttendance"
          valuePropName="checked"
        >
          {enableEdit ? (
            <Checkbox />
          ) : editData?.isAttendance ? (
            <CheckOutlined style={{ color: "green" }} />
          ) : (
            <CloseOutlined style={{ color: "red" }} />
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default EditUser;
