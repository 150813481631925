import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  // InputNumber,
  InputRef,
  Row,
  Select,
  Space,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { CloseOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ProductService, {
  Category,
  StockType,
} from "../../Services/ProductService";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import "../../Common/Common.css";
// import { dayjs } from "../../../Utilities/dayjs";
import MasterService from "../../Services/MasterService";
import { LabelValueModel } from "../../Context/ApplicationContext/ApplicationState";
const { Option } = Select;

interface IAddProductsProps {
  seatingOptions: any;
  category: Category;
  subCategoryOptions: any;
  onDismiss: (rec: boolean) => void;
  getSubCategory: () => Promise<void>;
  getSeatingOptions: () => Promise<void>;
  setShouldRefresh: () => void;
}

const AddInOption = ({
  funcType,
  category,
  getSubCategory,
  variant,
  getVariantOptions,
  getSeatingOptions,
}: any) => {
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const { getVariants, getAssigneeOptions } = useContext(ApplicationContext);
  const [status, setStatus] = useState<any>("success");
  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "varType")
        MasterService.postVariant({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error(r?.message);
          } else {
            getVariants();
            setName("");
            inputRef.current?.focus();
          }
        });
      else if (funcType === "seating")
        MasterService.postSeating({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error(r?.message);
          } else {
            getSeatingOptions("");
            setName("");
            inputRef.current?.focus();
          }
        });
      else if (funcType === "assignee")
        MasterService.postAssignee({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error(r?.message);
          } else {
            getAssigneeOptions();
            setName("");
            inputRef.current?.focus();
          }
        });
      else if (funcType === "subCategory")
        MasterService.postSubCategory({ name: name, category: category }).then(
          (r: any) => {
            getSubCategory();
            setName("");
            inputRef.current?.focus();
          }
        );
      else if (funcType === "varOption")
        MasterService.postOption({ name: name, variant: variant }).then(
          (r: any) => {
            if (r?.message) {
              message.error(r?.message);
            } else {
              setName("");
              getVariantOptions(variant.name);
              getVariants();
              inputRef.current?.focus();
            }
          }
        );
    }
  };
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 0px 4px" }} className="InvAddForm">
        <Input
          placeholder="Enter Option"
          ref={inputRef}
          value={name}
          status={status}
          onChange={onNameChange}
          onPressEnter={addOption}
        />
        <Button type="text" icon={<PlusOutlined />} onClick={addOption} />
      </Space>
    </>
  );
};
const AddDigitalProduct = (props: IAddProductsProps) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  let form1Values = false;
  const [variantList, setVariantList] = useState<any[]>([]);
  const [variantOptions, setVariantOptions] = useState<any[]>([]);
  const [productGrpOptions, setProductGrpOptions] = useState<any[]>([]);
  const [subCategory, setSubCategory] = useState<LabelValueModel>({
    label: "",
    value: "",
  });
  const [defaultVariants, setDefaultVariants] = useState<any[]>([
    { type: { value: "", label: "" } },
  ]);
  const [isChecked, setIsChecked] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const [form1] = Form.useForm();
  const [formPurchase] = Form.useForm();
  const [formVariant] = Form.useForm();
  const inputRef = React.useRef<any>(null);
  const selectRef = React.useRef<any>(null);

  const {
    variantTypes,
    // vendorOptions,
    // disabledDate,
    assigneeOptions,
    // getVendorOptions,
  } = useContext(ApplicationContext);

  const getVariantOptions = (variantSearch: string) => {
    MasterService.getOption(variantSearch, 0, 20, false).then((res: any) => {
      setVariantOptions(
        res?.items?.map((r: any) => ({
          label: r.name,
          value: r.id,
        }))
      );
    });
  };
  const varOption = (type: any) => {
    let varOpts = variantTypes.filter((f: any) => f?.value.includes(type));
    let opt = varOpts[0]?.varOptions?.map((res: any) => ({
      label: res?.name,
      value: res?.id,
    }));
    return opt;
  };

  const updateDefaultVariants = (op: any) => {
    if (op?.defaultVariants?.length === 0 || op?.defaultVariants === null)
      setDefaultVariants([{ type: { label: " ", value: " " } }]);
    else {
      setDefaultVariants(
        op?.defaultVariants?.map((itm: any) => ({
          type: { label: itm.name, value: itm.id },
        }))
      );
    }
  };
  const clearSelect = (field: string) => {
    if (field === "productGroup")
      form1.setFieldValue("productGroup", undefined);
    if (field === "varOption") form1.setFieldValue("varOption", undefined);
  };

  const checkSeating = async (rule: any, value: any) => {
    await ProductService.verifySeating(subCategory?.value, value?.value).then(
      (res: any) => {
        if (res?.result === true) {
          throw new Error("Seat already allotted.");
        }
      }
    );
  };

  // const onContinueForm1 = () => {
  //   form1.validateFields().then((res) => {
  //     setForm1Values(res);
  //     isChecked ? setActiveKey("3") : setActiveKey("2");
  //   });
  // };
  // const onContinueVariant = () => {
  //   formVariant.validateFields().then((res) => {
  //     setVariantList(res?.variants);
  //     setActiveKey("3");
  //   });
  // };
  const resetForm = () => {
    // formPurchase.resetFields();
    formVariant.resetFields();
    form1.resetFields();
    if (isChecked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    formVariant.setFieldValue("variants", [{ type: { value: "", label: "" } }]);

    props.setShouldRefresh();
    formVariant.setFieldValue("variants", []);
    setVariantList([]);
    setActiveKey("1");
  };

  // const onSave = (onlySave: boolean) => {
  //   formPurchase.validateFields().then((val: any) => {
  //     let values = {
  //       ...form1Values,
  //       ...val,
  //       subCategory: {
  //         id: form1Values?.subCategory?.value,
  //         name: form1Values?.subCategory?.label,
  //       },
  //       vendor: {
  //         id: val?.vendor?.value,
  //         name: val?.vendor?.label,
  //       },
  //       category: props.category,
  //       stockType: StockType.SingleStock,
  //       variantType: {
  //         id: form1Values?.varType?.value,
  //         name: form1Values?.varType?.label,
  //       },
  //       variantOption: {
  //         id: form1Values?.varOption?.value,
  //         name: form1Values?.varOption?.label,
  //       },
  //       seatAllotted: {
  //         id: form1Values?.seatAllotted?.value,
  //         name: form1Values?.seatAllotted?.label,
  //       },
  //       productGroup: {
  //         id: form1Values?.productGroup?.value,
  //         name: form1Values?.productGroup?.label,
  //       },

  //       assignee:
  //         form1Values?.assignee != null
  //           ? [
  //               {
  //                 id: form1Values?.assignee?.value,
  //                 name: form1Values?.assignee?.label,
  //               },
  //             ]
  //           : null,
  //       digiStatus: form1Values?.assignee != null ? 1 : 0,
  //     };
  //     if (Object.keys(form1Values).length === 0)
  //       error("Dashboard fields are required!");
  //     else {
  //       setButtonDisable(true);
  //       ProductService.post(values).then((res: any) => {
  //         console.log(res, "res");

  //         if (res?.status && !isChecked) {
  //           onSaveVariant(onlySave, res?.result?.id);
  //         } else if (res?.status) {
  //           info(`Product added successfully.`);
  //           setTimeout(() => {
  //             onlySave ? props.onDismiss(true) : resetForm();
  //           }, 500);
  //           props.setShouldRefresh((x) => !x);
  //           setIsChecked(false);
  //         } else {
  //           error(res?.message);
  //         }
  //         setButtonDisable(false);
  //       });
  //     }
  //   });
  // };
  // const onSaveVariant = (onlySave: boolean, referenceId: string) => {
  //   formPurchase.validateFields().then(async (values) => {
  //     let val = {
  //       ...values,
  //       // ...form1Values,
  //       variants:
  //         variantList && variantList.length > 0
  //           ? variantList?.map(({ type, option, ...rest }) => ({
  //               ...rest,
  //               type: { id: type?.value, name: type?.label },
  //               option: { id: option?.value, name: option?.label },
  //             }))
  //           : null,
  //       subCategory: {
  //         id: form1Values?.subCategory?.value,
  //         name: form1Values?.subCategory?.label,
  //       },
  //       vendor: {
  //         id: values?.vendor?.value,
  //         name: values?.vendor?.label,
  //       },
  //       category: props.category,
  //       stockType: StockType.Variant,
  //       referenceId: referenceId,
  //     };
  //     await ProductService.post(val).then((res) => {
  //       if (res?.status) {
  //         info(`Product added successfully.`);
  //         setTimeout(() => {
  //           onlySave ? props.onDismiss(true) : resetForm();
  //         }, 500);
  //       } else {
  //         error(res?.message);
  //       }
  //       setButtonDisable(false);
  //     });
  //   });
  // };
  // const onSave = (onlySave: boolean) => {
  //   form1.validateFields().then((val: any) => {
  //     let values = {
  //       ...val,
  //       subCategory: {
  //         id: val?.subCategory?.value,
  //         name: val?.subCategory?.label,
  //       },
  //       vendor: {
  //         id: val?.vendor?.value,
  //         name: val?.vendor?.label,
  //       },
  //       category: props.category,
  //       stockType: StockType.SingleStock,
  //       variantType: {
  //         id: val?.varType?.value,
  //         name: val?.varType?.label,
  //       },
  //       variantOption: {
  //         id: val?.varOption?.value,
  //         name: val?.varOption?.label,
  //       },
  //       seatAllotted: {
  //         id: val?.seatAllotted?.value,
  //         name: val?.seatAllotted?.label,
  //       },
  //       productGroup: {
  //         id: val?.productGroup?.value,
  //         name: val?.productGroup?.label,
  //       },

  //       assignee:
  //         val?.assignee != null
  //           ? [
  //               {
  //                 id: val?.assignee?.value,
  //                 name: val?.assignee?.label,
  //               },
  //             ]
  //           : null,
  //       digiStatus: val?.assignee != null ? 1 : 0,
  //     };
  //     formVariant.validateFields().then((res: any) => {
  //       console.log(res, "res");
  //       setForm1Values(true);
  //       console.log("1");
  //     });
  //     console.log("2");
  //     setButtonDisable(true);

  //     if (form1Values === false) {
  //       error("Variant  fields are required!");
  //       console.log("3");

  //       setButtonDisable(false);
  //     } else {
  //       console.log("4");
  //       ProductService.post(values).then((res: any) => {
  //         if (res?.status && !isChecked) {
  //           onSaveVariant(onlySave, res?.result?.id);
  //         } else if (res?.status) {
  //           info(`Product added successfully.`);
  //           setTimeout(() => {
  //             onlySave ? props.onDismiss(true) : resetForm();
  //           }, 500);
  //           // props.setShouldRefresh();
  //           setIsChecked(false);
  //         } else {
  //           error(res?.message);
  //         }
  //         setButtonDisable(false);
  //       });
  //     }
  //   });
  // };
  const onSave = (onlySave: boolean) => {
    form1.validateFields().then((val: any) => {
      let values = {
        ...val,
        subCategory: {
          id: val?.subCategory?.value,
          name: val?.subCategory?.label,
        },
        vendor: {
          id: val?.vendor?.value,
          name: val?.vendor?.label,
        },
        category: props.category,
        stockType: StockType.SingleStock,
        variantType: {
          id: val?.varType?.value,
          name: val?.varType?.label,
        },
        variantOption: {
          id: val?.varOption?.value,
          name: val?.varOption?.label,
        },
        seatAllotted: {
          id: val?.seatAllotted?.value,
          name: val?.seatAllotted?.label,
        },
        productGroup: {
          id: val?.productGroup?.value,
          name: val?.productGroup?.label,
        },

        assignee:
          val?.assignee != null
            ? [
                {
                  id: val?.assignee?.value,
                  name: val?.assignee?.label,
                },
              ]
            : null,
        digiStatus: val?.assignee != null ? 1 : 0,
      };
      formVariant.validateFields().then((res: any) => {
        // setForm1Values(true);
        form1Values = true;
        setButtonDisable(true);

        if (form1Values) {
          ProductService.post(values).then((res: any) => {
            if (res?.status && !isChecked) {
              onSaveVariant(onlySave, res?.result?.id);
            } else if (res?.status) {
              info(`Product added successfully.`);
              setTimeout(() => {
                onlySave ? props.onDismiss(true) : resetForm();
              }, 500);
              // props.setShouldRefresh();
              setIsChecked(false);
            } else {
              error(res?.message);
            }
            setButtonDisable(false);
          });
        } else {
          error("Variant fields are required!");
          setButtonDisable(false);
        }
      });
    });
  };

  const onSaveVariant = (onlySave: boolean, referenceId: string) => {
    formVariant.validateFields().then(async (values) => {
      let val = {
        variants: values?.variants?.map((itm: any) => ({
          ...itm,
          type: { id: itm?.type?.value, name: itm?.type?.label },
          option: { id: itm?.option?.value, name: itm?.option?.label },
        })),
        category: props.category,
        stockType: StockType.Variant,
        referenceId: referenceId,
      };
      ProductService.post(val).then((res) => {
        if (res?.status) {
          info(`Product added successfully.`);
          setTimeout(() => {
            onlySave ? props.onDismiss(true) : resetForm();
          }, 500);
        } else {
          error(res?.message);
        }
        setButtonDisable(false);
      });
    });
  };

  useEffect(() => {
    if (subCategory === undefined || subCategory?.value === "") return;
    MasterService.getProductGroupList("", subCategory?.label, false).then(
      (res: any) => {
        if (res !== null || res?.totalRecords > 0) {
          setProductGrpOptions(
            res?.items?.map((itm: any) => ({
              label: itm?.name,
              value: itm?.id,
            }))
          );
        }
      }
    );
  }, [subCategory]);

  useEffect(() => {
    formVariant.setFieldValue("variants", defaultVariants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVariants]);

  useEffect(() => {
    setTimeout(() => {
      activeKey === "3"
        ? selectRef.current?.focus()
        : inputRef.current?.focus();
    }, 2);
  }, [activeKey]);

  let items: TabsProps["items"] = [
    {
      key: "1",
      label: `Dashboard`,
      children: (
        <>
          <Form
            form={form1}
            // labelCol={{ span: 18 }}
            // wrapperCol={{ span: 24 }}
            requiredMark={false}
            // style={{ maxWidth: 800 }}
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            // className="InvAddForm"
          >
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input Product Name" },
                    {
                      whitespace: true,
                    },
                    // {
                    //   pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                    //   message: "Please input alphabets only!",
                    // },
                  ]}
                >
                  <Input ref={inputRef} placeholder="Enter Product Name" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Product-Category"
                  name="subCategory"
                  rules={
                    !isChecked
                      ? [
                          {
                            required: true,
                            message: "Please Select Sub Category",
                          },
                        ]
                      : []
                  }
                >
                  <Select
                    placeholder="Select Product Category"
                    showSearch
                    labelInValue
                    options={props.subCategoryOptions}
                    allowClear
                    listHeight={224}
                    style={{ width: "100%" }}
                    onChange={(val: any, op: any) => {
                      updateDefaultVariants(op);
                      setSubCategory(op);
                      clearSelect("productGroup");
                    }}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption
                          funcType={"subCategory"}
                          category={props.category}
                          getSubCategory={props.getSubCategory}
                        />
                      </>
                    )}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item label="Product Group" name="productGroup">
                  <Select
                    placeholder="Select Product Group"
                    labelInValue
                    showSearch
                    popupMatchSelectWidth={true}
                    allowClear
                    listHeight={224}
                    style={{ width: "100%" }}
                    options={productGrpOptions}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // dropdownRender={(menu) => (
                    //   <>
                    //     {menu}
                    //     <AddInOption
                    //       funcType={"varOption"}
                    //       variant={variant}
                    //       getVariantOptions={getVariantOptions}
                    //     />
                    //   </>
                    // )}
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item label="Serial No." name="serialNo">
                  <Input placeholder="Enter Serial Number" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item label="Invoice Number" name="invoiceNumber">
                  <Input placeholder="Enter Invoice Number" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  // initialValue={dayjs()}
                  label="Invoice Date"
                  name="purchaseDate"
                >
                  <DatePicker
                    placeholder="Select Date"
                    style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    onChange={(val: any) => {
                      if (val === null) {
                        formPurchase.setFieldValue("purchaseDate", undefined);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Seat Allotment"
                  name="seatAllotted"
                  rules={[
                    {
                      validator: checkSeating,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Seat"
                    labelInValue
                    allowClear
                    listHeight={160}
                    popupMatchSelectWidth={true}
                    options={props.seatingOptions}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption
                          funcType={"seating"}
                          getSeatingOptions={props.getSeatingOptions}
                        />
                      </>
                    )}
                  />
                </Form.Item>
              </Col>

              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item label="Assign To" name="assignee">
                  {/* <Select
                    placeholder="Select Assignee"
                    listHeight={150}
                    labelInValue
                    options={assigneeOptions}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption funcType={"assignee"} />
                      </>
                    )}
                  /> */}
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Assignee"
                    // onChange={handleChange}/
                    // onSelect={(e: any) => {
                    //   ({ label: { ...e.label }, value: e.value }, "s");
                    // }}
                    showSearch
                    maxLength={1}
                    maxTagTextLength={8}
                    allowClear
                    maxTagCount={1}
                    listItemHeight={50}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption funcType={"assignee"} />
                      </>
                    )}
                    optionLabelProp="label"
                    labelInValue
                  >
                    {assigneeOptions.map((itm: any, index: any) => {
                      return (
                        <Option
                          value={itm?.value}
                          label={itm?.label}
                          key={index}
                        >
                          <Row>
                            <Col sm={24}>
                              <span className=" fw600 font13 ">
                                {itm?.label}
                              </span>
                            </Col>
                            <Row>
                              <Col sm={24}>
                                <span className="fontS fw500 ">
                                  {itm?.designation}
                                </span>
                              </Col>
                              <Col sm={24}>
                                <span className="fontS fw500 ">
                                  {itm?.department}
                                </span>
                              </Col>
                            </Row>
                          </Row>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item label="Warranty Date" name="expiryDate">
                  <DatePicker
                    placeholder="Select Date"
                    // disabledDate={disabledDate}
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    onChange={(val: any) => {
                      if (val === null) {
                        formPurchase.setFieldValue("expiryDate", undefined);
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col lg={{ span: 10 }} sm={{ span: 24 }}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea
                    placeholder="Description"
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                className="fw500"
              >
                <Form.Item name="workFromHome" valuePropName="checked">
                  <Checkbox style={{ userSelect: "none" }}>
                    Allow work from home?
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col lg={{ span: 17 }} md={{ span: 12 }} sm={{ span: 12 }}>
                <Form.Item>
                  <Checkbox
                    checked={isChecked}
                    style={{ userSelect: "none" }}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  >
                    <div className="fw500">
                      Is product a single variant only?
                      <span className="font12 text-light">
                        (when product is like single RAM or HDD,etc.)
                      </span>
                    </div>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {isChecked ? (
              <Row gutter={[18, 18]}>
                <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                  <Form.Item label="Variant Type" name="varType">
                    <Select
                      placeholder="Select Variant Type"
                      labelInValue
                      showSearch
                      allowClear
                      listHeight={160}
                      options={variantTypes}
                      popupMatchSelectWidth={true}
                      onChange={(val, op: any) => {
                        setVariantOptions(
                          op?.varOptions.map((v: any) => ({
                            label: v.name,
                            value: v.id,
                          }))
                        );
                        clearSelect("varOption");
                      }}
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <AddInOption funcType={"varType"} />
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                  <Form.Item label="Variant Option" name="varOption">
                    <Select
                      placeholder="Select Variant Option"
                      labelInValue
                      allowClear
                      popupMatchSelectWidth={true}
                      options={variantOptions}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <AddInOption
                            funcType={"varOption"}
                            variant={{
                              id: form1.getFieldValue("varType")?.value,
                              name: form1.getFieldValue("varType")?.label,
                            }}
                            getVariantOptions={getVariantOptions}
                          />
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Form>
          {isChecked ? (
            <></>
          ) : (
            <Form
              className="InvAddForm"
              initialValues={{ remember: true }}
              form={formVariant}
              style={{
                maxWidth: "100%",
                maxHeight: "400px",
                overflowY: "auto",
              }}
              autoComplete="off"
              layout="vertical"
            >
              <Form.List name={"variants"}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Form.Item className="m-0 pb-4">
                      <Button type="primary" onClick={() => add()}>
                        Add
                      </Button>
                    </Form.Item>
                    {fields.map(({ key, name, ...restField }, index) => {
                      let fieldValue = formVariant?.getFieldValue([
                        "variants",
                        name,
                      ]);
                      let opt = varOption(fieldValue?.type?.value);
                      return (
                        <div key={index}>
                          <Row gutter={[10, 0]} justify={"start"}>
                            {/* <Col md={3} xl={3}>
                            <Form.Item
                              {...restField}
                              name={[name, "brand"]}
                              label={index === 0 ? "Category" : ""}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Category Name",
                                },
                              ]}
                            >
                              <Input autoFocus placeholder="Category" />
                            </Form.Item>
                          </Col> */}
                            <Col md={8} xl={4} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "variantName"]}
                                label={index === 0 ? "Name" : ""}
                                // rules={[
                                //   { required: true, message: "Enter Name" },
                                // ]}
                              >
                                <Input
                                  placeholder="Enter Name"
                                  ref={inputRef}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} xl={4} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "type"]}
                                label={index === 0 ? "Type" : ""}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Variant Type",
                                  },
                                ]}
                              >
                                <Select
                                  labelInValue
                                  allowClear
                                  showSearch
                                  listHeight={160}
                                  style={{ width: "100%" }}
                                  options={variantTypes}
                                  placeholder="Select Variant"
                                  popupMatchSelectWidth={true}
                                  onChange={(val, op: any) => {
                                    setVariantOptions(
                                      op?.varOptions.map((v: any) => ({
                                        label: v.name,
                                        value: v.id,
                                      }))
                                    );
                                  }}
                                  filterOption={(input, option) =>
                                    option?.label
                                      ?.toLowerCase()
                                      .indexOf(input?.toLowerCase()) >= 0
                                  }
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <AddInOption funcType={"varType"} />
                                    </>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} xl={4} sm={12}>
                              <Form.Item
                                // {...restField}
                                name={[name, "option"]}
                                label={index === 0 ? "Option" : ""}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Option Type",
                                  },
                                ]}
                              >
                                <Select
                                  labelInValue
                                  allowClear
                                  placeholder="Select Option"
                                  style={{ width: "100%" }}
                                  popupMatchSelectWidth={true}
                                  options={opt?.length < 0 ? [] : opt}
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label?.toString() ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <AddInOption
                                        funcType={"varOption"}
                                        variant={{
                                          id: formVariant.getFieldValue([
                                            "variants",
                                            name,
                                          ])?.type?.value,
                                          name: formVariant.getFieldValue([
                                            "variants",
                                            name,
                                          ])?.type?.label,
                                        }}
                                        getVariantOptions={getVariantOptions}
                                      />
                                    </>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} xl={4} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "serialNo"]}
                                label={index === 0 ? "Serial Number" : ""}
                              >
                                <Input placeholder="Enter Serial No." />
                              </Form.Item>
                            </Col>

                            <Col md={8} xl={4} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "warranty"]}
                                label={index === 0 ? "Warranty" : ""}
                              >
                                <DatePicker
                                  placeholder="Select Date"
                                  style={{ width: "100%" }}
                                  format={"DD/MM/YYYY"}
                                  onChange={(val: any) => {
                                    if (val === null) {
                                      formVariant.setFieldValue(
                                        "purchaseDate",
                                        undefined
                                      );
                                    }
                                  }}
                                  // disabledDate={(current) => {
                                  //   return current && current < dayjs().endOf("day");
                                  // }}
                                />
                              </Form.Item>
                            </Col>
                            {index !== 0 ? (
                              <Col md={1} xl={1} sm={12}>
                                <Button
                                  style={
                                    index === 0
                                      ? {
                                          borderRadius: "5px",
                                          marginTop: "50px",
                                        }
                                      : {
                                          borderRadius: "5px",
                                          marginTop: "2px",
                                        }
                                  }
                                  onClick={() => remove(name)}
                                  icon={<DeleteOutlined />}
                                />
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      );
                    })}
                  </>
                )}
              </Form.List>
            </Form>
          )}
        </>
      ),
    },
  ];

  if (isChecked) {
    items = items.filter((x) => x.key !== "2");
  }

  const onTabChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const info = (msg: any) => {
    messageApi.success(msg, 0.5);
  };
  const error = (mes: any) => {
    messageApi.error(mes);
  };

  return (
    <>
      {contextHolder}
      <Drawer
        zIndex={1005}
        title="Add Product"
        className="InvAddDrawer-A"
        open={true}
        closable={false}
        onClose={() => props.onDismiss(false)}
        width="60vw"
        extra={<CloseOutlined onClick={() => props.onDismiss(false)} />}
        // getContainer={false}
        // style={{ height: `calc(100vh - 100px)` }}
        afterOpenChange={() => {
          setActiveKey("1");
        }}
        footer={
          <>
            {/* {activeKey === "3" ? ( */}

            <Space>
              <Button
                htmlType="submit"
                ghost
                type="primary"
                // className="Inv-SaveBtn"
                onClick={() => onSave(true)}
                disabled={buttonDisable}
              >
                Save
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                ghost
                // className="Inv-SaveAndNewBtn"
                onClick={() => onSave(false)}
                disabled={buttonDisable}
              >
                Save and New
              </Button>

              {/* ) : activeKey === "2" ? (
              <Button
                htmlType="submit"
                type="primary"
                className="Inv-SaveAndNewBtn"
                onClick={onContinueVariant}
              >
                Save and Continue
              </Button>
            ) : (
              <>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="Inv-SaveAndNewBtn"
                  onClick={onContinueForm1}
                  disabled={buttonDisable}
                >
                  Save and Continue
                </Button>
              </>
            )} */}
              <Button
                htmlType="button"
                danger
                // className="Inv-CancelBtn"
                onClick={() => props.onDismiss(false)}
              >
                Cancel
              </Button>
            </Space>
          </>
        }
      >
        {/* <Tabs
          activeKey={activeKey}
          items={items}
          onChange={onTabChange}
          className="InvTab"
        /> */}
        <>
          <Form
            form={form1}
            // labelCol={{ span: 18 }}
            // wrapperCol={{ span: 24 }}
            requiredMark={false}
            // style={{ maxWidth: 800 }}
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            className="InvAddForm"
          >
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    { required: true, message: "Please input Product Name" },
                    {
                      whitespace: true,
                    },
                    // {
                    //   pattern: new RegExp(/^[a-zA-Z_ /,-]+$/i),
                    //   message: "Please input alphabets only!",
                    // },
                  ]}
                >
                  <Input ref={inputRef} placeholder="Enter Product Name" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Product-Category"
                  name="subCategory"
                  rules={
                    !isChecked
                      ? [
                          {
                            required: true,
                            message: "Please Select Sub Category",
                          },
                        ]
                      : []
                  }
                >
                  <Select
                    placeholder="Select Product Category"
                    showSearch
                    labelInValue
                    options={props.subCategoryOptions}
                    allowClear
                    listHeight={224}
                    style={{ width: "100%" }}
                    onChange={(val: any, op: any) => {
                      updateDefaultVariants(op);
                      setSubCategory(op);
                      clearSelect("productGroup");
                    }}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption
                          funcType={"subCategory"}
                          category={props.category}
                          getSubCategory={props.getSubCategory}
                        />
                      </>
                    )}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item label="Product Group" name="productGroup">
                  <Select
                    placeholder="Select Product Group"
                    labelInValue
                    showSearch
                    popupMatchSelectWidth={true}
                    allowClear
                    listHeight={224}
                    style={{ width: "100%" }}
                    options={productGrpOptions}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // dropdownRender={(menu) => (
                    //   <>
                    //     {menu}
                    //     <AddInOption
                    //       funcType={"varOption"}
                    //       variant={variant}
                    //       getVariantOptions={getVariantOptions}
                    //     />
                    //   </>
                    // )}
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item label="Serial No." name="serialNo">
                  <Input placeholder="Enter Serial Number" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item label="Invoice Number" name="invoiceNumber">
                  <Input placeholder="Enter Invoice Number" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  // initialValue={dayjs()}
                  label="Invoice Date"
                  name="purchaseDate"
                >
                  <DatePicker
                    placeholder="Select Date"
                    style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    onChange={(val: any) => {
                      if (val === null) {
                        formPurchase.setFieldValue("purchaseDate", undefined);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Seat Allotment"
                  name="seatAllotted"
                  rules={[
                    {
                      validator: checkSeating,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Seat"
                    labelInValue
                    allowClear
                    listHeight={160}
                    popupMatchSelectWidth={true}
                    options={props.seatingOptions}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption
                          funcType={"seating"}
                          getSeatingOptions={props.getSeatingOptions}
                        />
                      </>
                    )}
                  />
                </Form.Item>
              </Col>

              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item label="Assign To" name="assignee">
                  {/* <Select
                    placeholder="Select Assignee"
                    listHeight={160}
                    labelInValue
                    options={assigneeOptions}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption funcType={"assignee"} />
                      </>
                    )}
                  /> */}
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Select Assignee"
                    // onChange={handleChange}/
                    // onSelect={(e: any) => {
                    //   ({ label: { ...e.label }, value: e.value }, "s");
                    // }}
                    showSearch
                    maxLength={1}
                    maxTagTextLength={8}
                    allowClear
                    maxTagCount={1}
                    listItemHeight={50}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <AddInOption funcType={"assignee"} />
                      </>
                    )}
                    optionLabelProp="label"
                    labelInValue
                  >
                    {assigneeOptions.map((itm: any, index: any) => {
                      return (
                        <Option
                          value={itm?.value}
                          label={itm?.label}
                          key={index}
                        >
                          <Row>
                            <Col sm={24}>
                              <span className=" fw600 font13 ">
                                {itm?.label}
                              </span>
                            </Col>
                            <Row>
                              <Col sm={24}>
                                <span className="fontS fw500 ">
                                  {itm?.designation}
                                </span>
                              </Col>
                              <Col sm={24}>
                                <span className="fontS fw500 ">
                                  {itm?.department}
                                </span>
                              </Col>
                            </Row>
                          </Row>
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item label="Warranty Date" name="expiryDate">
                  <DatePicker
                    placeholder="Select Date"
                    // disabledDate={disabledDate}
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                    onChange={(val: any) => {
                      if (val === null) {
                        formPurchase.setFieldValue("expiryDate", undefined);
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col lg={{ span: 12 }} sm={{ span: 24 }}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea
                    placeholder="Description"
                    autoSize={{ minRows: 1, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                lg={{ span: 8 }}
                md={{ span: 10 }}
                sm={{ span: 12 }}
                className="fw500"
              >
                <Form.Item name="workFromHome" valuePropName="checked">
                  <Checkbox style={{ userSelect: "none" }}>
                    Allow work from home?
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col lg={{ span: 17 }} md={{ span: 12 }} sm={{ span: 12 }}>
                <Form.Item>
                  <Checkbox
                    checked={isChecked}
                    style={{ userSelect: "none" }}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  >
                    <div className="fw500">
                      Is product a single variant only?
                      <span className="font12 text-light">
                        (when product is like single RAM or HDD,etc.)
                      </span>
                    </div>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {isChecked ? (
              <Row gutter={[18, 18]}>
                <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                  <Form.Item label="Variant Type" name="varType">
                    <Select
                      placeholder="Select Variant Type"
                      labelInValue
                      showSearch
                      allowClear
                      listHeight={160}
                      options={variantTypes}
                      popupMatchSelectWidth={true}
                      onChange={(val, op: any) => {
                        setVariantOptions(
                          op?.varOptions.map((v: any) => ({
                            label: v.name,
                            value: v.id,
                          }))
                        );
                        clearSelect("varOption");
                      }}
                      filterOption={(input, option) =>
                        option?.label
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) >= 0
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <AddInOption funcType={"varType"} />
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                  <Form.Item label="Variant Option" name="varOption">
                    <Select
                      placeholder="Select Variant Option"
                      labelInValue
                      allowClear
                      popupMatchSelectWidth={true}
                      options={variantOptions}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <AddInOption
                            funcType={"varOption"}
                            variant={{
                              id: form1.getFieldValue("varType")?.value,
                              name: form1.getFieldValue("varType")?.label,
                            }}
                            getVariantOptions={getVariantOptions}
                          />
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </Form>
          {isChecked ? (
            <></>
          ) : (
            <Form
              className="InvAddForm"
              initialValues={{ remember: true }}
              form={formVariant}
              // style={{
              //   maxWidth: "100%",
              //   maxHeight: "400px",
              //   overflowY: "auto",
              // }}
              autoComplete="off"
              layout="vertical"
            >
              <Form.List name={"variants"}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <Form.Item className="m-0 pb-4">
                      <Button type="primary" onClick={() => add()}>
                        Add
                      </Button>
                    </Form.Item>
                    {fields.map(({ key, name, ...restField }, index) => {
                      let fieldValue = formVariant?.getFieldValue([
                        "variants",
                        name,
                      ]);
                      let opt = varOption(fieldValue?.type?.value);
                      return (
                        <div key={index}>
                          <Row gutter={[14, 0]}>
                            {/* <Col md={3} xl={3}>
                            <Form.Item
                              {...restField}
                              name={[name, "brand"]}
                              label={index === 0 ? "Category" : ""}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter Category Name",
                                },
                              ]}
                            >
                              <Input autoFocus placeholder="Category" />
                            </Form.Item>
                          </Col> */}
                            <Col md={8} xl={5} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "variantName"]}
                                label={index === 0 ? "Name" : ""}
                                // rules={[
                                //   { required: true, message: "Enter Name" },
                                // ]}
                              >
                                <Input
                                  placeholder="Enter Name"
                                  ref={inputRef}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} xl={5} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "type"]}
                                label={index === 0 ? "Type" : ""}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Variant Type",
                                  },
                                ]}
                              >
                                <Select
                                  labelInValue
                                  allowClear
                                  placeholder="Select Type"
                                  showSearch
                                  listHeight={160}
                                  style={{ width: "100%" }}
                                  options={
                                    variantTypes?.length < 0 ? [] : variantTypes
                                  }
                                  popupMatchSelectWidth={true}
                                  onChange={(val, op: any) => {
                                    setVariantOptions(
                                      op?.varOptions.map((v: any) => ({
                                        label: v.name,
                                        value: v.id,
                                      }))
                                    );
                                  }}
                                  filterOption={(input, option) =>
                                    option?.label
                                      ?.toLowerCase()
                                      .indexOf(input?.toLowerCase()) >= 0
                                  }
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <AddInOption funcType={"varType"} />
                                    </>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} xl={5} sm={12}>
                              <Form.Item
                                // {...restField}
                                name={[name, "option"]}
                                label={index === 0 ? "Option" : ""}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please Select Option Type",
                                  },
                                ]}
                              >
                                <Select
                                  labelInValue
                                  allowClear
                                  placeholder="Select Option"
                                  style={{ width: "100%" }}
                                  popupMatchSelectWidth={true}
                                  options={opt?.length < 0 ? [] : opt}
                                  showSearch
                                  filterOption={(input, option) =>
                                    (option?.label?.toString() ?? "")
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  dropdownRender={(menu) => (
                                    <>
                                      {menu}
                                      <AddInOption
                                        funcType={"varOption"}
                                        variant={{
                                          id: formVariant.getFieldValue([
                                            "variants",
                                            name,
                                          ])?.type?.value,
                                          name: formVariant.getFieldValue([
                                            "variants",
                                            name,
                                          ])?.type?.label,
                                        }}
                                        getVariantOptions={getVariantOptions}
                                      />
                                    </>
                                  )}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} xl={4} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "serialNo"]}
                                label={index === 0 ? "Serial Number" : ""}
                              >
                                <Input placeholder="Enter Serial No." />
                              </Form.Item>
                            </Col>

                            <Col md={8} xl={4} sm={12}>
                              <Form.Item
                                {...restField}
                                name={[name, "warranty"]}
                                label={index === 0 ? "Warranty" : ""}
                              >
                                <DatePicker
                                  placeholder="Select Date"
                                  style={{ width: "100%" }}
                                  format={"DD/MM/YYYY"}
                                  onChange={(val: any) => {
                                    if (val === null) {
                                      formVariant.setFieldValue(
                                        "purchaseDate",
                                        undefined
                                      );
                                    }
                                  }}
                                  // disabledDate={(current) => {
                                  //   return current && current < dayjs().endOf("day");
                                  // }}
                                />
                              </Form.Item>
                            </Col>
                            {index !== 0 ? (
                              <Col md={1} xl={1} sm={12}>
                                <Button
                                  style={
                                    index === 0
                                      ? {
                                          borderRadius: "5px",
                                          marginTop: "50px",
                                        }
                                      : {
                                          borderRadius: "5px",
                                          marginTop: "2px",
                                        }
                                  }
                                  onClick={() => remove(name)}
                                  icon={<DeleteOutlined />}
                                />
                              </Col>
                            ) : null}
                          </Row>
                        </div>
                      );
                    })}
                  </>
                )}
              </Form.List>
            </Form>
          )}
        </>
      </Drawer>
    </>
  );
};

export default AddDigitalProduct;
