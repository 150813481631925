import {
  Card,
  Row,
  Col,
  Select,
  Table,
  Input,
  DatePicker,
  Skeleton,
  Form,
  Empty,
  Spin,
  Space,
  Button,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useId, useState } from "react";
import {
  CategoryOption,
  pageSizeOption,
} from "../../Context/ApplicationContext/ApplicationState";
import ReportSummary from "./ReportSummary";
import PcConfiguration from "../../Services/PcConfiguration";
import { ColumnsType, TableProps } from "antd/es/table";
import { dayjs } from "../../../Utilities/dayjs";
import Tree, { DataNode } from "antd/es/tree";
import { DownOutlined, EditOutlined, LoadingOutlined } from "@ant-design/icons";
import Item from "antd/es/list/Item";
import { NavLink } from "react-router-dom";
const { RangePicker } = DatePicker;
const { Search } = Input;
const { TreeNode } = Tree;

const { Paragraph, Text } = Typography;
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="">
        {ifSelect ? (
          <Select
            onSelect={(value) => {
              changeListParams("type", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            className="invSelectVendor"
            placeholder={title}
            options={[
              { value: 0, label: "In Stock" },
              { value: 1, label: "In Use" },
              { value: 2, label: "Used" },
            ]}
            allowClear
            style={{ width: "100%" }}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) => changeListParams(inputName, e.target?.value)}
            onSearch={() => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

type DetailsType = {
  [key: string]:
    | string
    | number
    | DetailsType
    | Array<string | number | DetailsType>;
};

const PcConfigurationReport = () => {
  const uid = useId();
  const [data, setData] = useState<any>([]);
  const [expendedData, setExpendedData] = useState<any>([]);
  const [spinLoading, setSpinLoading] = useState(true);
  const [listParams, setListParams] = useState({
    name: "",
    start: 0,
    length: 15,
  });

  const [loading, setLoading] = useState(false);
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const generateTreeNodes = useCallback(
    (
      data: DetailsType | string | number | Array<any>,
      parentKey = ""
    ): DataNode[] => {
      if (typeof data === "string" || typeof data === "number") {
        return [
          {
            key: `${parentKey}-${crypto.randomUUID()}`,
            title: String(data),
            isLeaf: true,
          },
        ];
      }

      if (Array.isArray(data)) {
        return data.map((item, index) => ({
          key: `${parentKey}-${index}-${crypto.randomUUID()}`,
          title:
            typeof item == "number" || typeof item == "string"
              ? `(${item})`
              : `${parentKey} ${index + 1} `,
          children:
            typeof item == "number" || typeof item == "string"
              ? []
              : generateTreeNodes(item, `${parentKey}-${index}`),
        }));
        // }
      }

      if (data && typeof data === "object") {
        return Object.entries(data).map(([key, value]) => ({
          key: `${parentKey}-${key}-${crypto.randomUUID()}`,
          title:
            typeof value === "object" ? (
              <Text key={uid}>{key}</Text>
            ) : (
              <Text
                style={{ fontSize: "12px", fontWeight: "bolder" }}
                key={uid}
              >
                {key} :
                <Text
                  style={{
                    color: "rgba(182, 17, 17, 1)",
                    fontSize: "12px",
                    fontWeight: "bolder",
                    marginLeft: "2px",
                  }}
                >
                  {value}
                </Text>
              </Text>
            ),
          children:
            typeof value === "object"
              ? generateTreeNodes(value, `${parentKey}-${key}`)
              : [],
        }));
      }

      return [];
    },
    []
  );

  const [shouldRefresh, setShouldRefresh] = useState(false);

  const getData = () => {
    setLoading(true);
    PcConfiguration.getPcConfiguration(
      listParams.name,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      let result = res?.items?.map((itm: any) => {
        return {
          key: itm?._id,
          id: itm?._id,
          name: itm?.Details.diskdrive.SystemName,
          details: { ...itm?.Details },
        };
      });
      setLoading(false);
      setData(result);
    });
  };
  useEffect(() => {
    getData();
  }, [listParams, shouldRefresh]);
  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"System Name"}
          inputName={"name"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "name",
      key: "",
      render: (name: any, record: any) => (
        <div
          style={{ cursor: "pointer" }}
          className="ps-2 font13"
          onClick={() => {
            //   setShow("Summary");
          }}
        >
          {name}
        </div>
      ),
    },
  ];
  const handleExpand = (expanded: any, record: any) => {
    setExpendedData(record);
    setExpandedRowKeys(expanded ? [record.key] : []);
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    // setListParams((p) => {
    //   p.sortCol = sorter?.columnKey;
    //   p.sortDir = sorter?.order;
    //   return p;
    // });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setExpandedRowKeys([]);
    }
    setShouldRefresh((x) => !x);
  };
  return (
    <>
      <Card
        title={"Configuration Report"}
        bordered={false}
        extra={
          <>
            {/* <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Select
                  style={{ width: "190px" }}
                  placeholder="Select Category"
                  defaultValue={"0"}
                  //   onChange={(val) => setCategory(val)}
                  options={CategoryOption}
                />
              </Col>
            </Row> */}
            {/* <Button >Pdf Viewer</Button> */}
          </>
        }
      >
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          className="InvTable1"
          scroll={{ y: `calc(100vh - 300px)` }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          expandable={{
            columnWidth: 80,
            onExpand: (expanded, record) => {
              handleExpand(expanded, record);
            },
            expandedRowKeys,
            expandedRowRender: (
              record: any
              // index: number,
              // indent: number,
              // expanded: boolean
            ) => {
              return (
                <>
                  {data?.length > 0 ? (
                    <Skeleton
                      key={"1"}
                      active={true}
                      round={true}
                      loading={false}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "30",
                          minWidth: "550px",
                          overflow: "scroll",
                          // cursor: "all-scroll",
                          // overflow: "",
                          // overflowAnchor: "auto",
                        }}
                      >
                        {Object?.keys(record?.details).map((i: any, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                marginRight: "20px",
                                background: "white",
                                border: "1px solid #11215F",
                                padding: 20,
                                borderRadius: 8,
                              }}
                            >
                              {i == "InstalledSoftware" ? (
                                <>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {i}
                                  </span>
                                  <Table
                                    columns={[
                                      {
                                        key: "1",
                                        title: "Name",
                                        dataIndex: "DisplayName",
                                        ellipsis: true,
                                        width: "150px",
                                      },
                                      {
                                        key: "2",
                                        title: "Version",
                                        dataIndex: "DisplayVersion",
                                        ellipsis: true,

                                        width: "100px",
                                      },
                                      {
                                        key: "3",
                                        title: "Size",
                                        dataIndex: "Size (MB)",
                                        ellipsis: true,
                                        width: "50px",
                                      },
                                    ]}
                                    dataSource={record?.details[i]?.map(
                                      (itm: any, index: any) => {
                                        const val = { ...itm, key: index };
                                        return val;
                                      }
                                    )}
                                    //           minWidth: "550px",
                                    // overflow: "scroll",
                                    pagination={{}}
                                  />
                                </>
                              ) : (
                                <>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {i}
                                  </span>
                                  <Tree
                                    style={{
                                      minWidth: "250px",
                                      overflow: "auto",
                                    }}
                                    autoExpandParent
                                    itemScrollOffset={250}
                                    height={500}
                                    className="pcConfigTree"
                                    showLine
                                    switcherIcon={<DownOutlined />}
                                    treeData={generateTreeNodes(
                                      record?.details[i],
                                      i
                                    )}
                                  />
                                </>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </Skeleton>
                  ) : (
                    <>
                      <Empty description={<span>No Data</span>} />
                    </>
                  )}
                </>
              );
            },
          }}
        />
      </Card>
    </>
  );
};

export default PcConfigurationReport;
