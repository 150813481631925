import React, { useEffect, useState } from "react";
import { Button, Divider, Drawer, Spin, Table, message } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import LogService, { EntitiesEnum } from "../../Services/LogService";
import { ColumnsType } from "antd/es/table";
import { dayjs } from "../../../Utilities/dayjs";

export interface IHistoryProps {
  masterId: string;
  entity: EntitiesEnum;
  openDrawer: boolean;
  onDismiss: () => void;
}
interface DataType {
  key: React.Key;
  serialNo: number;
  user: string;
  date: string;
  message: string;
  action: number;
}
const MasterHistory = (props: IHistoryProps) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshHistory, setRefreshHistory] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  useEffect(() => {
    setLoading(true);
    LogService.getUserLog(
      props?.masterId,
      props?.entity,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.items.length > 0) {
        setData(
          res?.items?.map((d: any, i: number) => ({
            serialNo: i + 1 + listParams.start,
            key: i,
            date: dayjs(d?.createdBy?.date).format("DD MMM YYYY , hh:mm A"),
            message: d?.message.replace(/\r?\n|\r/, ""),
            user: d?.createdBy?.userName,
            action: d?.action,
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      } else {
        setLoading(false);
        messageApi.error(res?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    listParams.length,
    listParams.start,
    props?.entity,
    props?.masterId,
    refreshHistory,
  ]);

  const columns: ColumnsType<DataType> = [
    {
      title: "S.No.",
      dataIndex: "serialNo",
      key: "serialNo",
      width: 70,
      ellipsis: true,
      align: "center",
      render: (serialNo: any) => <span className="font12">{serialNo}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 170,
      render: (date: any) => <span className="font12">{date}</span>,
    },
    {
      title: "Remarks",
      dataIndex: "message",
      key: "message",
      render: (val: string, record) => (
        <div className="font12">
          {record?.action === 1 ? (
            <p>{`${val} by ${record?.user}`}</p>
          ) : record?.action === 2 ? (
            <div>
              {`Updates made by ${record?.user} in :`}
              <pre className="pre-style">{val}</pre>
            </div>
          ) : (
            <p>{`${val} by ${record?.user}`}</p>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Drawer
        title={"History"}
        width="35vw"
        zIndex={1005}
        onClose={props?.onDismiss}
        open={props?.openDrawer}
        extra={
          <Button
            icon={<CloseOutlined />}
            size="small"
            onClick={props?.onDismiss}
            className="border-none"
          />
        }
        className="InvDetailsDrawer"
      >
        <Divider className="m-0" />
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          scroll={{ y: `calc(100vh - 250px)` }}
          className="InvTable pt-2 fw500"
          pagination={
            totalRecords >= 10
              ? {
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  total: totalRecords,
                  position: ["bottomCenter"],
                  onChange: (page, pageSize) => {
                    setListParams({
                      ...listParams,
                      start: (page - 1) * pageSize,
                    });
                    setRefreshHistory((x) => !x);
                  },
                }
              : false
          }
        />
      </Drawer>
    </>
  );
};

export default MasterHistory;
