import React from "react";
import { Button, Col, Row, Space } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FundOutlined,
  ProjectOutlined,
  ReconciliationOutlined,
  StarOutlined,
} from "@ant-design/icons";

type Props = {};

const LinkButton = ({ title, href }: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="linkButton">
        <Button
          className="p-0 text-light"
          type="link"
          size="large"
          icon={<StarOutlined />}
          onClick={() => {
            navigate(href);
          }}
        >
          <span style={{ color: "#1677ff" }}>{title}</span>
        </Button>
      </div>
    </>
  );
};

const Report = (props: Props) => {
  return (
    <>
      <Row justify={"space-between"} className="mt-4">
        {/* <Space
          direction="vertical"
          size="middle"
          // split={<Divider type="horizontal" />}
        >
          <div className="font21 fw500">
            <ReconciliationOutlined /> Products Reports
          </div>
        </Space> */}
        {/* <Col span={8}>
          <Space direction="vertical" size="middle">
            <div className="font21 fw500">
              <FundOutlined /> Stock Reports
            </div>
            <div>
              <LinkButton
                title="Committed Stock Report"
                href="CommittedStock"
              />
            </div>
            <div>
              <LinkButton
                title="Digital Product Report"
                href="DigitalProduct"
              />
            </div>
          </Space>
        </Col> */}
        {/* <Col span={8}>
          <Space direction="vertical" size="middle">
            <div className="font21 fw500 me-5">
              <ProjectOutlined rotate={180} /> Sales Reports
            </div>
          </Space>
        </Col> */}
      </Row>

      <Row style={{ marginTop: "15px" }}>
        <Col span={8}>
          <Space direction="vertical" size="middle">
            <div className="font21 fw500">
              <FundOutlined /> Pc Configuration Reports
            </div>
            <div>
              <LinkButton title="Configuration Report" href="PcConfiguration" />
            </div>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default Report;
