import React from "react";
import { Route, Routes } from "react-router-dom";
import { ApplicationRouteLink } from "./ApplicationRoutePath";
import InventoryMainDashboard from "../Pages/InventoryDashboard/InventoryMainDashboard";
import Cleaning from "../Pages/Products/Cleaning/Cleaning";
import Stationery from "../Pages/Products/Stationery/Stationery";
import GardenOrPlants from "../Pages/Products/GardenOrPlants/GardenOrPlants";
import EmergencyMedicalKit from "../Pages/Products/EmergencyMedicalKit/EmergencyMedicalKit";
import PaidSoftware from "../Pages/Products/PaidSoftware/PaidSoftware";
import DigitalProduct from "../Pages/Products/DigitalProduct/DigitalProduct";
import OfficeEquipments from "../Pages/Products/OfficeEquipments/OfficeEquipments";
import Services from "../Pages/Services/Services";
import Report from "../Pages/Reports/Report";
import VendorList from "../Pages/Vendor/VendorList";
import CleaningDashboard from "../Pages/InventoryDashboard/CleaningDashboard/CleaningDashboard";
import GardenOrPlantsDashboard from "../Pages/InventoryDashboard/GardenOrPlantsDashboard/GardenOrPlantsDashboard";
import StationeryDashboard from "../Pages/InventoryDashboard/StationeryDashboard/StationeryDashboard";
import MedicalKitDashboard from "../Pages/InventoryDashboard/MedicalKitDashboard/MedicalKitDashboard";
import PaidSoftwareDashboard from "../Pages/InventoryDashboard/PaidSoftwareDashboard/PaidSoftwareDashboard";
import DigitalProductsDashboard from "../Pages/InventoryDashboard/DigitalProductsDashboard/DigitalProductsDashboard";
import OfficeEquipmentsDashboard from "../Pages/InventoryDashboard/OfficeEquipmentsDashboard/OfficeEquipmentsDashboard";
import Unit from "../Pages/Masters/Unit/Unit";
import Option from "../Pages/Masters/Option/Option";
import Variant from "../Pages/Masters/Variant/Variant";
import SubCategory from "../Pages/Masters/SubCategory/SubCategory";
import Seating from "../Pages/Masters/Seating/Seating";
import Department from "../Pages/Masters/Department/Department";
import Designation from "../Pages/Masters/Designation/Designation";
import Assignee from "../Pages/Masters/Assignee/Assignee";
import ProductGroup from "../Pages/Masters/ProductGroup/ProductGroup";
import Users from "../Pages/Users/Users";
import UserProfile from "../Pages/Users/UserProfile";
import CommittedStockReport from "../Components/Reports/CommittedStockReport";
import DPStockReport from "../Components/Reports/DPStockReport";
import Setting from "../Pages/Setting/Setting";
import UserAccessRights from "../Pages/Setting/UserAccessRights";
import secureLocalStorage from "react-secure-storage";
import PageNotFound from "../../Common/PageNotFound";
import {
  getSecureSessionItem,
  userRightsSessionKey,
} from "../Context/ApplicationContext/ApplicationState";

import Construction from "../Pages/Construction/Construction";
import Materials from "../../CapsitechConstruction/Master/Materials";
import ConstructionItem from "../../CapsitechConstruction/Master/ConstructionItem";
import Measurement from "../../CapsitechConstruction/Master/Measurement";
import Gst from "../../CapsitechConstruction/Master/Gst";
import UserCategory from "../Pages/Attendance/UserCategory/UserCategory";
import UserAttendance from "../Pages/Attendance/UserAttendacne/UserAttendance";
import Vehicle from "../Pages/CabService/Masters/Vehicle/Vehicle";
import Driver from "../Pages/CabService/Masters/Driver/Driver";
import Passenger from "../Pages/CabService/Masters/Passenger/Passenger";
import TripSchedule from "../Pages/CabService/TripSchedule/TripSchedule";
import TripLogs from "../Pages/CabService/TripLogs/TripLogs";
import Shift from "../Pages/CabService/Masters/Shift/Shift";
import Round from "../Pages/CabService/Masters/Round/Round";
import PassengerType from "../Pages/CabService/Masters/PassengerType/PassengerType";
import LogStatus from "../Pages/CabService/Masters/LogStatus/LogStatus";
import PcConfigurationReport from "../Components/Reports/PcConfigurationReport";
type Props = {};

const DashboardRoutes = (props: Props) => {
  const loginUserRights: any = getSecureSessionItem(userRightsSessionKey);
  return (
    <Routes>
      {/* Inventory Application Main Dashboard */}
      {loginUserRights?.dashboard?.showDashboard ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.InventoryMainDashboard}
          element={<InventoryMainDashboard />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* Vendor Page */}
      {loginUserRights?.vendor?.showVendor ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Vendor}
          element={<VendorList />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* Products */}
      {loginUserRights?.products?.cleaning ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Cleaning}
          element={<Cleaning />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.products?.stationery ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Stationery}
          element={<Stationery />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.products?.gardenOrPlants ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.GardenOrPlants}
          element={<GardenOrPlants />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.products?.emergencyMedicalKit ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.EmergencyMedicalKit}
          element={<EmergencyMedicalKit />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.products?.paidSoftware ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.PaidSoftware}
          element={<PaidSoftware />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.products?.digitalProduct ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.DigitalProduct}
          element={<DigitalProduct />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.products?.officeEquipments ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.OfficeEquipments}
          element={<OfficeEquipments />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* {loginUserRights?.products?.construction ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Construction}
          element={<Construction />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )} */}
      {/* Masters */}
      {loginUserRights?.master?.unit ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersUnit}
          element={<Unit />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.productGroup ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MasterProductGroup}
          element={<ProductGroup />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.option ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersOption}
          element={<Option />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.variant ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersVariant}
          element={<Variant />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.subCategory ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersSubCategory}
          element={<SubCategory />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.seating ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersSeating}
          element={<Seating />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.department ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersDepartment}
          element={<Department />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.designation ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersDesignation}
          element={<Designation />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.assignee ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersAssignee}
          element={<Assignee />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* {loginUserRights?.master?.materials ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersMaterials}
          element={<Materials />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.constructionItem ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersConstructionItem}
          element={<ConstructionItem />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.master?.measurement ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.MastersLinks.MastersMeasurement}
          element={<Measurement />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )} */}
      {/* Construction module */}
      {loginUserRights?.construction?.showPurchase ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Construction.Purchases}
          element={<Construction />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.construction?.master?.gst ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Construction.Gst}
          element={<Gst />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.construction?.master?.cMaterialType ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Construction.Category}
          element={<Materials />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.construction?.master?.cItems ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Construction.SubCategory}
          element={<ConstructionItem />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.construction?.master?.cMeasurement ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Construction.Measurement}
          element={<Measurement />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* Attendance */}
      {loginUserRights?.attendance?.showAttendanceSheet ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Attendance.UserAttendance}
          element={<UserAttendance />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.attendance?.showUserCategory ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Attendance.UserCategory}
          element={<UserCategory />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* Cab Service */}
      {loginUserRights?.cab?.master?.vehicle ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.VehicleMaster}
          element={<Vehicle />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.master?.driver ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.Driver}
          element={<Driver />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.master?.passenger ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.Passenger}
          element={<Passenger />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.master?.shift ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.Shift}
          element={<Shift />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.master?.round ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.Round}
          element={<Round />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.master?.passengerType ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.PassengerType}
          element={<PassengerType />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.master?.status ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.LogStatus}
          element={<LogStatus />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.trip?.tripSchedule ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.TripSchedule}
          element={<TripSchedule />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.cab?.trip?.tripLogs ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.CabService.TripLogs}
          element={<TripLogs />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* Services Page */}
      {loginUserRights?.service?.showService ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Services}
          element={<Services />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* Reports */}
      {loginUserRights?.report?.showReport ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.ReportsLinks.Report}
          element={<Report />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {loginUserRights?.report?.showReport ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.ReportsLinks.PcConfiguration}
          element={<PcConfigurationReport />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      <Route
        caseSensitive
        path={ApplicationRouteLink.ReportsLinks.CommittedStockReport}
        element={<CommittedStockReport />}
      />
      <Route
        caseSensitive
        path={ApplicationRouteLink.ReportsLinks.DigitalProductReport}
        element={<DPStockReport />}
      />
      {/* Home Dashboard Pages */}
      {/* Dashboard / Cleaning */}
      <Route
        caseSensitive
        path={ApplicationRouteLink.MainDashboardLinks.CleaningDashboard}
        element={<CleaningDashboard />}
      />
      {/* Dashboard / GardenOrPlants */}
      <Route
        caseSensitive
        path={ApplicationRouteLink.MainDashboardLinks.GardenOrPlantsDashboard}
        element={<GardenOrPlantsDashboard />}
      />
      {/* Dashboard / Stationery */}
      <Route
        caseSensitive
        path={ApplicationRouteLink.MainDashboardLinks.StationeryDashboard}
        element={<StationeryDashboard />}
      />
      {/* Dashboard / EmergencyMedicalKit */}
      <Route
        caseSensitive
        path={
          ApplicationRouteLink.MainDashboardLinks.EmergencyMedicalKitDashboard
        }
        element={<MedicalKitDashboard />}
      />
      {/* Dashboard / PaidSoftware */}
      <Route
        caseSensitive
        path={ApplicationRouteLink.MainDashboardLinks.PaidSoftwareDashboard}
        element={<PaidSoftwareDashboard />}
      />
      {/* Dashboard / DigitalProduct */}
      <Route
        caseSensitive
        path={ApplicationRouteLink.MainDashboardLinks.DigitalProductDashboard}
        element={<DigitalProductsDashboard />}
      />
      {/* Dashboard / OfficeEquipments */}
      <Route
        caseSensitive
        path={ApplicationRouteLink.MainDashboardLinks.OfficeEquipmentsDashboard}
        element={<OfficeEquipmentsDashboard />}
      />
      {/* Users */}
      {loginUserRights?.profile?.showTeamMember ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Users.UserList}
          element={<Users />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      <Route
        caseSensitive
        path={ApplicationRouteLink.Users.UserProfile}
        element={<UserProfile />}
      />
      {/* Setting */}
      {loginUserRights?.profile?.showSetting ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.Setting}
          element={<Setting />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      {/* UserAccess */}
      {loginUserRights?.profile?.showSetting ? (
        <Route
          caseSensitive
          path={ApplicationRouteLink.UserAccess}
          element={<UserAccessRights />}
        />
      ) : (
        <Route element={<PageNotFound />} path="*" />
      )}
      <Route caseSensitive element={<PageNotFound />} path="*" />
    </Routes>
  );
};

export default DashboardRoutes;
