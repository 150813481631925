import "../../Common/Common.css";
import React, { useState, useEffect, useContext } from "react";
import {
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
  PlusSquareFilled,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Collapse,
  Space,
  Alert,
  message,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import VendorDetails from "../../Components/Vendor/VendorDetails";
import AddVendor from "../../Components/Vendor/AddVendor";
import VendorService from "../../Services/VendorService";
import { pageSizeOption } from "../../Context/ApplicationContext/ApplicationState";
import type { CollapseProps } from "antd";
import AddEditVendor from "../../Components/Vendor/AddEditVendor";

interface VendorListDataType {
  key: string;
  id: string;
  vendorName: string;
  knowsFor: string;
  contactPerson: string;
  phone: number;
  total: number;
}

const { Search } = Input;
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  options,
  listParams,
  setListParams,
  fieldName,
}: any) => {
  return (
    <>
      <div className="">
        {ifSelect ? (
          <Select
            className="invSelectVendor"
            style={{ width: "100%" }}
            popupMatchSelectWidth={false}
            onSelect={(value: any) => {
              changeListParams(fieldName, value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            placeholder={title}
            size="small"
            options={options}
            allowClear
            onClear={() => {
              changeListParams(fieldName, -1);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e: any) => changeListParams(inputName, e.target?.value)}
            onSearch={() => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

const VendorList = () => {
  const VendorType = [
    { label: "All", value: -1 },
    { label: "Product", value: 0 },
    { label: "Construction", value: 1 },
  ];
  const type = [
    { value: "-1", label: "All" },
    { value: "0", label: "Supplier & Service Provider" },
    { value: "1", label: "Supplier" },
    { value: "2", label: "Service Provider" },
  ];
  const [show, setShow] = useState("");
  const [data, setData] = useState<any>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [vendorId, setVendorId] = useState("");
  const [vendorHistoryId, setHistoryVendorId] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [collapseKey, setCollapseKey] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [listParams, setListParams] = useState({
    company: "",
    vendorSearch: "",
    contactSearch: "",
    type: "",
    vendorType: -1,
    start: 0,
    length: 10,
    sortDir: "",
    sortCol: "",
    fromDate: "",
    toDate: "",
  });

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
    f: any,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter.columnKey;
      p.sortDir = sorter.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  useEffect(() => {
    setLoading(true);
    VendorService.getList(
      listParams.company,
      listParams.vendorSearch.trim(),
      listParams.contactSearch.trim(),
      listParams.type,
      listParams.vendorType,
      listParams.start,
      listParams.length,
      listParams.sortCol,
      listParams.sortDir,
      listParams.fromDate,
      listParams.toDate
    ).then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        setData(
          res?.items?.map((data: any, i: number) => ({
            key: i,
            id: data?.id,
            vendorName: data?.name,
            knowsFor:
              data?.companyType === 1
                ? "Supplier"
                : data?.companyType === 2
                ? "Service Provider"
                : "Supplier & Service Provider",
            contactPerson: data?.contactPerson,
            phone: data?.workPhone,
            total: data?.purchases,
            vendorType: data?.vendorType === 0 ? "Product" : "Construction",
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  }, [listParams, shouldRefresh]);

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  // For Collapse
  const items: CollapseProps["items"] = [
    {
      key: "1",
      showArrow: false,
      label: "",
      children: (
        <AddEditVendor
          vendorId={vendorId}
          onDismiss={(rec: boolean) => {
            setSelectedRowKeys("");
            setCollapseKey("");
            setVendorId("");
            if (rec) {
              setShouldRefresh((x) => !x);
            }
          }}
          setShouldRefresh={() => setShouldRefresh((x) => !x)}
        />
      ),
    },
  ];

  //For Table
  const columns: ColumnsType<VendorListDataType> = [
    {
      title: (
        <THeader
          title={"Name"}
          inputName={"vendorSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "vendorName",
      key: "vendorName",
      render: (name: any, record: any) => (
        <div
          // type="link"
          onClick={() => {
            setVendorId(record.id);
            setSelectedRowKeys(record?.key);
            setCollapseKey("1");
          }}
          className="ps-2 text-dark  font13 cursorPointer"
        >
          {name}
        </div>
      ),
    },
    {
      title: (
        <THeader
          title={"Contact Person"}
          inputName={"contactSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "contactPerson",
      key: "contactPerson",
      render: (val: any) => <div className="ps-2 font13">{val}</div>,
    },
    {
      title: (
        <THeader
          title={"Knows For"}
          inputName={"type"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          options={type}
          setListParams={setListParams}
          fieldName={"type"}
          ifSelect={true}
        />
      ),
      dataIndex: "knowsFor",
      key: "knowsFor",
      width: 210,
      render: (val: any) => <div className="ps-2 font13">{val}</div>,
    },
    {
      title: (
        <THeader
          title={"Type"}
          inputName={"vendorType"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          options={VendorType}
          fieldName={"vendorType"}
          setListParams={setListParams}
          ifSelect={true}
        />
      ),
      dataIndex: "vendorType",
      key: "vendorType",
      width: 150,
      render: (val: any) => <div className="ps-2 font13">{val}</div>,
    },
    {
      title: "Phone",
      align: "right",
      dataIndex: "phone",
      key: "phone",
      ellipsis: true,
      width: 95,
      render: (val: any) => <div className="font13 text-end">{val}</div>,
    },
    {
      title: "Total Amt",
      align: "right",
      dataIndex: "total",
      key: "Purchases.Total",
      render: (amt: any) => (
        <div className="font13 text-end">
          {amt && amt.length > 0
            ? `₹${amt?.map((val: any) => (val?.total).toFixed(2))}`
            : `₹0.00`}
        </div>
      ),
      width: 150,
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: "History",
      dataIndex: "history",
      width: 80,
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="primary"
            size="small"
            ghost
            icon={<HistoryOutlined />}
            onClick={() => {
              setHistoryVendorId(record?.id);
              setCollapseKey("");
              setOpenDrawer(true);
              setShow("VendorDetails");
            }}
          />
        </>
      ),
    },
  ];
  return (
    <Card
      title="Vendor"
      className="add"
      // security="z+"
      // size="small"
      extra={
        <>
          <Row justify={"end"} gutter={[10, 30]}>
            {/* Temp comment */}

            {/* <Col className="mb-3 mx-1">
          <RangePicker
            size="large"
            format={"DD/MM/YYYY"}
            onChange={(val: any) => {
              if (val !== null) {
                changeListParams("fromDate", val[0].toISOString());
                changeListParams("toDate", val[1].toISOString());
                setShouldRefresh((x) => !x);
              } else {
                changeListParams("fromDate", "");
                changeListParams("toDate", "");
                setShouldRefresh((x) => !x);
              }
            }}
          />
        </Col> */}
            <Col>
              {/* <Button
                type="primary"
                ghost
                size="middle"
                // className="mb-3"
                // title="Vendor"
                style={{ fontWeight: "600" }}
                icon={<PlusOutlined style={{}} />}
                onClick={() => setShow("AddVendor")}
              >
                Vendor
              </Button> */}
              <Button
                type="primary"
                ghost
                size="middle"
                style={{ fontWeight: "600" }}
                onClick={() => {
                  setCollapseKey("1");
                }}
                icon={<PlusOutlined style={{}} />}
              >
                New
              </Button>
            </Col>
          </Row>
        </>
      }
    >
      <Collapse ghost items={items} activeKey={collapseKey} />
      {collapseKey === "1" ? (
        <Card>
          <Table
            // size="small"
            rowClassName={rowClassName}
            loading={tableLoading}
            columns={columns}
            dataSource={data}
            onChange={onChangeTable}
            className="InvTable1"
            pagination={{
              showTotal: (total: any, range: any) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              total: totalRecords,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
            scroll={{ y: `calc(100vh - 279px)` }}
            size="small"
          />
        </Card>
      ) : (
        <Card>
          <Table
            rowClassName={rowClassName}
            loading={tableLoading}
            columns={columns}
            dataSource={data}
            onChange={onChangeTable}
            className="InvTable1"
            pagination={{
              showTotal: (total: any, range: any) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              total: totalRecords,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
            scroll={{ y: `calc(100vh - 279px)` }}
            size="small"
          />
        </Card>
      )}

      {show === "VendorDetails" && (
        <VendorDetails
          openDrawer={openDrawer}
          vendorHistoryId={vendorHistoryId}
          onDismiss={(rec?: boolean) => {
            setSelectedRowKeys("");
            setShow("");
            setOpenDrawer(false);
            // setShouldRefresh((x) => !x);
            if (rec) setShouldRefresh((x) => !x);
          }}
        />
      )}
      {show === "AddVendor" && (
        <AddVendor
          onDismiss={(rec: boolean) => {
            setSelectedRowKeys("");
            setShow("");
            if (rec) setShouldRefresh((x) => !x);
          }}
          setShouldRefresh={() => setShouldRefresh((x) => !x)}
        />
      )}
    </Card>
  );
};

export default VendorList;
