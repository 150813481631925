import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
import VendorService, { IApiDataProps } from "../../Services/VendorService";
import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { VendorType } from "./AddVendor";

interface IEditVendor {
  vendor: IApiDataProps;
  setIsDataSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditVendor: React.FC<IEditVendor> = (props: IEditVendor) => {
  const [form] = Form.useForm();
  const [editData, setEditData] = useState<any>({});
  const [enableEdit, setEnableEdit] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setEditData({ ...props?.vendor });
  }, [props?.vendor]);

  useEffect(() => {
    form.setFieldsValue(editData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, enableEdit]);

  const saveUpdatedData = () => {
    setBtnDisable(true);
    form.validateFields().then((values: any) => {
      let val = {
        ...values,
        id: props?.vendor?.id,
      };
      VendorService.post(val).then((res) => {
        if (res?.status) {
          setEnableEdit(false);
          props.setShouldRefresh((x: boolean) => !x);
          messageApi.success("Information updated successfully");
          props?.setIsDataSaved(true);
        } else messageApi.error(res?.message);
      });
      setBtnDisable(false);
    });
  };

  return (
    <>
      {contextHolder}
      {/* <Row justify={"space-between"} align={"bottom"} className="pb-3">
        <Col className="font16 fw600">Basic Details</Col>
        {enableEdit ? (
          <Space>
            <Button
              icon={<SaveOutlined />}
              // className="border-none font16"
              onClick={saveUpdatedData}
              disabled={btnDisable}
            />
            <Button
              icon={<CloseOutlined />}
              // className="border-none font16"
              disabled={btnDisable}
              onClick={() => {
                setEnableEdit(false);
              }}
            />
          </Space>
        ) : (
          <Col>
            <Button
              icon={<EditOutlined />} size="small"
              // className="border-none font16"
              disabled={!editData?.isEditAllowed}
              onClick={() => setEnableEdit(true)}
            />
          </Col>
        )}
      </Row> */}
      <Form
        form={form}
        colon={false}
        size="small"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 14 }}
        requiredMark={false}
        className="InvDForm"
        autoComplete="off"
      >
        <Form.Item
          label="Company Name"
          name="name"
          rules={[{ required: true }]}
        >
          {enableEdit ? (
            <Input autoFocus />
          ) : editData?.name ? (
            editData?.name
          ) : (
            "-"
          )}
        </Form.Item>
        <Form.Item label="Known For" name="companyType">
          {enableEdit ? (
            <Select
              allowClear
              options={[
                { value: 0, label: "Supplier & Service Provider" },
                { value: 1, label: "Supplier" },
                { value: 2, label: "Service Provider" },
              ]}
            />
          ) : editData?.companyType === 1 ? (
            "Supplier"
          ) : editData?.companyType === 2 ? (
            "Service Provider"
          ) : (
            "Suppler & Service Provider"
          )}
        </Form.Item>
        <Form.Item label="VendorType" name="vendorType">
          {enableEdit ? (
            <Select allowClear options={VendorType} />
          ) : editData?.vendorType === 0 ? (
            "Product"
          ) : (
            "Construction"
          )}
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            // { required: true, message: "Please input email!" },
            {
              type: "email",
              message: "Please input valid email!",
            },
          ]}
        >
          {enableEdit ? <Input /> : editData?.email ? editData?.email : "-"}
        </Form.Item>
        <Form.Item label="Contact Person" name="contactPerson">
          {enableEdit ? (
            <Input />
          ) : editData?.contactPerson ? (
            editData?.contactPerson
          ) : (
            "-"
          )}
        </Form.Item>
        <Form.Item label="GST No." name="zipCode">
          {enableEdit ? <Input /> : editData?.zipCode ? editData?.zipCode : "-"}
        </Form.Item>
        <Form.Item
          label="Work Phone"
          name="workPhone"
          rules={[
            {
              pattern: new RegExp(/^\d{10}$/),
              message: "Please input valid number!",
            },
          ]}
        >
          {enableEdit ? (
            <Input />
          ) : editData?.workPhone ? (
            editData?.workPhone
          ) : (
            "-"
          )}
        </Form.Item>
        {/* <Form.Item
          label="Mobile Phone"
          name="mobileNo"
          rules={[
            {
              pattern: new RegExp(/^\d{10}$/),
              message: "Please input valid number!",
            },
          ]}
        >
          {enableEdit ? (
            <Input />
          ) : editData?.mobileNo ? (
            editData?.mobileNo
          ) : (
            "-"
          )}
        </Form.Item> */}
        {/* <Form.Item label="GST No." name="description">
          {enableEdit ? (
            <Input />
          ) : editData?.description ? (
            editData?.description
          ) : (
            "-"
          )}
        </Form.Item> */}
        <Form.Item label="Description" name="description">
          {enableEdit ? (
            <Input />
          ) : editData?.description ? (
            editData?.description
          ) : (
            "-"
          )}
        </Form.Item>
        <Col className="font16 fw600 py-3">Address Information</Col>
        {/* <Form.Item label="Country" name="country">
          {enableEdit ? (
            <Select options={[{ value: "India", label: "India" }]} />
          ) : editData?.country ? (
            editData?.country
          ) : (
            "-"
          )}
        </Form.Item> */}

        <Form.Item label="Address">
          {enableEdit ? (
            <div style={{ width: "100%" }}>
              <Form.Item name="street1">
                <Input />
              </Form.Item>
              <Form.Item name="street2">
                <Input />
              </Form.Item>
            </div>
          ) : editData?.street1 && editData?.street2 ? (
            `${editData?.street1}, ${editData?.street2}`
          ) : editData?.street1 || editData?.street2 ? (
            `${editData?.street1} ${editData?.street2}`
          ) : (
            "-"
          )}
        </Form.Item>
        {/* <Form.Item label="State" name="state">
          {enableEdit ? <Input /> : editData?.state ? editData?.state : "-"}
        </Form.Item>
        <Form.Item label="City" name="city">
          {enableEdit ? <Input /> : editData?.city ? editData?.city : "-"}
        </Form.Item> */}
        {/* Temp comment */}
        {/* <Form.Item label="Issue date" name="issueDate">
          {enableEdit ? (
            <DatePicker
              placeholder="Select date"
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              onChange={(val: any) => {
                if (val === null) {
                  form.setFieldValue("issueDate", undefined);
                }
              }}
            />
          ) : editData?.issueDate ? (
            Utils.getFormattedDate(editData?.issueDate, "-", false)
          ) : (
            "-"
          )}
        </Form.Item> */}
      </Form>
    </>
  );
};

export default EditVendor;
