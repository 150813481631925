import { Routes, Route, useNavigate } from "react-router-dom";
import { UserProvider } from "./Context/UserContext";
import React from "react";
import { FullPageSpinner } from "./Common/FullPageSpinner";
import WrapperInventory from "./CapsitechInventoryComponents/Common/WrapperInventory";
import PageNotFound from "./Common/PageNotFound";
import { ConfigProvider } from "antd";
import ApplicationState from "./CapsitechInventoryComponents/Context/ApplicationContext/ApplicationState";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 1,
    },
  },
});

const AsyncLogin = React.lazy(() => import("./Common/Login/Login"));

const App = () => {
  const navigate = useNavigate();
  // const token = sessionStorage.getItem(
  //   "localhost|AO.Token" || "inventoryuat.capsitech.com|AO.Token"
  // );

  // <debug>
  //   commented due to token prefix issue
  // </debug>

  //React.useEffect(() => {
  //  if (!token) {
  //    navigate("/");
  //  }
  //  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, [navigate]);

  return (
    <UserProvider>
      <ApplicationState>
        <ConfigProvider
          // theme={{ token: { fontFamily: "Poppins" } }}
          theme={{
            components: {
              Menu: {
                darkSubMenuItemBg: " rgb(253, 12, 148)",
                darkItemBg: "rgb(39, 0, 41)",
                colorText: "#001440",
                // itemBg: "#EBF3FC",
              },
              // Menu: {
              // radiusItem: 0,
              // itemBorderRadius: 0,
              // radiusSubMenuItem: 0,
              // subMenuItemBorderRadius: 0,
              // itemColor: "white",
              // itemHoverColor: "white",
              // horizontalItemHoverColor: "#1677ff",
              // groupTitleColor: "white",
              // itemSelectedColor: "white",
              // horizontalItemSelectedColor: "#1677ff",
              // itemHoverBg: "#002a52",
              // itemActiveBg: "#1F77FB",
              // subMenuItemBg: "#000c17",
              // itemSelectedBg: "#1F77FB",
              // activeBarHeight: 2,
              // itemMarginInline: 0,
              // itemHeight: 35,
              // itemMarginBlock: 8,
              // itemPaddingInline: 16,
              // horizontalLineHeight: "46px",
              // iconSize: 17,
              // iconMarginInlineEnd: 20,
              // groupTitleFontSize: 12,
              // fontSize: 14,
              // fontWeightStrong: 800,
              // darkItemHoverBg: "#002a52",
              // darkItemColor: "white",
              // },

              Table: {
                // fontSize: 14,
                fontWeightStrong: 500,
                // lineHeight: 1,
                // lineWidth: 0,
                // lineType: "dashed",
                padding: 6,
                paddingContentVerticalLG: 6,
                algorithm: true,
                colorFillAlter: "#F8FAFE",

                // borderRadius: 0,
                // algorithm: true,
              },
              Badge: {
                colorSuccess: "#40d824",
                colorWarning: "#FF9100",
                colorError: "#FF0000",
                algorithm: true,
              },
              Button: {
                fontSize: 13,
                // borderRadius: 4,
              },
              Input: {
                colorTextPlaceholder: "#1B1A1A",
                colorFillAlter: "transparent",

                fontSize: 13,
                // controlHeight: 28,
                // borderRadius: 1,
                algorithm: true,
              },
              DatePicker: {
                colorTextPlaceholder: "#1B1A1A",
                algorithm: true,
                // fontSize: 13,
                // controlHeight: 28,
                // borderRadius: 1,
              },
              Select: {
                colorTextPlaceholder: "#1B1A1A",
                fontSize: 13,
                // fontSize: 14,
                // controlHeight: 28,
                // borderRadius: 1,
                algorithm: true,
                fontWeightStrong: 600,
              },
              Pagination: {
                borderRadius: 1,
                algorithm: true,
                itemSize: 28,
                fontSize: 13,
                margin: 4,
              },
            },

            // token: { fontFamily: "Segoe UI", fontSize: 14 },
            token: {
              // fontFamily: "Open Sans, sans-serif",
              fontFamily: "Poppins , sans-serif",
              // fontFamily: "Inter",
              // fontFamily: "Verdana",
              // fontFamily: "Readex Pro",
            },
          }}
        >
          <QueryClientProvider client={queryClient}>
            {/*<ReactQueryDevtools initialIsOpen={false} />*/}
            <React.Suspense fallback={<FullPageSpinner />}>
              <Routes>
                <Route element={<AsyncLogin />} path="/" />
                <Route element={<WrapperInventory />} path="/inventory/*" />
                {/* <Route element={<AsyncWrapperInventory />} path="/inventory/*" /> */}
              </Routes>
            </React.Suspense>
          </QueryClientProvider>
        </ConfigProvider>
      </ApplicationState>
    </UserProvider>
  );
};

export default App;
