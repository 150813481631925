import "../../Common/Common.css";
import React, { useState, useEffect } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  LoadingOutlined,
  PlusOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Popconfirm,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  message,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import AddProduct from "./AddProduct";
import ProductDetails from "./ProductDetails";
import ProductService, {
  Category,
  IProductApiProps,
} from "../../Services/ProductService";
import MasterService from "../../Services/MasterService";
import {
  ProductStatusFlag,
  pageSizeOption,
} from "../../Context/ApplicationContext/ApplicationState";
import ModifyState from "./ModifyState";
import ViewModifySummary from "./ViewModifySummary";
import { dayjs } from "../../../Utilities/dayjs";
import StockAdjustment from "./StockAdjustment";
import { number } from "echarts";
import PurchasesService from "../../../CapsitechConstruction/Services/PurchasesService";

interface ListDataType {
  key: string;
  id: string;
  productName: string;
  subCategory: string;
  assignedPerson: string;
  committedStock: number;
  inStock: number;
  status: string;
  productStatusFlag: number;
}
const { Search } = Input;
const { RangePicker } = DatePicker;
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {ifSelect ? (
          <Select
            style={{ width: "100%" }}
            onSelect={(value) => {
              changeListParams("status", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            className="invSelectVendor"
            placeholder={title}
            allowClear
            onClear={() => {
              changeListParams("status");
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            // defaultValue={{ value: "-1", label: "All" }}
            options={[
              { value: "-1", label: "All" },
              { value: "0", label: "In Stock" },
              { value: "1", label: "In Use" },
              { value: "2", label: "Used" },
            ]}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) =>
              changeListParams(inputName, e.target?.value?.trim())
            }
            onSearch={(val) => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};
const ProductList = ({ category }: any) => {
  const [show, setShow] = useState("");
  const [data, setData] = useState<any>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isPending, setIsPending] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listLoading, setListLoading] = useState(false);
  const [loading, setLoading] = useState({
    key: "",
    value: "",
  });
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [listParams, setListParams] = useState({
    nameSearch: "",
    subCategorySearch: "",
    assigneeSearch: "",
    status: "",
    start: 0,
    length: 15,
    sortDir: "",
    sortCol: "",
    fromDate: "",
    toDate: "",
  });
  const [apiData, setApiData] = useState<IProductApiProps>({
    id: "",
    name: "",
    index: 0,
    category: 1,
    stockType: 0,
    subCategory: { id: "", name: "" },
    productGroup: { id: "", name: "" },
    itemId: "",
    brand: "",
    vendor: { id: "", name: "" },
    purchaseDate: "",
    invoiceNumber: "",
    purchaseType: 0,
    transactionId: "",
    numberOfStock: 0,
    tax: 0,
    pricePerUnit: 0,
    totalPrice: 0,
    expiryDate: "",
    variantType: { id: "", name: "" },
    variantOption: { id: "", name: "" },
    variants: [],
    seatAllotted: { id: "", name: "" },
    status: 0,
    assignee: [],
    workFromHome: false,
    description: "",
    isEditAllowed: false,
    issueDate: "",
    serialNo: "",
    currency: 0,
  });
  async function getData(id: string, key: any) {
    // if () {
    setLoading({ key: key, value: "StockAdjustment" });
    // }
    await ProductService.get(id).then((res) => {
      if (res) {
        setApiData({
          ...res,
          unit: res?.unit?.name,
          inStock: res?.numberOfStock,
        });
        // setListLoading(false);
        setLoading({ key: "", value: "" });
      }
    });
  }
  const deleteItem = (id: string) => {
    ProductService.delete(id).then((res: any) => {
      if (res?.status) {
        message.success("Deleted Successfully");
        setShouldRefresh((x) => !x);
      } else {
        message.error(res?.message);
      }
    });
  };
  const [messageApi, contextHolder] = message.useMessage();
  const error = (mes: string) => {
    messageApi.error(mes, 1);
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter?.columnKey;
      p.sortDir = sorter?.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };
  const columns: ColumnsType<ListDataType> = [
    {
      title: (
        <THeader
          title={"Product"}
          inputName={"nameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productName",
      key: "Name",
      ellipsis: true,
      sorter: true,
      // width: 150,
      showSorterTooltip: false,
      render: (name, record) => (
        <div
          // size="small"
          // type="link"
          onClick={() => {
            setSelectedRowKeys(record.key);
            if (record.productStatusFlag === ProductStatusFlag.Pending) {
              setIsPending(ProductStatusFlag.Pending);
            } else {
              setIsPending(ProductStatusFlag.Approved);
            }
            setShow("ProductDetails");
            setSelectedRecord(record);
            setOpenDrawer(true);
          }}
          className="ps-2 text-dark font13   cursorPointer"
        >
          {name}
        </div>
      ),
    },
    {
      title: (
        <THeader
          title={"Product Category"}
          inputName={"subCategorySearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subCategory",
      key: "subCategory",
      ellipsis: true,
      // width: 150,
      render: (val: any) => <div className="ps-2 font13 ">{val}</div>,
    },
    // {
    //   title: (
    //     <THeader
    //       title={"Assignee"}
    //       inputName={"assigneeSearch"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setShouldRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   ellipsis: true,
    //   dataIndex: "assignedPerson",
    //   key: "assignedPerson",

    //   render: (val: any, record: any) =>
    //     val ? (
    //       <Row justify={"start"} align={"middle"} className="ps-2  font13 ">
    //         {val[0]?.name}
    //         <div className=" fw500">
    //           {val?.length > 1 ? (
    //             <Popover
    //               placement="rightTop"
    //               trigger="click"
    //               content={() => (
    //                 <>
    //                   <h3 className="my-1">Assignee</h3>
    //                   <div
    //                     style={{
    //                       maxHeight: 300,
    //                       maxWidth: 200,
    //                       overflow: "auto",
    //                     }}
    //                   >
    //                     {val?.map((v: any, index: any) => (
    //                       <div key={index}>
    //                         <Divider className="m-0" key={index} />
    //                         <div className="p-1" key={index + 1}>
    //                           {v?.name}
    //                         </div>
    //                       </div>
    //                     ))}
    //                   </div>
    //                 </>
    //               )}
    //             >
    //               <div className="popoverStyle fw500">
    //                 {val?.length < 100 ? `+${val?.length - 1}` : `+99`}
    //               </div>
    //             </Popover>
    //           ) : null}
    //         </div>
    //       </Row>
    //     ) : (
    //       <div className="ps-4">-</div>
    //     ),
    // },
    // {
    //   title: "Adj Date",
    //   width: 100,
    //   dataIndex: "stockAdjDate",
    //   key: "stockAdjDate",
    //   sorter: true,
    //   showSorterTooltip: false,
    //   render: (val: any) => (
    //     <div className="font13">
    //       {val === "0001-01-01T00:00:00Z"
    //         ? `-`
    //         : dayjs(val).format("DD/MM/YYYY")}
    //     </div>
    //   ),
    // },
    // {
    //   title: "Qty",
    //   align: "right",
    //   dataIndex: "stockAdjQty",
    //   key: "stockAdjQty",
    //   sorter: true,
    //   showSorterTooltip: false,
    //   render: (val: any) => <div className="font13 text-end">{val}</div>,
    //   width: 70,
    // },
    // {
    //   title: "Commit",
    //   align: "right",
    //   dataIndex: "committedStock",
    //   key: "CommittedStock",
    //   sorter: true,
    //   showSorterTooltip: false,
    //   render: (val: any) => <div className=" font13 text-end">{val}</div>,
    //   // width: 80,
    // },
    {
      title: "InStock",
      align: "right",
      dataIndex: "inStock",
      sorter: true,
      showSorterTooltip: false,
      key: "inStock",
      render: (val: any) => <div className="font13 ">{val}</div>,
      // width: 150,
    },
    // {
    //   align: "right",
    //   title: "Discard",
    //   sorter: true,
    //   showSorterTooltip: false,
    //   dataIndex: "discardStock",
    //   key: "discardStock",
    //   render: (val: any) => <div className="font13 text-end">{val}</div>,
    //   // width: 80,
    // },
    {
      title: (
        <THeader
          title={"Status"}
          inputName={"status"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
        />
      ),
      dataIndex: "status",
      key: "status",
      align: "center",
      // width: 60,
      render: (val, record, idx) => (
        <Row justify={"space-around"} align={"middle"} className="ps-2">
          <Button
            // size="small"
            className="InvTableBtn"
            onClick={() => {
              setSelectedRowKeys(record.key);
              if (record.productStatusFlag === ProductStatusFlag.Approved) {
                setSelectedRecord(record);
                setShow("ModifyState");
              } else {
                error("Product in Pending state after Approved can access");
              }
            }}
            icon={
              <>
                <div className="py-1 fw500 cursorPointer">
                  {record.productStatusFlag === ProductStatusFlag.Pending ||
                  false ? (
                    <PlusOutlined />
                  ) : null}
                </div>
              </>
            }
          >
            {val === 0 ? "InStock" : val === 1 ? "InUse" : "Used"}
          </Button>
        </Row>
      ),
    },
    {
      title: "",
      dataIndex: "summary",
      key: "summary",
      // width: 130,
      align: "end",
      render: (_: any, record: any) => {
        return (
          <>
            <div style={{ display: "flex", gap: "2%" }}>
              <Button
                type="link"
                size="small"
                icon={<FileTextOutlined />}
                onClick={() => {
                  setSelectedRecord(record);
                  setShow("View Summary");
                }}
                disabled={
                  record?.productStatusFlag === ProductStatusFlag.Pending
                }
              >
                Summary
              </Button>
              <Button
                type="link"
                // type="primary"
                ghost
                loading={
                  loading.value === "StockAdjustment" &&
                  loading.key === record?.key
                }
                icon={<ReconciliationOutlined />}
                size="small"
                onClick={async () => {
                  await getData(record?.id, record?.key);
                  setShow("StockAdjustment");
                }}
                disabled={
                  record?.productStatusFlag === ProductStatusFlag.Pending
                }
              >
                Adjustment
              </Button>
              <Button
                type="link"
                // type="primary"
                loading={loading?.key === record.index && loading.value === ""}
                size="small"
                icon={<EditOutlined />}
                onClick={() => {
                  setSelectedRowKeys(record.key);
                  if (record.productStatusFlag === ProductStatusFlag.Pending) {
                    setIsPending(ProductStatusFlag.Pending);
                  } else {
                    setIsPending(ProductStatusFlag.Approved);
                  }
                  setShow("ProductDetails");
                  setSelectedRecord(record);
                  setOpenDrawer(true);
                }}
                disabled={
                  record?.productStatusFlag === ProductStatusFlag.Pending
                }
              >
                {/*  */}
              </Button>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteItem(record?.id)}
                okText="Yes"
                cancelText="No"
                placement="top"
                icon={<DeleteOutlined style={{ color: "red" }} />}
              >
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  danger
                  title="Delete"
                  className="invSelectVendor"
                  size="small"
                >
                  {/* Delete */}
                </Button>
              </Popconfirm>
            </div>
          </>
        );
      },
    },
  ];

  const tableLoading = {
    spinning: listLoading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getSubCategory = () =>
    MasterService.getSubCategoryOptions(category, "").then((res) => {
      setSubCategoryOptions(
        res?.map((r: any) => ({
          label: r.name,
          value: r.id,
        }))
      );
    });

  useEffect(() => {
    getSubCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    // setLoading({ key: 0, value: "getList" });
    setListLoading(true);
    ProductService.getList(
      category,
      // 0,
      listParams.nameSearch,
      listParams.subCategorySearch,
      "",
      listParams.assigneeSearch,
      "",
      listParams.status,
      "",
      listParams.start,
      listParams.length,
      listParams.sortCol,
      listParams.sortDir,
      undefined,
      listParams.fromDate,
      listParams.toDate
    ).then((res: any) => {
      if (res !== null || res?.items.length > 0) {
        setData(
          res?.items?.map((data: any, i: number) => ({
            ...data,
            key: i,
            id: data?.id,
            productName: data?.name,
            subCategory: data?.subCategory?.name,
            assignedPerson: data?.assignee,
            committedStock: data?.committedStock,
            inStock: data?.numberOfStock,
            status: data?.status,
            productStatusFlag: data?.productStatusFlag,
            unit: data?.unit?.name,
            totalStock: data?.numberOfStock,
            stockAdjQty: data?.stockAdjQty,
            stockAdjDate: data?.stockAdjDate,
            discardStock: data?.discardStock,
            currentCid: data?.currentCid,
          }))
        );
        setListLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  }, [category, listParams, shouldRefresh]);

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  return (
    <>
      <Card
        title={Category[category]}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col className="">
                {/* <RangePicker
                  format={"DD/MM/YYYY"}
                  onChange={(val: any) => {
                    if (val !== null) {
                      changeListParams("fromDate", val[0].toISOString());
                      changeListParams("toDate", val[1].toISOString());
                      setShouldRefresh((x) => !x);
                    } else {
                      changeListParams("fromDate", "");
                      changeListParams("toDate", "");
                      setShouldRefresh((x) => !x);
                    }
                  }}
                /> */}
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow("AddProduct")}
                >
                  Product
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {contextHolder}

        <Table
          // size="small"
          rowClassName={rowClassName}
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          className="InvTable1"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          scroll={{ y: `calc(100vh - 300px)` }}
          // summary={() => {
          //   return (
          //     <Table.Summary fixed>
          //       <Table.Summary.Row>
          //         <Table.Summary.Cell index={0} className="fw600">
          //
          //           <div className="ps-2">TOTAL</div>
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell
          //           index={1}
          //           className="fw600"
          //         ></Table.Summary.Cell>
          //         {/* <Table.Summary.Cell
          //           index={2}
          //           className="fw600"
          //         ></Table.Summary.Cell> */}
          //         <Table.Summary.Cell index={3} className="fw600 text-end">
          //           {data?.reduce((acc: any, curr: any) => {
          //             return (acc += Number(curr?.inStock));
          //           }, 0) || 0}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell
          //           index={4}
          //           className="fw600"
          //         ></Table.Summary.Cell>

          //         {/* <Table.Summary.Cell index={5} className="fw600" align="right">
          //           {0?.reduce((acc: any, curr: any) => {
          //             return (acc += Number(curr?.stockAdjQty));
          //           }, 0) || 0}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell index={6} className="fw600" align="right">
          //           {data?.reduce((acc: any, curr: any) => {
          //             return (acc += Number(curr?.committedStock));
          //           }, 0) || 0}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell index={7} className="fw600" align="right">
          //
          //           {data?.reduce((acc: any, curr: any) => {
          //             return (acc += Number(curr?.inStock));
          //           }, 0) || 0}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell index={8} className="fw600" align="right">
          //
          //           {data?.reduce((acc: any, curr: any) => {
          //             return (acc += Number(curr?.discardStock));
          //           }, 0) || 0}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell
          //           index={9}
          //           className="fw600"
          //         ></Table.Summary.Cell>
          //         <Table.Summary.Cell
          //           index={10}
          //           className="fw600"
          //         ></Table.Summary.Cell> */}
          //       </Table.Summary.Row>
          //     </Table.Summary>
          //   );
          // }}
        />

        {show === "ProductDetails" && (
          <ProductDetails
            openDrawer={openDrawer}
            isPending={isPending}
            selectedRecord={selectedRecord}
            subCategoryOptions={subCategoryOptions}
            onDismiss={(rec: boolean) => {
              setShow("");
              setOpenDrawer(false);
              setSelectedRowKeys("");
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}

        {show === "AddProduct" && (
          <AddProduct
            getSubCategory={getSubCategory}
            category={category}
            subCategoryOptions={subCategoryOptions}
            onDismiss={(rec: boolean) => {
              setShow("");
              if (rec) setShouldRefresh((x) => !x);
            }}
            setShouldRefresh={() => setShouldRefresh((x) => !x)}
          />
        )}

        {show === "ModifyState" && (
          <ModifyState
            mode=""
            setShow={setShow}
            selectedRecord={selectedRecord}
            category={category}
            onDismiss={(rec: boolean) => {
              setSelectedRowKeys("");
              setShow("");
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}

        {show === "View Summary" && (
          <ViewModifySummary
            setShow={setShow}
            selectedRecord={selectedRecord}
            category={category}
            onDismiss={(rec: boolean) => {
              setSelectedRowKeys("");
              setShow("");
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}
        {show === "StockAdjustment" && (
          <StockAdjustment
            category={apiData?.category}
            selectedRecord={apiData}
            onDismiss={(rec: boolean) => {
              setShow("");
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}
      </Card>
    </>
  );
};

export default ProductList;
