import {
  message,
  Input,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
  Select,
  Spin,
  Popconfirm,
  Divider,
  Tag,
  Space,
  InputRef,
  Card,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useState, useEffect, useRef } from "react";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import MasterHistory from "../MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";
const { Search } = Input;

const AddInOption = ({ funcType }: any) => {
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const { getVariants, getAssigneeOptions } = useContext(ApplicationContext);
  const [status, setStatus] = useState<any>("success");
  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "varType")
        MasterService.postVariant({ name: name }).then((r: any) => {
          getVariants();
          setName("");
          inputRef.current?.focus();
        });
    }
  };
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 0px 4px" }}>
        <Input
          placeholder="Enter Option"
          ref={inputRef}
          value={name}
          status={status}
          onChange={onNameChange}
          onPressEnter={addOption}
        />
        <Button type="text" icon={<PlusOutlined />} onClick={addOption} />
      </Space>
    </>
  );
};

export const Option = () => {
  const [optionForm] = Form.useForm();
  const [optionList, SetOptionList] = useState<any>([]);
  const inputRef = useRef<any>(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [updateToggle, setUpdateToggle] = useState("close");
  const [messageApi, contextHolder] = message.useMessage();
  const [editRecord, setEditRecord] = useState<any>({});
  const { variantTypes, getVariants } = useContext(ApplicationContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [show, setShow] = useState<boolean>(false);
  const [keyVal, setKeyVal] = useState<any>();
  const [selectDisable, setButtonDisable] = useState(false);
  const [exist, setExist] = useState<any>(false);
  const [editData, setEditData] = useState<any>({});
  const [listParams, setListParams] = useState<any>({
    search: "",
    start: 0,
    length: 15,
    isOptionSearch: true,
  });
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  // Message on Submit
  const info = (msg: any) => {
    messageApi.success(msg);
  };
  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  // Message on Already exist value
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Option Is Assigned  Can't be deleted",
    });
  };

  // POST For Option
  const subOption = (saveTog: boolean) => {
    optionForm.validateFields().then((values) => {
      let val = {
        name: values?.name,
        variant: { id: values?.variant?.value, name: values?.variant?.label },
      };

      MasterService.postOption(val).then((res) => {
        if (res?.status) {
          if (saveTog === true) {
            getVariants();
            info("submitted");
            onDismiss();
          } else {
            optionForm.resetFields();
            info("submitted");
            setShouldRefresh(!shouldRefresh);
          }
        } else {
          errorMsg(res?.message);
        }
      });
    });
  };
  // GET For Option
  const getData = (listParams: any) => {
    setLoading(true);
    MasterService.getOption(
      listParams.search,
      listParams.start,
      listParams.length,
      listParams.isOptionSearch
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items?.map((r: any) => {
          return data.push({
            key: r.id,
            id: r.id,
            label: r.name,
            value: r.id,
            variantId: r.variant.id,
            variantList: r?.variant.name,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        SetOptionList([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };
  //
  const editRowData = async (editList: any) => {
    MasterService.getOptionById(editList?.value).then(async (res: any) => {
      let listData: any = {
        ...res,
        variant: { label: res?.variant?.name, value: res?.variant?.id },
      };
      setEditData(res);

      setButtonDisable(true);
      setUpdateToggle("show");
      setEditRecord(editList);
      setKeyVal(editList.key);
      setShow(true);
      await MasterService.isExist(Masters.Option, editList.value).then(
        (res) => {
          if (res === true) setExist(true);
          else setExist(false);
        }
      );
      optionForm.setFieldsValue(listData);
    });
  };
  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === optionForm.getFieldValue(fieldName)) {
      // console.log("Chalega");
      setFieldChange(false);
    }
    // setChangesInFields({ ...changesInFields, [fieldName]: false });
    else {
      setFieldChange(true);
      // console.log("Nhi");
    }

    // setChangesInFields({ ...changesInFields, [fieldName]: true });

    // console.log(
    //   fieldChange,
    //   "fieldChange",
    //   optionForm.getFieldsValue(),
    //   "getField"
    // );

    // if (fieldName === optionForm.getFieldsValue()) {
    //   console.log("chalega");
    // } else {
    //   console.log("Nhi Chalega");
    // }
  };
  const updateData = () => {
    let values = optionForm.getFieldsValue(true);
    if (fieldChange) {
      optionForm.validateFields().then(async () => {
        let val = {
          id: keyVal,
          name: values.name,
          variant: {
            id: editRecord?.variantId,
            name: editRecord?.variantList,
          },
        };
        await MasterService.postOption(val).then((res: any) => {
          if (res?.message) {
            messageApi.error(res?.message);
          } else {
            setUpdateToggle("close");
            onDismiss();
            info("Updated Successfully");
            getVariants();
            setSelectedRowKeys("");
          }
        });
      });
    } else {
      setShow(false);
    }

    // MasterService.postOption(val).then(() => {
    //   setUpdateToggle("close");
    //   onDismiss();
    //   info("Updated Successfully");
    // });
  };

  const deleteList = (listId: any) => {
    MasterService.isExist(Masters.Option, listId).then((res) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getOptionById(listId).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteOption(listId).then((res) => {
              if (res.status) {
                getVariants();
                // errorMsg("Option is Deleted Successfully");
                messageApi.success("Option is deleted successfully.");
                onDismiss();
              } else errorMsg(res.errors);
            });
          } else {
            messageApi.warning("Option is added by admin can't be deleted");
          }
        });
      }
    });
  };
  const searchName = (e: any) => {
    setListParams({
      search: e.trim(),
      start: 0,
      length: 15,
      isOptionSearch: true,
    });
    // getData(e);
  };
  const searchVariant = (e: any) => {
    setListParams({
      search: e.trim(),
      start: 0,
      length: 15,
      isOptionSearch: false,
    });
  };
  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setFieldChange(false);
    setShow(false);
    setShowDrawer("");
    optionForm.resetFields();
  };
  useEffect(() => {
    getData(listParams);
    inputRef.current?.focus();
    // getVariants();
    // eslint-Disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh, listParams]);
  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          <Search
            placeholder="Option"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchName(e)}
            size="small"
            bordered={false}
          />
        </div>
      ),
      dataIndex: "label",
      width: 240,
      render: (itm: any, record: any) => (
        <span
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
    },
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">VARIANT</span> */}
          <Search
            placeholder="Variant"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchVariant(e)}
            size="small"
            bordered={false}
          />
        </div>
      ),
      width: 240,
      dataIndex: "variantList",
      render: (itm: any) => <span className="ps-2  font13">{itm}</span>,
    },
    {
      title: (
        <>
          <div className="">Date</div>
        </>
      ),
      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: " ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            size="small"
            type="text"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            {/* Edit */}
          </Button>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record.value)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button size="small" icon={<DeleteOutlined />} type="text" danger>
              {/* Delete */}
            </Button>
          </Popconfirm>
          {
            <Button
              size="small"
              type="text"
              icon={<HistoryOutlined />}
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  return (
    <>
      {contextHolder}
      <Card
        title={"Option"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setUpdateToggle("close");
                    setShow(true);
                    setButtonDisable(false);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={optionList}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          // title={updateToggle === "close" ? "Add Option" : "Update Option"}
          title={
            updateToggle === "close" ? (
              "Add Option"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Option
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Option"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag> */}
              </>
            )
          }
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setSelectedRowKeys("");
            setShow(false);
            optionForm.resetFields();
            setUpdateToggle("close");
          }}
          zIndex={1005}
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subOption();
          // }}
          footer={
            <>
              {updateToggle === "close" ? (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        optionForm.resetFields();
                        setUpdateToggle("close");
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        subOption(true);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        subOption(false);
                      }}
                    >
                      Save and New
                    </Button>
                  </Space>
                </div>
              ) : (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        optionForm.resetFields();
                        setUpdateToggle("close");
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>

                    {/* <Popconfirm
                  title={
                    exist === true
                      ? "Do you wan't to update this assigned Option"
                      : "Update Option"
                  }
                  // onConfirm={() =>  }
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  onConfirm={() => updateData()}
                  onCancel={() => {
                    setShow(false);
                    optionForm.resetFields();
                    setUpdateToggle("close");
                  }}
                > */}
                    <Button
                      type="primary"
                      ghost
                      onClick={() => updateData()}
                      disabled={!editData?.isEditAllowed}
                    >
                      Update
                    </Button>
                  </Space>
                  {/* </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="optionBasic"
            className="InvAddForm"
            layout="vertical"
            size="large"
            form={optionForm}
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            onFieldsChange={() => {
              setFieldChange(true);
            }}
          >
            <Form.Item
              label="Enter Variant"
              name="variant"
              required
              rules={[{ required: true, message: "Please enter variant" }]}
            >
              <Select
                ref={inputRef}
                options={variantTypes}
                labelInValue
                allowClear
                listHeight={120}
                disabled={selectDisable}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <AddInOption
                      funcType={"varType"}
                      // category={props.category}
                      // getSubCategory={props.getSubCategory}
                    />
                  </>
                )}
              />
            </Form.Item>
            <Form.Item
              label="Enter Option"
              name="name"
              required
              rules={[
                // {
                //   pattern: /^\b[^-\s\d\W][^\s\d\W]*$/,
                //   message:
                //     "Spaces,  are not allowed at the beginning or end of the value.",
                // },
                // {
                //   required: true,
                //   message: "Fill the field",
                // },
                {
                  pattern: pattern,
                  message:
                    "Spaces, digits, and special symbols are not allowed at beginning or end of the input",
                },
                {
                  required: true,
                  message: "Please enter option",
                },
              ]}
            >
              <Input
                placeholder="Enter Option"
                onBlur={() => {
                  CheckChanges("name");
                }}
              />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Option}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Option;
