import { Card, Col, Modal, Row, Spin, Table } from "antd";
import {
  // LineChart,
  BarChart,
  PieChart,
  // ScatterChart,
  // RadarChart,
  // MapChart,
  // TreeChart,
  // TreemapChart,
  // GraphChart,
  // GaugeChart,
  // FunnelChart,
  // ParallelChart,
  // SankeyChart,
  // BoxplotChart,
  // CandlestickChart,
  // EffectScatterChart,
  // LinesChart,
  // HeatmapChart,
  // PictorialBarChart,
  // ThemeRiverChart,
  // SunburstChart,
  // CustomChart,
} from "echarts/charts";
import {
  axisClasses,
  // BarChart,
  pieArcLabelClasses,
} from "@mui/x-charts";
import ReactECharts from "echarts-for-react";
import {
  useDashBoardChart,
  useGetPcConfigReport,
  useInstalledApp,
} from "../../../Services/RQDasboardService";
import { memo, useCallback, useEffect, useState } from "react";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";
type MyDatum = { date: Date; stars: number };
type DailyStars = {
  date: Date;
  stars: number;
};

type Series = {
  label: string;
  data: DailyStars[];
};
const Modal1 = ({ listParams, onCancel }: any) => {
  console.log(listParams);
  const {
    data,
    isFetching: isF,
    isFetched: if1,
    isSuccess,
  } = useGetPcConfigReport(listParams);

  console.log("WER", data);
  return (
    <>
      <Modal  open zIndex={1100} onCancel={() => onCancel()}>
        <Table
          className="InvTable1"
          // loading={isSuccess}
          size="small"
          columns={[
            {
              title: "Software Name",
              dataIndex: "SystemName",
              key: "category",
              width: "70%",
            },
            // {
            //   title: "Count",
            //   dataIndex: "count",
            //   key: "category1",
            //   width: "30%",
            // },
          ]}
          // style={{ overflow: "hidden", boxSizing: "border-box" }}
          scroll={{ y: "60vh" }} // Or another percentage that suits your layout
          dataSource={data?.items?.map((i: any, index: any) => {
            return { ...i, key: index };
          })}
          // dataSource={[]}
          pagination={{
            // showTotal: (total, range) =>
            //   `${range[0]}-${range[1]} of ${total} items`,
            // selectPrefixCls: "custom-table-select",
            // current: pagingItems.TotalProducts.currentPage,
            // pageSize: pagingItems.TotalProducts.pageSize,
            // showSizeChanger: true,
            // total: pagingItems.TotalProducts.totalRecords,
            pageSizeOptions: pageSizeOption,
          }}
          // scroll={{ y: "30vh" }}
        />
      </Modal>
    </>
  );
};

const Information = () => {
  const [listParams, setListParams] = useState<{
    s: string | number;
    f: string;
    start?: number;
    length?: number;
  }>();
  const [pagingItems, setPagingItems] = useState<{
    TotalProducts: {
      totalRecords: number;
      currentPage: number;
      pageSize: number;
    };
  }>({
    TotalProducts: { totalRecords: 0, currentPage: 1, pageSize: 15 },
  });
  const [open, setOpen] = useState<any>("");
  const { data: chartData, isFetching } = useDashBoardChart();
  const { data: installedData, isFetching: dataFetching } = useInstalledApp();

  const onClick = (param: any) => {
    setOpen("Modal1");
    setListParams((pre: any) => {
      return { ...param };
    });
  };

  console.log("123", listParams);
  const ChartBoard = memo(
    () => {
      return (
        <Row gutter={[8, 0]} style={{}}>
          <Col span={16}>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Card
                  title="OS Based information"
                  loading={isFetching}
                  style={{ height: "350px" }}
                >
                  <ReactECharts
                    key={"1"}
                    option={{
                      color: [
                        "#f64c4c",
                        "#db4cf6",
                        "#4cc7f6",
                        "#4cf69b",
                        "#b8f64c",
                        "#f6b84c",
                        "#594cf6",
                      ],
                      tooltip: {
                        trigger: "item",
                      },
                      legend: {
                        orient: "vertical",
                        left: "left",
                      },
                      xAxis: {
                        // type: "bar",
                        boundaryGap: true,
                        data: chartData?.osInfo?.xAxis,
                        onclick: (e: any) => {
                          console.log(
                            "OOsadddddddddddddddddddddddddddddddddddddddddddd",
                            e
                          );
                        },
                        axisLabel: {
                          color: "#000000",
                          fontSize: 10,
                          interval: 0,
                          // rotate: 50,
                        },

                        color: [
                          "#dd6b66",
                          "#759aa0",
                          "#e69d87",
                          "#8dc1a9",
                          "#ea7e53",
                          "#eedd78",
                          "#73a373",
                          "#73b9bc",
                          "#7289ab",
                          "#91ca8c",
                          "#f49f42",
                        ],
                      },
                      yAxis: {
                        type: "value",
                      },
                      emphasis: {
                        focus: "series",
                      },
                      series: [
                        {
                          name: "OS Info",
                          type: "bar",
                          data: chartData?.osInfo?.series,
                          color: [
                            "#37A2DA",
                            "#32C5E9",
                            "#67E0E3",
                            "#9FE6B8",
                            "#FFDB5C",
                            "#ff9f7f",
                            "#fb7293",
                            "#E062AE",
                            "#E690D1",
                            "#e7bcf3",
                            "#9d96f5",
                            "#8378EA",
                            "#96BFFF",
                          ],
                          // width: "20px",
                        },
                      ],
                      width: "100%",
                    }}
                    onEvents={{
                      click: (e: any) => {
                        console.log("e?.name 1 = ", e);
                        e?.event?.stop();
                        console.log("e?.name 11 = ", e?.name);
                        onClick({ s: e.name, f: "Details.Systeminfo.OS Name" });
                        return false;
                      },
                    }}

                    // opts={{ renderer: "svg" }}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  title="Ram Based Information"
                  loading={isFetching}
                  style={{ height: "350px" }}
                >
                  <ReactECharts
                    key={"2"}
                    option={{
                      color: [
                        "#f64c4c",
                        "#db4cf6",
                        "#4cc7f6",
                        "#4cf69b",
                        "#b8f64c",
                        "#f6b84c",
                        "#594cf6",
                      ],

                      xAxis: {
                        // type: "bar",
                        boundaryGap: true,
                        data: chartData?.ramInfo?.xAxis,
                      },
                      yAxis: {
                        type: "value",
                      },
                      tooltip: {
                        trigger: "item",
                      },
                      legend: {
                        orient: "vertical",
                        left: "left",
                      },
                      emphasis: {
                        focus: "series",
                      },
                      series: [
                        {
                          name: "Ram Info",
                          type: "bar",
                          data: chartData?.ramInfo?.series,
                        },
                      ],
                      width: "100%",
                    }}
                    onEvents={{
                      click: (e: any) => {
                        onClick({
                          s: parseInt(e.name),
                          f: "Details.Memorychip.CapacityGB",
                        });
                      },
                    }}
                    opts={{ renderer: "svg" }}
                  />
                </Card>
              </Col>
              <Col span={24} style={{ overflow: "auto" }}>
                <Card
                  title="Processor Based Distribution"
                  loading={isFetching}
                  style={{ height: "400px" }}
                >
                  <ReactECharts
                    key={"3"}
                    option={{
                      tooltip: {
                        trigger: "item",
                      },
                      legend: {
                        orient: "vertical",
                        left: "left",
                      },
                      series: [
                        {
                          name: "Processor Based",
                          type: "pie",
                          radius: ["40%", "70%"],
                          data: chartData?.processorInfo,
                          emphasis: {
                            itemStyle: {
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                          },
                        },
                      ],
                    }}
                    onEvents={{
                      click: (e: any) => {
                        onClick({ s: e.name, f: "Details.Cpu.Name" });
                      },
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Card
              style={{ height: "83vh" }}
              title="Top Installed Software"
              loading={isFetching}
            >
              <Table
                className="InvTable1"
                size="small"
                columns={[
                  {
                    title: "Software Name",
                    dataIndex: "name",
                    key: "category",
                    width: "70%",
                  },
                  {
                    title: "Count",
                    dataIndex: "count",
                    key: "category1",
                    width: "30%",
                  },
                ]}
                style={{ overflow: "hidden", boxSizing: "border-box" }}
                scroll={{ y: "60vh" }} // Or another percentage that suits your layout
                dataSource={installedData?.items?.map((i: any, index: any) => {
                  return { ...i, key: index };
                })}
                pagination={{
                  // showTotal: (total, range) =>
                  //   `${range[0]}-${range[1]} of ${total} items`,
                  // selectPrefixCls: "custom-table-select",
                  // current: pagingItems.TotalProducts.currentPage,
                  // pageSize: pagingItems.TotalProducts.pageSize,
                  // showSizeChanger: true,
                  // total: pagingItems.TotalProducts.totalRecords,
                  pageSizeOptions: pageSizeOption,
                }}
                // scroll={{ y: "30vh" }}
              />
            </Card>
          </Col>
        </Row>
      );
    },
    () => true
  );
  return (
    <>
      <ChartBoard />
      {open === "Modal1" && (
        <Modal1
          // data={pcConfigReportoutData}
          listParams={listParams}
          onCancel={() => {
            setOpen("");
          }}
        />
      )}
    </>
  );
};

export default Information;
