import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ProductService, { Category } from "../../Services/ProductService";
import { dayjs } from "../../../Utilities/dayjs";

const { Option } = Select;
interface IStockAdjustmentProps {
  category: Category;
  selectedRecord: any;
  onDismiss: (rec: boolean) => void;
}

const StockAdjustment = (props: IStockAdjustmentProps) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [operator, setOperator] = useState("add");
  const [loading, setLoading] = useState(false);

  const getNewQty = () => {
    const adjustedQty = form.getFieldValue("adjustedQty");
    if (adjustedQty !== undefined) {
      let res =
        operator === "minus"
          ? props?.selectedRecord?.inStock - adjustedQty
          : props?.selectedRecord?.inStock + adjustedQty;
      form.setFieldValue("quantity", res);
      form.validateFields();
    }
  };

  useEffect(() => {
    getNewQty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operator]);

  const onSave = () => {
    form.validateFields().then((values) => {
      setLoading(true);
      let val = { ...values, productId: props?.selectedRecord?.id };
      ProductService.saveAdjustedStock(val).then((res) => {
        if (res?.status) {
          messageApi.success(
            `New quantity added to ${props?.selectedRecord?.name}`,
            0.5
          );
          setLoading(false);
          setTimeout(() => {
            props.onDismiss(true);
          }, 600);
        } else {
          messageApi.error(res?.message);
          setLoading(false);
        }
      });
    });
  };

  const selectBefore = (
    <Select
      defaultValue="add"
      style={{ width: 60 }}
      onChange={(val) => setOperator(val)}
    >
      <Option value="add">+</Option>
      <Option value="minus">-</Option>
    </Select>
  );
  return (
    <>
      {contextHolder}
      <Drawer
        open={true}
        width="35vw"
        zIndex={1005}
        title="Stock Adjustment"
        // placement="left"
        className="InvDetailsDrawer"
        extra={
          <Button
            // className="border-none"
            size="small"
            icon={<CloseOutlined />}
            onClick={() => props.onDismiss(false)}
          />
        }
        footer={
          <>
            <Button
              type="primary"
              className="Inv-SaveBtn"
              loading={loading}
              onClick={onSave}
            >
              Save
            </Button>
            <Button
              // className="Inv-CancelBtn"
              onClick={() => props.onDismiss(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Divider className="m-0 " />
        <Form
          form={form}
          size="middle"
          layout="vertical"
          className="InvAddForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item label="Date" name="date" initialValue={dayjs()}>
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%", borderRadius: "3px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={`Number of Stock (in ${props?.selectedRecord?.unit})`}
              >
                <Input value={props?.selectedRecord?.inStock} readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item
                label="Quantity Adjusted"
                name="adjustedQty"
                extra="Select '-' sign for subtraction and '+' for addition."
                rules={[
                  {
                    //set range of input value to max of 4 digits
                    pattern: new RegExp(/^\d{0,4}$/),
                    message: "Limit exceeded!",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  controls={false}
                  onBlur={getNewQty}
                  addonBefore={selectBefore}
                  style={{ width: "100%", borderRadius: "3px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="New Quantity in stock"
                name="quantity"
                rules={[
                  {
                    type: "number",
                    min: 0,
                    message: "Quantity can't be negative.",
                  },
                ]}
              >
                <InputNumber
                  readOnly
                  controls={false}
                  style={{ width: "100%", borderRadius: "3px" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item label="Reason" name="reason">
                <Input.TextArea maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Note" name="note">
                <Input.TextArea maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default StockAdjustment;
