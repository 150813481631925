import React, { useContext, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Popover,
  Form,
  Row,
  Select,
  Spin,
  Typography,
  message,
  ConfigProvider,
} from "antd";
import SearchImg from "../../Assets/images/search.svg";
import NotificationImg from "../../Assets/images/notification.svg";
import ProfileImg from "../../Assets/images/newImg/profile.svg";
import "./Nav.css";
import "../../Common/Common.css";
import {
  DownOutlined,
  LogoutOutlined,
  RightOutlined,
  SettingOutlined,
  UserOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../../../Services/AuthService";
import TestUserImg from "../../Assets/images/TestUser.jpg";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import { UserContext } from "../../../Context/UserContext";
import { ApplicationRouteLink } from "../../Routes/ApplicationRoutePath";
import secureLocalStorage from "react-secure-storage";
import {
  getSecureSessionItem,
  loginUserSessionKey,
  userRightsSessionKey,
} from "../../Context/ApplicationContext/ApplicationState";
const SearchContent = () => {
  return (
    <>
      <Input />
    </>
  );
};

const NotificationContent = () => {
  return (
    <>
      <div className="notificationDivStyle">
        <div>
          <div className="notificationHeadingStyle">
            <span>Low stock update</span>
          </div>
          <div className="notificationDetailsStyle">
            <span>
              List of products are closed to end. Please check and fill it as
              soon as possible to avoid end stage.
            </span>
          </div>
          <div className="notificationDateTimeStyle">
            <span>Today, 09.15 AM</span>
          </div>
          <Divider style={{ marginTop: 7, marginBottom: 10 }} />
        </div>

        <div>
          <div className="notificationHeadingStyle">
            <span>Close to Expiry!</span>
          </div>
          <div className="notificationDetailsStyle">
            <span>
              List of products are closed to end. Please check and fill it as
              soon as possible to avoid end stage.
            </span>
          </div>
          <div className="notificationDateTimeStyle">
            <span>Today, 09.15 AM</span>
          </div>
          <Divider style={{ marginTop: 7, marginBottom: 10 }} />
        </div>

        <div>
          <div className="notificationHeadingStyle">
            <span>Products are pending for approval.</span>
          </div>
          <div className="notificationDetailsStyle">
            <span>
              List of products are closed to end. Please check and fill it as
              soon as possible to avoid end stage.
            </span>
          </div>
          <div className="notificationDateTimeStyle">
            <span>Today, 09.15 AM</span>
          </div>
        </div>
      </div>
    </>
  );
};

const Nav = () => {
  const [showPopup, setShowPopup] = useState(false);
  const loginUser: any = getSecureSessionItem(loginUserSessionKey);
  const loginUserRights: any = getSecureSessionItem(userRightsSessionKey);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changePasswordForm] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const info = (msg: any) => {
    messageApi.success(msg, 1);
  };
  const error = (mes: any) => {
    messageApi.error(mes);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    changePasswordForm.validateFields().then((val: any) => {
      setConfirmLoading(true);
      AuthService.changePassword(loginUser?.id, val).then((res: any) => {
        if (res?.data?.result === true) {
          info("Password Changed Successfully");
          setIsModalOpen(false);
          changePasswordForm.resetFields();
          secureLocalStorage.clear();
          AuthService.logout().then((user) => {
            setTimeout(() => {
              window.location.href = "/";
            }, 1000);
          });
        } else {
          error(res?.data?.message);
        }
        setConfirmLoading(false);
      });
    });
  };

  const handleCancel = () => {
    changePasswordForm.resetFields();
    setIsModalOpen(false);
  };
  const ProfileContent = (props: any) => {
    const navigate = useNavigate();
    const UserLogOut = () => {
      secureLocalStorage.clear();
      AuthService.logout().then((user) => {
        navigate("/");
      });
    };
    return (
      <>
        {/* <div className="container">
        <div className="userDiv">
          <div>
            <img src={ProfileImg} alt="Profile" />
          </div>
          <div>
            <div>
              Manish Patel (Admin)
            </div>
            <div className="userId">
              manishpatel@gmail.com
            </div>
          </div>
        </div>
        <div>
          Team Member
        </div>
      </div> 
      <div className="cursorPointer">
        Sign Out
      </div>
  */}

        {/* <div style={{ width: 195 }}>
          <Row justify="center" className="mt-3 mb-2">
            <Avatar
              src={TestUserImg}
              className="avatar-user cursor"
            />
          </Row>
          <Row justify="center">
            <Typography.Title level={5} className="mb-2" style={{ margin: 0 }}>
              {props?.loginUser?.name}
            </Typography.Title>
          </Row>
          <Row justify="center" className="mb-2">
            {props?.loginUser?.email}
          </Row>

          <Row className="border-bottom"></Row>
          <Row
            className="py-2"
            style={{ paddingLeft: 9, paddingRight: 9 }}
            align="middle"
          >
            <Col className="cursor">
              <Button
                type="text"
                size="small"
                className="linkcolor"

                onClick={() => showModal()}
              >
                Change Password
              </Button>
            </Col>
          </Row>
          <Row
            className="py-2"
            style={{ paddingLeft: 9, paddingRight: 9 }}
            align="middle"
          >
            <Col className="cursor">
              <Button
                type="text"
                size="small"
                className="linkcolor"
                icon={<SettingOutlined />}
                onClick={() => showModal()}
              >
                Setting
              </Button>
            </Col>
          </Row>
          <Row
            className="py-2"
            style={{ paddingLeft: 9, paddingRight: 9 }}
            align="middle"
          >
            <Col className="cursor">
              <Button
                type="text"
                size="small"
                className="linkcolor"
                icon={<LogoutOutlined rotate={-90} />}
                onClick={() => UserLogOut()}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </div> */}

        <div style={{ width: 270 }} className="px-1 pt-1">
          <Row
            gutter={24}
            align={"middle"}
            className="cursorPointer teamMember py-2"
            onClick={() => {
              setShowPopup(false);
              navigate(ApplicationRouteLink.Users.UserProfile);
            }}
          >
            <Col lg={4}>
              <Avatar
                // style={{ width: 40, height: 40 }}
                icon={<UserOutlined />}
                // className="avatar-user cursor"
              />
            </Col>
            <Col lg={20}>
              <div className="d-flex">
                {props?.loginUser?.name}
                {/* Pankaj Singh Patwal */}
                <div style={{ color: "gray", marginLeft: 10 }}>
                  ({loginUser?.role})
                </div>
              </div>
              <div style={{ fontSize: 11, fontWeight: 500 }}>
                {props?.loginUser?.email}
              </div>
            </Col>
          </Row>
          {loginUserRights?.profile?.showTeamMember ? (
            <Row
              gutter={24}
              className="mt-3  fs12 fw500 cursorPointer teamMember py-2 mb-2"
              onClick={() => {
                navigate(ApplicationRouteLink.Users.UserList);
                setShowPopup(false);
              }}
            >
              <Col lg={20}>Team Member</Col>
              <Col lg={2}>
                <RightOutlined />
              </Col>
            </Row>
          ) : null}
          {loginUserRights?.profile?.showSetting ? (
            <Row
              gutter={24}
              className="mt-3  fs12 fw500 cursorPointer teamMember py-2 mb-2"
              onClick={() => {
                navigate(ApplicationRouteLink.Setting);
                setShowPopup(false);
              }}
            >
              <Col lg={20}>Setting</Col>
              <Col lg={2}>
                <RightOutlined />
              </Col>
            </Row>
          ) : null}

          {/* <Row gutter={24}>
            <Col lg={24}>
              <Button
                type="text"
                size="small"
                onClick={() => showModal()}
                style={{ padding: "0px 0px", width: "100%" }}
              >
                Change Password
              </Button>
            </Col>
          </Row> */}
          <Row
            gutter={24}
            className="fs12 fw500 cursorPointer teamMember py-2 mb-2"
            onClick={() => {
              setShowPopup(false);
              showModal();
            }}
          >
            <Col lg={20}>Change Password</Col>
          </Row>
          <Row
            gutter={24}
            className="cursorPointer mt-3"
            align={"middle"}
            justify={"center"}
            style={{ backgroundColor: "#1f77fb", color: "white" }}
            onClick={() => UserLogOut()}
          >
            <Col lg={24}>
              <div
                className="d-flex m-2 font16 fw500"
                style={{ justifyContent: "center" }}
              >
                <div>
                  <LogoutOutlined className="me-2" />
                </div>
                <div>Sign Out</div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };
  const ChangePassword = () => {
    return (
      <Form
        form={changePasswordForm}
        name="changePassword"
        initialValues={{ remember: true }}
        layout="vertical"
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[
            {
              required: true,
              message: "enter current password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Enter Old password" />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "enter new password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Enter New password" />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "enter confirm password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter New Confirm password" />
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      {contextHolder}
      <div className="navLayoutStyle  responsive">
        {/* <div className=''>
                <img src={LogoImg} alt="Logo" className='logoStyle' />
            </div> */}
        <div>
          <div className="secondPartStyle" style={{ justifyContent: "end" }}>
            {/* <div className="spaceBetweenSection">
            <Popover content={<SearchContent />} title="Search" trigger="click">
              <div className="searchStyle">
                <img src={SearchImg} alt="Search" />
              </div>
            </Popover>
          </div> */}

            {/* Temporary Remove  */}
            {/* <div className="spaceBetweenSection cursorPointer">
            <Popover

              content={<NotificationContent />}
              title={
                <span className="notificationTextStyle">Notification</span>
              }
              trigger="click"
            >
              <div className="notificationStyle">
                <Badge dot size="small" offset={[0, 4]}>
                  <img src={NotificationImg} alt="Notification" />
                </Badge>
              </div>
            </Popover>
          </div> */}
            <div className="cursorPointer">
              <ConfigProvider theme={{ token: { borderRadius: 0 } }}>
                <Popover
                  content={<ProfileContent loginUser={loginUser} />}
                  title=""
                  trigger="click"
                  zIndex={1005}
                  placement="bottomLeft"
                  onOpenChange={setShowPopup}
                  open={showPopup}
                >
                  <Avatar
                    // style={{ width: 45, height: 45 }}
                    // src={TestUserImg}
                    size={"large"}
                    icon={<UserOutlined />}
                    // className="avatar-user cursor profileStyle"
                    onClick={() => setShowPopup(!showPopup)}
                  />
                  {/* <div className="profileStyle">
                <img src={ProfileImg} alt="Profile" />
              </div> */}
                </Popover>
              </ConfigProvider>
            </div>
            {/*Temporary Remove*/}

            {/* <div style={{ display: "flex" }}>
            <div className="">
              {loginUser?.role} <DownOutlined />
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <Modal
        confirmLoading={confirmLoading}
        maskClosable={false}
        zIndex={1005}
        okText={"Save"}
        title="Change Password"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ChangePassword />
      </Modal>
    </>
  );
};

export default Nav;
