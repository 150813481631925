import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  InputRef,
  Row,
  Select,
  Space,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import ProductService, { Category } from "../../Services/ProductService";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import "../../Common/Common.css";
import { dayjs } from "../../../Utilities/dayjs";
import MastersService from "../../../CapsitechConstruction/Services/MastersService";
import VendorService from "../../Services/VendorService";
import MasterService from "../../Services/MasterService";
const { RangePicker } = DatePicker;
interface AddProductsProp {
  getSubCategory: () => Promise<void>;
  onDismiss: (rec: boolean) => void;
  subCategoryOptions: any;
  setShouldRefresh: () => void;
}
const AddInOption = ({
  funcType,
  selectedMaterial,
  setItemOpt,
  subCategoryOptions,
  getSubCategory,
}: any) => {
  const { getVendorOptions, getGstOptions } = useContext(ApplicationContext);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [status, setStatus] = useState<any>("success");
  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = async (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "vendor") {
        await VendorService.post({ name: name, vendorType: 0 }).then(
          (r: any) => {
            if (r?.message) {
              message.error("Vendor is already exist");
            } else {
              getVendorOptions();
              setName("");
              inputRef.current?.focus();
            }
          }
        );
      } else if (funcType === "subCategory")
        MasterService.postSubCategory({
          name: name,
          category: Category.PaidSoftware,
        }).then((r: any) => {
          getSubCategory();
          setName("");
          inputRef.current?.focus();
        });
      else if (funcType === "gst") {
        await MastersService.postGst({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("GST is already exist");
          } else {
            getGstOptions();
            setName("");
            inputRef.current?.focus();
          }
        });
      }
    }
  };
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 0px 4px" }}>
        {funcType === "subCategory" ? (
          <Input
            placeholder="Enter Option"
            ref={inputRef}
            style={{ width: "100%" }}
            value={name}
            onChange={onNameChange}
            status={status}
            onPressEnter={addOption}
          />
        ) : (
          <Input
            type="number"
            placeholder="Enter Option"
            ref={inputRef}
            value={name}
            onChange={onNameChange}
            status={status}
            onPressEnter={addOption}
            minLength={1}
            maxLength={3}
            min={0}
          />
        )}
        <Button type="text" icon={<PlusOutlined />} onClick={addOption} />
      </Space>
    </>
  );
};
// const AddInOption = ({ funcType, category, getSubCategory }: any) => {
//   const [name, setName] = useState("");
//   const inputRef = useRef<InputRef>(null);
//   const [status, setStatus] = useState<any>("success");

//   const { getUnits } = useContext(ApplicationContext);

//   const onNameChange = (e: any) => {
//     setName(e.target.value);
//   };
//   const addOption = (e: any) => {
//     e?.stopPropagation();
//     if (name.trim() === "") {
//       setStatus("error");
//     } else {
//       setStatus("success");
//       if (funcType === "subCategory")
//         MasterService.postSubCategory({ name: name, category: category }).then(
//           (r: any) => {
//             if (r?.message) {
//               message.error("Product category already exist");
//             } else {
//               getSubCategory();
//               setName("");
//               inputRef.current?.focus();
//             }
//           }
//         );
//       else if (funcType === "unit")
//         MasterService.postUnit({ name: name }).then((r: any) => {
//           if (r?.message) {
//             message.error(r?.message);
//           } else {
//             getUnits();
//             setName("");
//             inputRef.current?.focus();
//           }
//         });
//     }
//   };
//   return (
//     <>
//       <Divider style={{ margin: "8px 0" }} />
//       <Space style={{ padding: "0 0px 4px" }}>
//         <Input
//           placeholder="Enter Option"
//           ref={inputRef}
//           value={name}
//           onChange={onNameChange}
//           status={status}
//           onPressEnter={addOption}
//         />
//         <Button type="text" icon={<PlusOutlined />} onClick={addOption} />
//       </Space>
//     </>
//   );
// };
const AddPaidSoftware = (props: AddProductsProp) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const [paidSoftwareForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const inputRef = React.useRef<any>(null);
  // const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);

  const { selectAfter, currency, gstOption } = useContext(ApplicationContext);
  const resetForm = () => {
    paidSoftwareForm.resetFields();
    props.setShouldRefresh();
  };

  const onSave = (onlySave: boolean) => {
    paidSoftwareForm.validateFields().then(async (values) => {
      let val = {
        ...values,
        currency: currency?.value,
        category: Category.PaidSoftware,
        gst: {
          id: values?.gst?.value,
          name: values?.gst?.label,
        },
        subscription:
          values?.subscription === undefined
            ? null
            : {
                startDate: values?.subscription[0],
                endDate: values?.subscription[1],
              },
        subCategory: {
          id: values?.subCategory?.value,
          name: values?.subCategory?.label,
        },
      };

      setButtonDisable(true);

      await ProductService.post(val).then((res) => {
        if (res?.status) {
          messageApi.success(`Product ${val?.name} Added Successfully`, 1);
          setTimeout(() => {
            onlySave ? props.onDismiss(true) : resetForm();
          }, 1000);
        } else {
          messageApi.error(res?.message);
        }
        setButtonDisable(false);
      });
    });
  };

  // const items: TabsProps["items"] = [
  //   {
  //     key: "1",
  //     label: `Dashboard`,
  //     children: (
  //       <>
  //         <Form
  //           form={paidSoftwareForm}
  //           labelCol={{ span: 18 }}
  //           wrapperCol={{ span: 24 }}
  //           // style={{ maxWidth: 800 }}
  //           // className="InvAddForm"
  //           layout="vertical"
  //           initialValues={{
  //             numberOfStock: 1,
  //             pricePerUnit: 0,
  //             totalAmount: 0,
  //             inrRate: 0,
  //           }}
  //           autoComplete="off"
  //           // size="large"
  //         >
  //           <Row gutter={[26, 12]}>
  //             <Col lg={{ span: 5 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Name"
  //                 name="name"
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: "Please input name",
  //                   },
  //                   {
  //                     whitespace: true,
  //                   },
  //                 ]}
  //               >
  //                 <Input placeholder="Enter Product Name" ref={inputRef} />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
  //               <Form.Item
  //                 label="Purchase Date"
  //                 name="purchaseDate"
  //                 initialValue={dayjs()}

  //                 // rules={[
  //                 //   {
  //                 //     required: true,
  //                 //     message: "Please Select Date",
  //                 //   },
  //                 // ]}
  //               >
  //                 <DatePicker
  //                   placeholder="Select Purchase Date"
  //                   style={{ width: "100%" }}
  //                   format={"DD/MM/YYYY"}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
  //               <Form.Item
  //                 label="Expiry Date"
  //                 name="expiryDate"
  //                 // required
  //                 // rules={[
  //                 //   {
  //                 //     required: true,
  //                 //     message: "Please Select Date",
  //                 //   },
  //                 // ]}
  //               >
  //                 <DatePicker
  //                   placeholder="Select Expiry Date"
  //                   style={{ width: "100%" }}
  //                   format={"DD/MM/YYYY"}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           <Row gutter={[26, 12]}>
  //             <Col lg={{ span: 5 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Qty"
  //                 name="numberOfStock"
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: "Please Enter Qty",
  //                   },
  //                   {
  //                     pattern: new RegExp(/^\d{1,5}$/i),
  //                     message: "Please input number only upto 5 digits!",
  //                   },
  //                 ]}
  //               >
  //                 <InputNumber
  //                   type="number"
  //                   controls={false}
  //                   placeholder="Enter Qty"
  //                   style={{ width: "100%" }}
  //                   onChange={() => {
  //                     paidSoftwareForm.setFieldValue(
  //                       "totalPrice",
  //                       paidSoftwareForm.getFieldValue("numberOfStock") *
  //                         paidSoftwareForm.getFieldValue("pricePerUnit") *
  //                         paidSoftwareForm.getFieldValue("inrRate")
  //                     );
  //                   }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
  //               <Form.Item label="Rate" name="pricePerUnit" required>
  //                 <InputNumber
  //                   addonAfter={selectAfter}
  //                   onChange={() => {
  //                     paidSoftwareForm.setFieldValue(
  //                       "totalPrice",
  //                       paidSoftwareForm.getFieldValue("numberOfStock") *
  //                         paidSoftwareForm.getFieldValue("pricePerUnit") *
  //                         paidSoftwareForm.getFieldValue("inrRate")
  //                     );
  //                   }}
  //                   style={{ width: "100%" }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
  //               <Form.Item label="INR Rate" name="inrRate" required>
  //                 <InputNumber
  //                   onChange={() => {
  //                     paidSoftwareForm.setFieldValue(
  //                       "totalPrice",
  //                       paidSoftwareForm.getFieldValue("numberOfStock") *
  //                         paidSoftwareForm.getFieldValue("pricePerUnit") *
  //                         paidSoftwareForm.getFieldValue("inrRate")
  //                     );
  //                   }}
  //                   style={{ width: "100%" }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           <Row gutter={[26, 12]}>
  //             <Col lg={{ span: 5 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Total Amount"
  //                 name="totalPrice"
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: "Please Enter Amount ",
  //                   },
  //                   // {
  //                   //   pattern: new RegExp(/^\d{1,9}$/i),
  //                   //   message: "Please input number only upto 5 digits!",
  //                   // },
  //                 ]}
  //               >
  //                 <InputNumber
  //                   type="number"
  //                   controls={false}
  //                   placeholder="Enter Amount"
  //                   style={{ width: "100%" }}
  //                 />
  //               </Form.Item>
  //             </Col>

  //             <Col lg={{ span: 10 }} md={{ span: 24 }}>
  //               <Form.Item label="Description" name="description">
  //                 <Input.TextArea
  //                   placeholder="Description"
  //                   autoSize={{ minRows: 1, maxRows: 6 }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           {/* <Row>
  //             <Col lg={{ span: 12 }} md={{ span: 24 }}>
  //               <Form.Item label="Description" name="description">
  //                 <Input.TextArea
  //                   placeholder="Description"
  //                   autoSize={{ minRows: 2, maxRows: 6 }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row> */}
  //         </Form>
  //       </>
  //     ),
  //   },
  // ];
  // const onTabChange = (newActiveKey: string) => {
  //   setActiveKey(newActiveKey);
  // };
  const calAmount = () => {
    paidSoftwareForm.setFieldValue(
      "amount",
      parseFloat(
        (
          paidSoftwareForm.getFieldValue("numberOfStock") *
          (paidSoftwareForm.getFieldValue("pricePerUnit") === 0
            ? 1
            : paidSoftwareForm.getFieldValue("pricePerUnit")) *
          (paidSoftwareForm.getFieldValue("inrRate") === 0
            ? 1
            : paidSoftwareForm.getFieldValue("inrRate"))
        ).toFixed(2)
      )
        ? parseFloat(
            (
              paidSoftwareForm.getFieldValue("numberOfStock") *
              (paidSoftwareForm.getFieldValue("pricePerUnit") === 0
                ? 1
                : paidSoftwareForm.getFieldValue("pricePerUnit")) *
              (paidSoftwareForm.getFieldValue("inrRate") === 0
                ? 1
                : paidSoftwareForm.getFieldValue("inrRate"))
            ).toFixed(2)
          )
        : 0
    );
    // paidSoftwareForm.setFieldValue("amount", paidSoftwareForm.getFieldValue("quantity") * paidSoftwareForm.getFieldValue("rate"))
    paidSoftwareForm.setFieldValue(
      "totalPrice",
      parseFloat(
        (
          (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
            paidSoftwareForm.getFieldValue("amount") +
          paidSoftwareForm.getFieldValue("amount")
        ).toFixed(2)
      )
        ? parseFloat(
            (
              (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
                paidSoftwareForm.getFieldValue("amount") +
              paidSoftwareForm.getFieldValue("amount")
            ).toFixed(2)
          )
        : 0
    );
    paidSoftwareForm.setFieldValue(
      "gstAmount",
      parseFloat(
        (
          (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
          paidSoftwareForm.getFieldValue("amount")
        ).toFixed(2)
      )
        ? parseFloat(
            (
              (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
              paidSoftwareForm.getFieldValue("amount")
            ).toFixed(2)
          )
        : 0
    );
  };
  return (
    <>
      {contextHolder}
      <Drawer
        title={"Add Product"}
        className="InvAddDrawer-A"
        open={true}
        width="35vw"
        zIndex={1005}
        extra={<CloseOutlined onClick={() => props.onDismiss(false)} />}
        closable={false}
        // style={{ height: `calc(100vh - 100px)` }}
        // getContainer={false}
        onClose={() => props.onDismiss(false)}
        afterOpenChange={() => setActiveKey("1")}
        footer={
          <>
            <>
              <Button
                htmlType="submit"
                type="primary"
                ghost
                className="Inv-SaveBtn"
                onClick={() => onSave(true)}
                disabled={buttonDisable}
              >
                Save
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                ghost
                className="Inv-SaveAndNewBtn"
                onClick={() => onSave(false)}
                disabled={buttonDisable}
              >
                Save and New
              </Button>
            </>

            <Button
              htmlType="button"
              // className="Inv-CancelBtn"
              danger
              onClick={() => props.onDismiss(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        {/* <Tabs
          activeKey={activeKey}
          items={items}
          onChange={onTabChange}
          className="InvTab"
        /> */}
        <Form
          form={paidSoftwareForm}
          colon={false}
          requiredMark={false}
          labelAlign="left"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          // style={{ maxWidth: 800 }}
          className="InvAddForm"
          // layout="vertical"
          initialValues={{
            numberOfStock: 1,
            pricePerUnit: 1,
            amount: 1,
            totalPrice: 0,
            inrRate: 1,
            gstAmount: 0,
          }}
          autoComplete="off"
          // size="large"
        >
          {/* <Row gutter={[26, 12]}>
            <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          <Form.Item
            label="Name"
            name="name"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input name",
            //   },
            //   {
            //     whitespace: true,
            //   },
            // ]}
          >
            <Input placeholder="Enter Product Name" ref={inputRef} />
          </Form.Item>
          <Form.Item label="Subcategory" name="subCategory">
            {/* <Select options={subCategoryOptions} /> */}
            <Select
              placeholder="Select Product Category"
              style={{ width: "100%" }}
              labelInValue
              allowClear
              showSearch
              listHeight={224}
              options={props?.subCategoryOptions}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <AddInOption
                    funcType={"subCategory"}
                    category={Category.PaidSoftware}
                    getSubCategory={props.getSubCategory}
                  />
                </>
              )}
            />
          </Form.Item>
          <Form.Item
            label="Project Name"
            name="projectName"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input project name",
            //   },
            //   {
            //     whitespace: true,
            //   },
            // ]}
          >
            <Input placeholder="Enter Project Name" ref={inputRef} />
          </Form.Item>
          <Form.Item
            label="Subscription"
            name="subscription"
            // rules={[
            //   {
            //     required: true,
            //     message: "Select Subscription Date",
            //   },
            // ]}
          >
            <RangePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          </Form.Item>
          <Form.Item label="Invoice Number" name="invoiceNumber">
            <Input placeholder="Enter Invoice Number" />
          </Form.Item>

          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}> */}
          <Form.Item
            label="Purchase Date"
            name="purchaseDate"
            // initialValue={dayjs()}

            // rules={[
            //   {
            //     required: true,
            //     message: "Please Select Date",
            //   },
            // ]}
          >
            <DatePicker
              placeholder="Select Purchase Date"
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
            />
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}> */}
          {/* <Form.Item
            label="Expiry Date"
            name="expiryDate"
            // required
            // rules={[
            //   {
            //     required: true,
            //     message: "Please Select Date",
            //   },
            // ]}
          >
            <DatePicker
              placeholder="Select Expiry Date"
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
            />
          </Form.Item> */}
          {/* </Col> */}
          {/* </Row>/ */}
          {/* <Row gutter={[26, 12]}> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          <Form.Item
            label="Qty"
            name="numberOfStock"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please Enter Qty",
            //   },
            //   {
            //     pattern: new RegExp(/^\d{1,5}$/i),
            //     message: "Please input number only upto 5 digits!",
            //   },
            // ]}
          >
            <InputNumber
              min={1}
              type="number"
              controls={false}
              placeholder="Enter Qty"
              style={{ width: "100%" }}
              onChange={() => {
                calAmount();
              }}
            />
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}> */}
          <Form.Item
            label="Rate"
            name="pricePerUnit"
            rules={[
              // {
              //   required: true,
              //   message: "Please enter rate",
              // },
              {
                pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
                message: "Please enter number upto two decimal places only!",
              },
            ]}
          >
            <InputNumber
              min={1}
              addonAfter={selectAfter}
              onChange={() => {
                calAmount();
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}> */}
          <Form.Item
            label="INR Rate"
            name="inrRate"
            rules={[
              // {
              //   required: true,
              //   message: "Please enter rate",
              // },
              {
                pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
                message: "Please enter number upto two decimal places only!",
              },
            ]}
            // rules={[
            //   {
            //     required: true,
            //     message: "Enter INR Rate",
            //   },
            // ]}
          >
            <InputNumber
              min={1}
              onChange={() => {
                calAmount();
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Amount" name="amount">
            <InputNumber
              readOnly
              placeholder="Amount"
              style={{ width: "100%" }}
            />
          </Form.Item>
          {/* </Col> */}
          {/* </Row> */}
          {/* <Row gutter={[26, 12]}> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}

          <Form.Item
            label="GST"
            name="gst"
            // rules={[
            //   {
            //     required: true,
            //     message: "Select GST",
            //   },
            // ]}
          >
            <Select
              onSelect={() => calAmount()}
              placeholder="Select GST"
              style={{ width: "100%" }}
              labelInValue
              allowClear
              options={gstOption}
              listHeight={224}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <AddInOption funcType={"gst"} />
                </>
              )}
            />
          </Form.Item>

          <Form.Item label="GST Amount" name="gstAmount">
            <InputNumber
              placeholder="GST Amount"
              type="number"
              controls={false}
              readOnly
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label="Total Price"
            name="totalPrice"
            rules={
              [
                // {
                //   required: true,
                //   message: "Please Enter Amount ",
                // },
                // {
                //   pattern: new RegExp(/^\d{1,9}$/i),
                //   message: "Please input number only upto 5 digits!",
                // },
              ]
            }
          >
            <InputNumber
              readOnly
              type="number"
              controls={false}
              placeholder="Total Amount"
              style={{ width: "100%" }}
            />
          </Form.Item>
          {/* </Col> */}

          {/* <Col lg={{ span: 10 }} md={{ span: 24 }}> */}
          <Form.Item label="Description" name="description">
            <Input.TextArea
              placeholder="Description"
              autoSize={{ minRows: 1, maxRows: 6 }}
            />
          </Form.Item>
          {/* </Col>/ */}
          {/* </Row>/ */}
          {/* <Row>
              <Col lg={{ span: 12 }} md={{ span: 24 }}>
                <Form.Item label="Description" name="description">
                  <Input.TextArea
                    placeholder="Description"
                    autoSize={{ minRows: 2, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row> */}
        </Form>
      </Drawer>
    </>
  );
};

export default AddPaidSoftware;
