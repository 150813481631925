import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Divider,
  Row,
  Col,
  Select,
  Button,
  Form,
  message,
  DatePicker,
} from "antd";
import { useForm } from "antd/es/form/Form";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import ProductStateService from "../../Services/ProductStateService";
import "../../Common/Common.css";
import { dayjs } from "../../../Utilities/dayjs";
import { Category } from "../../Services/ProductService";
const { Option } = Select;

interface DigitalModifyStateProps {
  // selectedRecord: any;
  productData: any;
  onDismiss: (rec: boolean) => void;
  setShow?: React.Dispatch<React.SetStateAction<string>>;
  setIsDataSaved?: React.Dispatch<React.SetStateAction<boolean>>;
}
const DigitalModifyState = (props: DigitalModifyStateProps) => {
  const [form] = useForm();
  const { assigneeOptions } = useContext(ApplicationContext);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    ProductStateService.getList(
      Category.DigitalProducts,
      props?.productData?.id
    ).then((res: any) => {
      if (res?.items[res?.items?.length - 1]?.issueDate)
        form.setFieldValue(
          "issueDate",
          dayjs(new Date(res?.items[res?.items?.length - 1]?.issueDate))
        );
    });
    // console.log(props?.selectedRecord, "010");
    if (props?.productData?.assignee?.length > 0) {
      // eslint-disable-next-line array-callback-return
      props?.productData?.assignee?.map((itm: any) => {
        let val = { label: itm?.name, value: itm?.id };
        form.setFieldValue("assignee", val);
      });
    }
    if (props?.productData?.digiStatus === 0)
      form.setFieldValue("digiProductState", 1);
    else form.setFieldValue("digiProductState", props?.productData?.digiStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.productData?.assignee]);

  const onSave = () => {
    setBtnDisabled(true);
    form.validateFields().then((val) => {
      let values = {
        ...val,
        assignee:
          val?.assignee === undefined
            ? null
            : { id: val?.assignee?.value, name: val?.assignee?.label },
        category: props?.productData?.category,
        productGroup: props?.productData?.productGroup,
        productId: props?.productData?.id,
      };
      ProductStateService.postDigitalAssignee(values).then((res) => {
        if (res?.status) {
          messageApi.success("Product assigned successfully.", 0.5);
          form.resetFields();
          if (props?.setIsDataSaved !== undefined) {
            props?.setIsDataSaved(true);
          }
          setTimeout(() => {
            props.onDismiss(true);
          }, 500);
        } else {
          messageApi.error(res?.message);
          form.resetFields();
          setBtnDisabled(false);
        }
      });
    });
  };
  return (
    <>
      {contextHolder}
      <Modal
        className="InvModal"
        zIndex={1010}
        title="Modify State"
        open={true}
        onCancel={() => {
          form.resetFields();
          props.onDismiss(false);
        }}
        width={830}
        style={{ top: 20, margin: "0 0 0 27vw", minHeight: "450px" }}
        footer={
          <>
            <Divider style={{ borderBottom: "1px solid #d2d3d4" }} />
            <Button
              onClick={() => {
                form.resetFields();
                props.onDismiss(false);
              }}
            >
              Cancel
            </Button>
            <Button type="primary" disabled={btnDisabled} onClick={onSave}>
              Save
            </Button>
          </>
        }
      >
        <Divider style={{ borderBottom: "1px solid #d2d3d4" }} />
        <Form
          form={form}
          // labelCol={{ span: 24 }}
          // wrapperCol={{ span: 24 }}
          style={{ maxWidth: 800 }}
          layout="vertical"
          initialValues={{ remember: true }}
          autoComplete="off"
          size="large"
        >
          <Row gutter={[20, 10]}>
            <Col lg={{ span: 8 }} sm={{ span: 12 }}>
              <Form.Item
                label="Issue Date"
                name="issueDate"
                initialValue={dayjs()}
              >
                <DatePicker
                  placeholder="Select Date"
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 10]} className="pb-5">
            <Col lg={8}>
              <Form.Item
                name="digiProductState"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "Please select field.",
                  },
                ]}
              >
                <Select
                  listHeight={200}
                  allowClear
                  placeholder="Select State"
                  options={[
                    { label: "In Stock", value: 0 },
                    { label: "In Use", value: 1 },
                    { label: "In Repair", value: 3 },
                    { label: "Defective", value: 2 },
                    { label: "Repaired", value: 4 },
                    { label: "Replaced", value: 5 },
                    { label: "Dead", value: 6 }, //disposed
                  ]}
                />
              </Form.Item>
            </Col>
            <Col lg={8}>
              <Form.Item
                label="Assign To"
                name="assignee"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select field.",
                //   },
                // ]}
              >
                {/* <Select
                  listHeight={128}
                  labelInValue
                  placeholder="Select Assignee"
                  allowClear
                  showSearch
                  options={assigneeOptions}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                /> */}
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Assignee"
                  showSearch
                  maxLength={1}
                  maxTagTextLength={8}
                  allowClear
                  maxTagCount={1}
                  listItemHeight={50}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase().trim())
                  }
                  optionLabelProp="label"
                  labelInValue
                >
                  {assigneeOptions.map((itm: any, index: any) => {
                    return (
                      <Option value={itm?.value} label={itm?.label} key={index}>
                        <Row>
                          <Col md={24}>
                            <span className="fw600 font13 ">{itm?.label}</span>
                          </Col>
                          <Row>
                            <Col md={24}>
                              <span className="fontS fw500 ">
                                {itm?.designation}
                              </span>
                            </Col>
                            <Col md={24}>
                              <span className="fontS fw500 ">
                                {itm?.department}
                              </span>
                            </Col>
                          </Row>
                        </Row>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default DigitalModifyState;
