import {
  Row,
  Col,
  Table,
  Card,
  Select,
  Input,
  DatePicker,
  TimeRangePickerProps,
  Spin,
  message,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import "./Dashboard.css";
import "../../../Common/Common.css";

import { useNavigate } from "react-router-dom";
import ReactECharts from "echarts-for-react";
import DashboardServices, {
  FilterTypeCategory,
} from "../../../Services/DashboardServices";
import { Category } from "../../../Services/ProductService";
import { ColumnsType } from "antd/es/table";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";
import { CategoryData } from "../ApprovalTab/Approval";
import { dayjs } from "../../../../Utilities/dayjs";
import { LoadingOutlined } from "@ant-design/icons";
// import {} "../../../Services/RQDasboardService";
import {
  useExpiryList,
  useGetDashboard,
  useGetProducts,
} from "../../../Services/RQDasboardService";
const { Search } = Input;
type Props = {};
const { RangePicker } = DatePicker;
type THeader = {
  title: string;
  inputName?: string;
  setShouldRefresh?: any;
  changeListParams?: any;
  ifSelect?: boolean;
  listParams?: any;
  setListParams?: any;
  option?: any;
};
// console.log("getDashboardList", getDashboardList);

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
  option,
}: THeader) => {
  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {ifSelect ? (
          <Select
            style={{ width: "100%" }}
            onSelect={(value) => {
              changeListParams(inputName, value);
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            className="invSelectVendor"
            placeholder={title}
            allowClear
            onClear={() => {
              changeListParams(inputName);
              // setListParams({ ...listParams });
              setListParams((prev: any) => {
                return {
                  ...prev,
                  start: 0,
                };
              });
              // setShouldRefresh((x: boolean) => !x);
            }}
            // defaultValue={{ value: "-1", label: "All" }}
            options={option}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            // onChange={(e) =>
            //   // changeListParams(inputName, e.target?.value?.trim())
            // }
            onSearch={(val) => {
              changeListParams(inputName, val?.trim());

              // setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

const ChartColor: any = {
  "Digital Products": "#f64c4c",
  Cleaning: "#db4cf6",
  "Office Equipment": "#4cc7f6",
  "Paid Software": "#4cf69b",
  "Garden Or Plants": "#b8f64c",
  Stationery: "#f6b84c",
  "Medical Kit": "#594cf6",
};

const Dashboard = (props: Props) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(
    localStorage.getItem("activeTab") || "1"
  );
  localStorage.setItem("expensesGraphMode", "1");

  // Chart Data //
  const [legendHeading, setLegendHeading] = useState<string[]>([]);
  const [rangeData, setRangeData] = useState<string[]>([]);
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [series, setSeries] = useState<any[]>([]);
  const [pagingItems, setPagingItems] = useState<{
    TotalProducts: {
      totalRecords: number;
      currentPage: number;
      pageSize: number;
    };
    ExpirySoon: {
      totalRecords: number;
      currentPage: number;
      pageSize: number;
    };
  }>({
    TotalProducts: { totalRecords: 0, currentPage: 1, pageSize: 15 },
    ExpirySoon: { totalRecords: 0, currentPage: 1, pageSize: 5 },
  });
  const [listParams, setListParams] = useState<{
    TotalProducts: {
      category: FilterTypeCategory.All;
      name: string;
      subcategory: string;
      userName: string;
    };
    ExpirySoon: {
      fromDate: string;
      toDate: string;
      category: FilterTypeCategory.All;
    };
    start?: number;
    length?: number;
  }>({
    TotalProducts: {
      category: FilterTypeCategory.All,
      name: "",
      subcategory: "",
      userName: "",
    },
    ExpirySoon: {
      fromDate: "",
      toDate: "",
      category: FilterTypeCategory.All,
    },
  });

  // const [refresh, setRefresh] = useState(false);
  // const [shouldRefresh, setShouldRefresh] = useState(false);
  // // Table Data //
  // const [tableData1, setTableData1] = useState<any[]>([]);
  // const [tableData2, setTableData2] = useState<any[]>([]);

  const { data: inventoryExpense } = useGetDashboard();

  useMemo(() => {
    const convertArray: any = [];
    if (!!inventoryExpense?.message)
      message.error(
        `Error in Get Task List in Board => ${inventoryExpense?.message}`
      );
    setLegendHeading(inventoryExpense?.heading);
    setRangeData(inventoryExpense?.years);
    // eslint-disable-next-line array-callback-return
    inventoryExpense?.products?.map((item: any) => {
      convertArray.push({
        name: item?.name,
        type: "line",
        smooth: true,
        color: ChartColor[item?.name],
        data: item?.count,
      });
    });

    // Set Convert Data to Series //
    setSeries(convertArray);
    return {};
  }, [inventoryExpense]);

  const { data: productsData, isLoading: productLoading } = useGetProducts({
    ...listParams.TotalProducts,
    start:
      (pagingItems?.TotalProducts?.currentPage - 1) *
      pagingItems?.TotalProducts?.pageSize,
    length: pagingItems?.TotalProducts?.pageSize,
  });

  const pTable = useMemo(() => {
    if (productsData?.items.length > 0) {
      setPagingItems((p) => {
        p.TotalProducts.totalRecords = productsData?.totalRecords;
        return p;
      });
      return productsData?.items?.map((item: any) => {
        return {
          ...item,
          key: item.id,
          name: item?.name,
          category: CategoryData[item?.category],
          // subcategory: item?.subCategory?.name,
          addedBy: item?.createdBy?.userName,
          numberOfStock: item?.numberOfStock,
          status: item?.productStatusFlag,
        };
      });
    } else {
      return [];
    }
  }, [productsData]);

  const { data: expiryData, isLoading: expiryLoading } = useExpiryList({
    ...listParams.ExpirySoon,
    start:
      (pagingItems?.ExpirySoon?.currentPage - 1) *
      pagingItems?.ExpirySoon?.pageSize,
    length: pagingItems?.ExpirySoon?.pageSize,
  });

  const eTable = useMemo(() => {
    if (expiryData?.items.length > 0) {
      setPagingItems((p) => {
        p.ExpirySoon.totalRecords = expiryData?.totalRecords;
        return p;
      });
      return expiryData?.items?.map((itm: any) => {
        return {
          ...itm,
          key: itm.id,
          category: CategoryData[itm?.category],
          date: itm?.expiryDate
            ? itm?.expiryDate
            : itm?.Subscription?.EndDate
            ? itm?.Subscription?.EndDate
            : "-",
        };
      });
    } else {
      return [];
    }
  }, [expiryData]);

  const tableLoading1 = {
    spinning: loading1,
    indicator: <Spin indicator={<LoadingOutlined />} size="small" />,
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
    { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
  ];

  const changeListParams = (key: string, value: any) => {
    const keys = key.split(".");
    setListParams((prevState: any) => {
      return {
        ...prevState,
        [keys[0]]: {
          ...prevState[keys[0]],
          [keys[1]]: value,
        },
      };
    });
    setPagingItems((prevState: any) => {
      return {
        ...prevState,
        [keys[0]]: {},
      };
    });
    // setPagingItems(()=>{pagingItems.})
    /*
      (pagingItems?.TotalProducts?.currentPage - 1) *
      pagingItems?.TotalProducts?.pageSize,
    */
  };

  const chartData = {
    color: [
      "#f64c4c",
      "#db4cf6",
      "#4cc7f6",
      "#4cf69b",
      "#b8f64c",
      "#f6b84c",
      "#594cf6",
    ],

    tooltip: {
      trigger: "axis",
    },

    legend: {
      top: "bottom",
      textStyle: {
        fontFamily: "REM, sans- serif",
        fontSize: "11",
        marginLeft: "20",
        fontWeight: "600",
        padding: 1,
      },
      icon: "circle",
      data: legendHeading,
      itemGap: 16,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "22%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: true,
      data: rangeData, // Weekly Days or Monthly Month Name and Yearly Years  by API //
    },
    yAxis: {
      type: "value",
    },
    emphasis: {
      focus: "series",
    },
    series: series,
    width: "100%",
  };

  let option = [
    { label: "Cleaning", value: Category.Cleaning },
    { label: "Digital Products", value: Category.DigitalProducts },
    { label: "Garden", value: Category.Garden },
    { label: "MedicalKit", value: Category.MedicalKit },
    { label: "Office Equipments", value: Category.OfficeEquipments },
    { label: "PaidSoftware", value: Category.PaidSoftware },
    { label: "Stationary", value: Category.Stationary },
  ];

  //#region  commented code
  // const getData = async () => {
  //   const convertArray: any = [];
  //   const tableArray: any = [];

  //   await DashboardServices.getInventoryExpenses().then((res) => {
  //     setLegendHeading(res?.heading);
  //     setRangeData(res?.years);

  //     // eslint-disable-next-line array-callback-return
  //     res?.products?.map((item: any) => {
  //       convertArray.push({
  //         name: item?.name,
  //         type: "line",
  //         smooth: true,
  //         color: ChartColor[item?.name],
  //         data: item?.count,
  //       });
  //     });

  //     // Set Convert Data to Series //
  //     setSeries(convertArray);
  //   });

  //   // Convert tableData to UI Table Data //
  //   // eslint-disable-next-line array-callback-return
  //   JsonData[0].tableData.map((item: any, index: any) => {
  //     tableArray.push({
  //       key: index,
  //       cateories: item.cateories,
  //       products: "-",
  //       amount: "-",
  //       // products: item.products === 0 ? "-" : item.products,
  //       // amount: item.amount === 0 ? "-" : item.amount,
  //     });
  //   });
  //   tableArray.push({
  //     key: "total",
  //     cateories: JsonData[0].totalSummary.cateories,
  //     products: JsonData[0].totalSummary.product,
  //     amount: JsonData[0].totalSummary.amount,
  //   });
  // };

  // const dataSource = Array.from<any>({ length: 1146 }).map<any>((_, i) => ({
  //   key: i,
  //   name: `Edward King ${i}`,
  //   age: 32,
  //   address: `London, Park Lane no. ${i}`,
  // }));
  // const dataSource1 = Array.from<any>({ length: 1146 }).map<any>((_, i) => ({
  //   key: i,
  //   name: `Edward King ${i}`,
  //   age: 32,
  //   address: `London, Park Lane no. ${i}`,
  // }));
  // const JsonData = [
  //   {
  //     // Chart Data //
  //     legendHeading: [
  //       "DigitalProducts",
  //       "Cleaning",
  //       "OfficeEquipment",
  //       "PaidSoftware",
  //       "GardenOrPlants",
  //       "Stationery",
  //       "MedicalKit",
  //     ],
  //     rangeData: ["2017", "2018", "2019", "2020", "2021", "2022", "2023"],
  //     series: [
  //       {
  //         name: "DigitalProducts",
  //         data: [120, 132, 101, 134, 90, 230, 210000],
  //       },
  //       {
  //         name: "Cleaning",
  //         data: [220, 182, 191, 234, 290, 330, 310],
  //       },
  //       {
  //         name: "OfficeEquipment",
  //         data: [150, 232, 201, 154, 190, 330, 410],
  //       },
  //       {
  //         name: "PaidSoftware",
  //         data: [320, 332, 301, 334, 390, 330, 32000],
  //       },
  //       {
  //         name: "GardenOrPlants",
  //         data: [820, 932, 901, 934, 1290, 1330, 1320],
  //       },
  //       {
  //         name: "Stationery",
  //         data: [820, 932, 901, 934, 1290, 1330, 1320],
  //       },
  //       {
  //         name: "MedicalKit",
  //         data: [820, 932, 901, 934, 1290, 1330, 1320],
  //       },
  //     ],

  //     // Table Data //
  //     tableData: [
  //       {
  //         cateories: "Digital Products",
  //         products: 80,
  //         amount: 10000,
  //       },
  //       {
  //         cateories: "Cleaning",
  //         products: 90,
  //         amount: 5000,
  //       },
  //       {
  //         cateories: "Office Equipments",
  //         products: 1,
  //         amount: 0,
  //       },
  //       {
  //         cateories: "Digital Products",
  //         products: 0,
  //         amount: 0,
  //       },
  //       {
  //         cateories: "Garden Or Plants",
  //         products: 0,
  //         amount: 0,
  //       },
  //       {
  //         cateories: "Stationery",
  //         products: 0,
  //         amount: 0,
  //       },
  //       {
  //         cateories: "Medical Kit",
  //         products: 0,
  //         amount: 0,
  //       },
  //     ],

  //     // Total Summary //
  //     totalSummary: {
  //       cateories: "Total :",
  //       product: "-",
  //       amount: "-",
  //     },
  //   },
  // ];
  /*
  const formatDate = (dateString: string) => {
    if (
      dateString === "0001-01-01T00:00:00" ||
      dateString === "0001-01-01T00:00:00Z"
    ) {
      return "N/A";
    }
    const parsedDate = dayjs(dateString);
    return parsedDate.isValid()
      ? parsedDate.format("YYYY-MM-DD")
      : "Invalid Date";
  };

  const GetExpiry = useCallback(() => {
    setLoading1(true);
    let param = {
      ...listParams.ExpirySoon,
    };
    DashboardServices.geExpiryList(param)
      .then((res: any) => {
        let mem = res?.items?.map((itm: any) => ({
          ...itm,
          key: itm.id,
          category: CategoryData[itm?.category],
          date: itm?.expiryDate
            ? "1"
            : itm?.Subscription?.EndDate
            ? "212"
            : "-",
        }));

        setTableData2(() => mem);
        setLoading1(false);
      })
      .finally(() => {
        setLoading1(false);
      });
  }, [listParams.ExpirySoon]);

  const GetData1 = useCallback(() => {
    setLoading(true);
    let res = {
      ...listParams.TotalProducts,
      start:
        (pagingItems?.TotalProducts?.currentPage - 1) *
        pagingItems?.TotalProducts?.pageSize,
      length: pagingItems?.TotalProducts?.pageSize,
    };

    DashboardServices.getProductList({ ...res }).then((r: any) => {
      // eslint-disable-next-line array-callback-return
      // if (r?.items?.length > 0) {
      setTableData1(
        r?.items?.map((item: any) => {
          return {
            ...item,
            key: item.id,
            name: item?.name,
            category: CategoryData[item?.category],
            // subcategory: item?.subCategory?.name,
            addedBy: item?.createdBy?.userName,
            numberOfStock: item?.numberOfStock,
            status: item?.productStatusFlag,
          };
        })
      );

      setPagingItems((p) => {
        p.TotalProducts.totalRecords = r?.totalRecords;
        return p;
      });

      // }
      setLoading(false);
    });
  }, [listParams.TotalProducts]);
*/
  //#endregion

  // Table Data //
  const columns: ColumnsType<ColumnsType> = [
    {
      title: (
        <THeader
          title="Category"
          ifSelect={true}
          option={option}
          inputName="TotalProducts.category"
          setListParams={setListParams}
          listParams={listParams}
          // setShouldRefresh={setShouldRefresh}
          changeListParams={changeListParams}
        />
      ),
      dataIndex: "category",
      key: "category",
      width: "30%",
      render: (category: string) => (
        <div className="ps-2 text-dark font13">{category}</div>
      ),
    },
    {
      title: (
        <THeader
          title="Products"
          inputName="TotalProducts.name"
          listParams={listParams}
          setListParams={setListParams}
          // setShouldRefresh={setShouldRefresh}
          changeListParams={changeListParams}
        />
      ),
      dataIndex: "name",
      key: "name",
      render: (name: string) => (
        <div className="ps-2 text-dark font13">{name}</div>
      ),
    },
    {
      //title: <THeader title="Quantity" />,
      title: "Quantity",
      dataIndex: "numberOfStock",
      key: "numberOfStock",
      align: "end",
      // sorter: (a: any, b: any) => a.numberOfStock - b.numberOfStock,
      render: (qty: string) => <div className="font13 text-end">{qty}</div>,
    },
  ];
  // Expiry Table //

  const ExpiryCol: ColumnsType<ColumnsType> = [
    {
      title: (
        <THeader
          title="Category"
          ifSelect={true}
          option={option}
          inputName="ExpirySoon.category"
          setListParams={setListParams}
          listParams={listParams}
          // setShouldRefresh={setShouldRefresh}
          changeListParams={changeListParams}
        />
      ),
      dataIndex: "category",
      key: "category",
      width: "30%",
      render: (category: string) => (
        <div className="ps-2 text-dark font13">{category}</div>
      ),
    },
    {
      title: (
        <THeader
          title="Products"
          inputName="ExpirySoon.name"
          listParams={listParams}
          setListParams={setListParams}
          // setShouldRefresh={setShouldRefresh}
          changeListParams={changeListParams}
        />
      ),
      dataIndex: "name",
      key: "name",
      render: (name: string) => (
        <div className="ps-2 text-dark font13">{name}</div>
      ),
    },
    {
      //title: <THeader title="Quantity" />,
      title: "Expiry Date",
      dataIndex: "date",
      key: "date",
      // align: "end",
      render: (val: any) => (
        <div className=" font13">
          {dayjs(val).format("DD/MM/YYYY") && dayjs(val).format("DD/MM/YYYY")}
          {/* val */}
        </div>
      ),
    },
  ];

  const onRangeChange = (
    dates: null | (any | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      // GetExpiry(dates[0], dates[1]);
      setListParams((previous: any) => ({
        ...previous,
        ExpirySoon: {
          ...previous.ExpirySoon,
          fromDate: dayjs(dates[0]).toString(),
          toDate: dayjs(dates[1]).toString(),
        },
      }));
    } else {
      console.log("Clear");
    }
  };

  const navigate = useNavigate();

  const changeData = (key: string) => {
    localStorage.setItem("activeTab", key);
    setCurrentActiveTab(key);
  };
  useEffect(() => {
    // getData();
  }, []);

  useEffect(() => {
    // GetData1();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams.TotalProducts, pagingItems.TotalProducts]);
  useEffect(() => {
    // GetExpiry();
  }, [listParams.ExpirySoon, pagingItems.ExpirySoon]);

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        {/* ScrollContainer */}
        <div style={{ marginTop: 50 }}>
          <Row gutter={[8, 8]}>
            <Col span={14}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Card title="Inventory Expenses" style={{ height: "349px" }}>
                    <ReactECharts
                      option={chartData}
                      opts={{ renderer: "svg" }}
                      // style={{ height: "350px", width: "100%" }}
                    />
                  </Card>
                </Col>
                <Col span={24}>
                  <Card
                    title="Expiry Soon"
                    extra={
                      <RangePicker
                        presets={rangePresets}
                        onChange={onRangeChange}
                      />
                    }
                    style={{ height: "44vh", overflow: "auto" }}
                  >
                    <Table
                      // title={() => }
                      className="InvTable1 ant-table-wrapper"
                      size="small"
                      columns={ExpiryCol}
                      loading={{
                        spinning: expiryLoading,
                        indicator: (
                          <Spin indicator={<LoadingOutlined />} size="small" />
                        ),
                      }}
                      // dataSource={dataSource}
                      dataSource={eTable}
                      // scroll={{ y: `calc(100vh - 680px)` }}
                      style={{
                        overflow: "hidden",
                        boxSizing: "border-box",
                        minHeight: "100px",
                      }}
                      scroll={{ y: "25vh" }}
                      pagination={{
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items`,
                        selectPrefixCls: "custom-table-select",
                        current: pagingItems.ExpirySoon.currentPage,
                        pageSize: pagingItems.ExpirySoon.pageSize,
                        showSizeChanger: true,
                        total: pagingItems.ExpirySoon.totalRecords,
                        pageSizeOptions: [1, 15, 30, 50, 100, 200],
                      }}
                      onChange={(paging, filter, sort: any) => {
                        if (sort?.order) {
                          setListParams({
                            ...listParams,
                            // sortDir: sort?.order,
                            // sortCol: sort?.columnKey,
                          });
                        }

                        if (paging) {
                          setPagingItems((prevPagingItems) => ({
                            ...prevPagingItems,
                            ExpirySoon: {
                              ...prevPagingItems.ExpirySoon,
                              currentPage: paging?.current ?? 1,
                              pageSize: paging?.pageSize ?? 5,
                            },
                          }));
                        }
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Card
                title="Total Products"
                style={{ height: "82vh", overflow: "auto" }}
              >
                <Table
                  className="InvTable1"
                  loading={{
                    spinning: productLoading,
                    indicator: (
                      <Spin indicator={<LoadingOutlined />} size="small" />
                    ),
                  }}
                  dataSource={pTable}
                  columns={columns}
                  size="small"
                  style={{ overflow: "hidden", boxSizing: "border-box" }}
                  scroll={{ y: "60vh" }} // Or another percentage that suits your layout
                  pagination={{
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                    selectPrefixCls: "custom-table-select",
                    current: pagingItems.TotalProducts.currentPage,
                    pageSize: pagingItems.TotalProducts.pageSize,
                    showSizeChanger: true,
                    total: pagingItems.TotalProducts.totalRecords,
                    pageSizeOptions: pageSizeOption,
                  }}
                  onChange={(paging, filter, sort: any) => {
                    sort?.order &&
                      setListParams({
                        ...listParams,
                        // sortDir: sort?.order,
                        // sortCol: sort?.columnKey,
                      });
                    paging &&
                      setPagingItems((pagingItems) => {
                        return {
                          ...pagingItems,
                          TotalProducts: {
                            ...pagingItems.TotalProducts,
                            currentPage: paging?.current ?? 1,
                            pageSize: paging?.pageSize ?? 15,
                          },
                        };
                      });
                  }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

//#region  ScrollContainer
{
  /* <ScrollContainer
          style={{ display: "flex", width: "99%", userSelect: "auto" }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <div
                className="cardStyle"
                style={{ background: "#f2f4ff", borderColor: "#9eadfd" }}
                onClick={() => {
                  localStorage.setItem("Heading", "Cleaning");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.Cleaning
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={CleaningImg} alt="CleaningImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Cleaning</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#fffaed", borderColor: "#FFD770" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .GardenOrPlantsDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Garden Or Plants");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.GardenOrPlants
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={GardenOrPlantsImg} alt="GardenOrPlantsImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Garden or plants</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#eff9fc", borderColor: "#87D1E8" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks.StationeryDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Stationery");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.Stationery
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={StationeryImg} alt="StationeryImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Stationery</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#f2f4ff", borderColor: "#7086ff" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .EmergencyMedicalKitDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Emergency Medical Kit");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.EmergencyMedicalKit
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={MedicalKitImg} alt="MedicalKitImg" />
                </div>
              </div>

              <div className="cardSubHeadingStyle">
                <span>Medical kit</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#fcf3fd", borderColor: "#E085EA" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .PaidSoftwareDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Paid Software");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.PaidSoftware
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={PaidSoftwareImg} alt="PaidSoftwareImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Paid Software</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#fff7ef", borderColor: "#FFB870" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .DigitalProductDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Digital Product");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.DigitalProduct
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={DigitalProductImg} alt="DigitalProductImg" />
                </div>
              </div>

              <div className="cardSubHeadingStyle">
                <span>Digital Products</span>
              </div>
            </div>

            {/* <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#eff0f5", borderColor: "#9BA1C0" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .OfficeEquipmentsDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Office Equipments");
                  navigate(
                    ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    // ApplicationRouteLink.OfficeEquipments
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={OfficeEquipmentsImg} alt="OfficeEquipmentsImg" />
                </div>
              </div>

              <div className="cardSubHeadingStyle">
                <span>Office Equipments</span>
/              </div>
            </div> 
          </div>
        </ScrollContainer> */
}
//#endregion
