import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Popconfirm,
  // Radio,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import ProductService, {
  IProductApiProps,
  StockType,
} from "../../Services/ProductService";
import { Utils } from "../../../Utilities/Utils";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import {
  LabelValueModel,
  ProductStatusFlag,
} from "../../Context/ApplicationContext/ApplicationState";
import MasterService from "../../Services/MasterService";
import { dayjs } from "../../../Utilities/dayjs";
import TextArea from "antd/es/input/TextArea";

interface IEditProductProps {
  isPending: number;
  productData: IProductApiProps;
  seatingOptions: any;
  subCategoryOptions: any;
  setShow: React.Dispatch<React.SetStateAction<string>>;
  setIsDataSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditDigitalProduct = (props: IEditProductProps) => {
  const [form] = Form.useForm();
  const [enableEdit, setEnableEdit] = useState(false);
  const [addVariant, setAddVariant] = useState("");
  const [variantOptions, setVariantOptions] = useState<any[]>([]);
  const [editData, setEditData] = useState<any>({});
  const [productGrpOptions, setProductGrpOptions] = useState<any[]>([]);
  const [deleteRecord, setDeleteRecord] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [subCategory, setSubCategory] = useState<LabelValueModel>({
    label: "",
    value: "",
  });

  const { variantTypes } = useContext(ApplicationContext);
  const [messageApi, contextHolder] = message.useMessage();

  const varOption = (type: any) => {
    let varOpts = variantTypes.filter((f: any) => f?.value.includes(type));
    let opt = varOpts[0]?.varOptions?.map((res: any) => ({
      label: res?.name,
      value: res?.id,
    }));
    return opt;
  };

  const clearSelect = (field: string) => {
    if (field === "productGroup") form.setFieldValue("productGroup", undefined);
  };

  const checkSeating = async (rule: any, value: any) => {
    await ProductService.verifySeating(subCategory?.value, value?.value).then(
      (res: any) => {
        if (res?.result === true) {
          throw new Error("Seat already allotted.");
        }
      }
    );
  };
  useEffect(() => {
    if (subCategory === undefined || subCategory?.value === null) return;
    MasterService.getProductGroupList("", subCategory?.label, false).then(
      (res: any) => {
        if (res !== null || res?.totalRecords > 0) {
          setProductGrpOptions(
            res?.items?.map((itm: any) => ({
              label: itm?.name,
              value: itm?.id,
            }))
          );
        }
      }
    );
  }, [subCategory]);

  useEffect(() => {
    setEditData({
      ...props?.productData,
      expiryDate:
        props?.productData?.expiryDate === "0001-01-01T00:00:00Z" ||
        props?.productData?.expiryDate === "0001-01-01T00:00:00"
          ? undefined
          : dayjs(new Date(props?.productData?.expiryDate)),
      purchaseDate:
        props?.productData?.purchaseDate === "0001-01-01T00:00:00Z" ||
        props?.productData?.purchaseDate === "0001-01-01T00:00:00"
          ? undefined
          : dayjs(new Date(props?.productData?.purchaseDate)),
      // issueDate:
      //   props?.productData?.issueDate === "0001-01-01T00:00:00Z"
      //     ? undefined
      //     : dayjs(new Date(props?.productData?.issueDate)),
      subCategory: {
        value: props?.productData?.subCategory?.id,
        label: props?.productData?.subCategory?.name,
      },
      productGroup: {
        value: props?.productData?.productGroup?.id,
        label: props?.productData?.productGroup?.name,
      },
      vendor: {
        value: props?.productData?.vendor?.id,
        label: props?.productData?.vendor?.name,
      },
      variantType: {
        value: props?.productData?.variantType?.id,
        label: props?.productData?.variantType?.name,
      },
      variantOption: {
        value: props?.productData?.variantOption?.id,
        label: props?.productData?.variantOption?.name,
      },
      seatAllotted: {
        value: props?.productData?.seatAllotted?.id,
        label: props?.productData?.seatAllotted?.name,
      },
      variants: props?.productData?.variants?.map((r: any, i: number) => {
        return {
          ...r,
          type: { label: r?.type?.name, value: r?.type?.id },
          option: { label: r?.option?.name, value: r?.option?.id },
        };
      }),
    });
  }, [props?.productData]);

  useEffect(() => {
    form.setFieldsValue(editData);
    setSubCategory(editData?.subCategory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, enableEdit]);

  const saveUpdatedData = () => {
    setBtnDisable(true);
    let values = form.getFieldsValue(true);
    let val = {
      ...editData,
      ...values,
      expiryDate: values?.expiryDate,
      purchaseDate: values?.purchaseDate,
      // issueDate: values?.issueDate,
      subCategory: {
        id: values?.subCategory?.value,
        name: values?.subCategory?.label,
      },
      productGroup: {
        id: values?.productGroup?.value,
        name: values?.productGroup?.label,
      },
      vendor: {
        id: values?.vendor?.value,
        name: values?.vendor?.label,
      },
      variantType: {
        id: values?.variantType?.value,
        name: values?.variantType?.label,
      },
      variantOption: {
        id: values?.variantOption?.value,
        name: values?.variantOption?.label,
      },
      seatAllotted: {
        id: values?.seatAllotted?.value,
        name: values?.seatAllotted?.label,
      },
      variants: values?.variants?.map(({ option, type, ...rest }: any) => ({
        ...rest,
        option: { id: option?.value, name: option?.label },
        type: { id: type?.value, name: type?.label },
      })),
      id: props?.productData?.id,
    };
    if (deleteRecord !== "")
      ProductService.delete(deleteRecord).then((res) => {
        if (res?.status) {
          setDeleteRecord("");
        }
      });
    ProductService.post(val).then((res: any) => {
      if (res?.status) {
        messageApi.success("Product updated successfully.");
        setEnableEdit(false);
        setBtnDisable(true);
        props?.setIsDataSaved(true);
        props?.setShouldRefresh((x: boolean) => !x);
      } else {
        messageApi.error(res?.message);
        setBtnDisable(false);
      }
    });
  };
  return (
    <>
      {contextHolder}
      <Row justify={"space-between"} align={"bottom"} className="">
        {/* <Col className="font16 fw600"> Details</Col> */}
        <Col className="font16 fw600"></Col>
        {enableEdit ? (
          <Space>
            <Button
              icon={<SaveOutlined />}
              size="small"
              // className="border-none font16"
              onClick={saveUpdatedData}
              disabled={btnDisable}
            />
            <Button
              icon={<CloseOutlined />}
              size="small"
              // className="border-none font16"
              onClick={() => setEnableEdit(false)}
              disabled={btnDisable}
            />
          </Space>
        ) : (
          <Col>
            <Button
              icon={<EditOutlined />}
              size="small"
              // className="border-none font16"
              onClick={() => setEnableEdit(true)}
              disabled={
                (props.isPending === ProductStatusFlag.Pending
                  ? true
                  : false) || !editData?.isEditAllowed
              }
            />
          </Col>
        )}
      </Row>

      <Form
        form={form}
        colon={false}
        // size="small"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 14 }}
        labelAlign="left"
        className="InvDForm"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Field can't be empty." }]}
        >
          {enableEdit ? <Input /> : editData?.name}
        </Form.Item>
        <Form.Item label="Product-Category" name="subCategory">
          {enableEdit ? (
            <Select
              labelInValue
              allowClear
              options={props.subCategoryOptions}
              onChange={(val: any, op: any) => {
                setSubCategory(op);
                clearSelect("productGroup");
              }}
            />
          ) : editData?.subCategory?.label === null ? (
            "-"
          ) : (
            editData?.subCategory?.label
          )}
        </Form.Item>
        <Form.Item label="Product Group" name="productGroup">
          {enableEdit ? (
            <Select
              labelInValue
              showSearch
              popupMatchSelectWidth={true}
              allowClear
              listHeight={224}
              style={{ width: "100%" }}
              options={productGrpOptions}
            />
          ) : editData?.productGroup?.label === null ? (
            "-"
          ) : (
            editData?.productGroup?.label
          )}
        </Form.Item>

        <Form.Item label="Serial No." name="serialNo">
          {enableEdit ? (
            <Input />
          ) : editData?.serialNo !== null ? (
            editData?.serialNo
          ) : (
            "-"
          )}
        </Form.Item>
        <Form.Item label="Invoice Number" name="invoiceNumber">
          {enableEdit ? (
            <Input />
          ) : editData?.invoiceNumber !== null ? (
            editData?.invoiceNumber
          ) : (
            "-"
          )}
        </Form.Item>
        <Form.Item label="Invoice Date" name="purchaseDate">
          {enableEdit ? (
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              onChange={(val: any) => {
                if (val === null) {
                  form.setFieldValue("purchaseDate", undefined);
                }
              }}
            />
          ) : (
            Utils.getFormattedDate(editData?.purchaseDate, "-", false)
          )}
        </Form.Item>
        <Form.Item label="Warranty Date" name="expiryDate">
          {enableEdit ? (
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              onChange={(val: any) => {
                if (val === null) {
                  form.setFieldValue("expiryDate", undefined);
                }
              }}
            />
          ) : (
            Utils.getFormattedDate(editData?.expiryDate, "-", false)
          )}
        </Form.Item>
        <Form.Item
          label="Seat No."
          name="seatAllotted"
          rules={[
            {
              validator: checkSeating,
            },
          ]}
        >
          {enableEdit ? (
            <Select
              labelInValue
              allowClear
              listHeight={160}
              popupMatchSelectWidth={true}
              options={props.seatingOptions}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          ) : editData?.seatAllotted?.label === null ? (
            "No Seat Allotted"
          ) : (
            editData?.seatAllotted?.label
          )}
        </Form.Item>
        <Form.Item label="Assign To">
          <Button
            type="default"
            className="fw500"
            onClick={() => props.setShow("ModifyState")}
            disabled={!editData?.isEditAllowed}
          >
            Assign Product
          </Button>
        </Form.Item>
        <Form.Item label="Description" name="description">
          {enableEdit ? (
            <TextArea
              placeholder="Description"
              autoSize={{ minRows: 2, maxRows: 6 }}
            />
          ) : editData?.description ? (
            editData?.description
          ) : (
            "-"
          )}
        </Form.Item>

        <Form.Item
          label="Work from home"
          name="workFromHome"
          valuePropName="checked"
        >
          {enableEdit ? (
            <Checkbox />
          ) : editData?.workFromHome ? (
            "Allowed"
          ) : (
            "Not Allowed"
          )}
        </Form.Item>
        {editData?.variantType?.value ? (
          <>
            <Form.Item label="Variant" name="variantType">
              {enableEdit ? (
                <Select
                  labelInValue
                  options={variantTypes}
                  onChange={(val, op: any) => {
                    setVariantOptions(
                      op?.varOptions.map((v: any) => ({
                        label: v.name,
                        value: v.id,
                      }))
                    );
                    form.setFieldValue("variantOption", undefined);
                  }}
                  aria-readonly
                />
              ) : (
                editData?.variantType?.label
              )}
            </Form.Item>
            <Form.Item label="Option" name="variantOption">
              {enableEdit ? (
                <Select
                  labelInValue
                  options={
                    variantOptions && variantOptions?.length > 0
                      ? variantOptions
                      : varOption(editData?.variantType?.value)
                  }
                />
              ) : (
                editData?.variantOption?.label
              )}
            </Form.Item>
          </>
        ) : null}

        {editData?.variants && editData?.variants?.length > 0 ? (
          <>
            <Row justify={"space-between"} className="py-3">
              <Col className="font16 fw600 pe-2">Variants</Col>
              <Col>
                {!enableEdit ? (
                  <Button
                    onClick={() => setAddVariant("add")}
                    icon={<PlusOutlined />}
                    disabled={!editData?.isEditAllowed}
                  />
                ) : null}
              </Col>
            </Row>

            {editData?.variants?.map((r: any, index: number) => {
              const op = varOption(r?.type?.value);
              return (
                <Row key={index}>
                  <Col span={23}>
                    <Form.Item
                      name={[`variants`, index, "option"]}
                      label={r?.type?.label}
                      key={index}
                      initialValue={r?.option}
                    >
                      {enableEdit ? (
                        <Select
                          labelInValue
                          options={op}
                          style={{ width: "325px" }}
                        />
                      ) : (
                        r?.option?.label
                      )}
                    </Form.Item>
                  </Col>
                  {enableEdit ? (
                    <Col span={1}>
                      <Popconfirm
                        title="Are you sure to delete this variant?"
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        onConfirm={() => {
                          let newVariants = editData?.variants?.filter(
                            (f: any, idx: number) => {
                              if (index === idx) setDeleteRecord(f?.id);
                              return index !== idx;
                            }
                          );
                          setEditData((p: any) => ({
                            ...p,
                            variants: newVariants,
                          }));
                        }}
                      >
                        <Button icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </Col>
                  ) : null}
                </Row>
              );
            })}
          </>
        ) : null}
      </Form>

      {addVariant === "add" && (
        <AddVariant
          data={editData}
          setVariantOptions={setVariantOptions}
          variantOptions={variantOptions}
          onDismiss={(res: boolean) => {
            if (res) {
              props?.setShouldRefresh((x: boolean) => !x);
            }
            setAddVariant("");
          }}
        />
      )}
    </>
  );
};

const AddVariant = ({
  data,
  onDismiss,
  variantOptions,
  setVariantOptions,
}: any) => {
  const [formVar] = Form.useForm();
  const { variantTypes } = useContext(ApplicationContext);

  const onSave = () => {
    formVar.validateFields().then(async (values) => {
      let postModel = {
        subCategory: {
          id: data?.subCategory?.value,
          name: data?.subCategory?.label,
        },
        vendor: {
          id: data?.vendor?.value,
          name: data?.vendor?.label,
        },
        purchaseDate: data?.purchaseDate,
        purchaseType: data?.purchaseType,
        transactionId: data?.transactionId,
        invoiceNumber: data?.invoiceNumber,
        category: data?.category,
        stockType: StockType.Variant,
        variants: values?.variants?.map(({ option, type, ...rest }: any) => ({
          ...rest,
          option: { id: option?.value, name: option?.label },
          type: { id: type?.value, name: type?.label },
        })),
        referenceId: data?.id,
      };

      await ProductService.post(postModel).then((res) => {
        if (res?.status) {
          onDismiss(true);
        } else {
          message.error(res?.message);
        }
      });
    });
  };
  return (
    <>
      <Drawer
        title="Add"
        width="25vw"
        zIndex={1006}
        className="InvDetailsDrawer"
        open={true}
        closable={false}
        extra={
          <>
            <Button
              icon={<SaveOutlined />}
              size="small"
              onClick={onSave}
              className="border-none me-1"
            />
            <Button
              icon={<CloseOutlined />}
              size="small"
              onClick={onDismiss}
              className="border-none"
            />
          </>
        }
      >
        <Form
          form={formVar}
          colon={false}
          size="small"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 14 }}
          className="InvDForm"
          autoComplete="off"
        >
          <Form.List name={"variants"} initialValue={[{ brand: "" }]}>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restField }, i) => (
                  <div key={i}>
                    <Row justify={"space-between"} className="pb-2">
                      <Col className="fw500 font16">Variant {i + 1}</Col>
                      <Col>
                        <DeleteOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                    {/* <Form.Item
                      {...restField}
                      name={[name, "brand"]}
                      label="Category"
                    >
                      <Input />
                    </Form.Item> */}
                    <Form.Item
                      {...restField}
                      name={[name, "variantName"]}
                      label="Name"
                      //rules={[{ required: true, message: "Enter Name" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "type"]}
                      label="Type"
                      rules={[
                        { required: true, message: "Enter Variant Type." },
                      ]}
                    >
                      <Select
                        labelInValue
                        options={variantTypes}
                        onChange={(val, op: any) => {
                          setVariantOptions(
                            op?.varOptions.map((v: any) => ({
                              label: v.name,
                              value: v.id,
                            }))
                          );
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "option"]}
                      label="Option"
                      rules={[
                        { required: true, message: "Enter Variant Option." },
                      ]}
                    >
                      <Select labelInValue options={variantOptions} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "serialNo"]}
                      label="Serial No."
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "warranty"]}
                      label="Warranty"
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        format={"DD/MM/YYYY"}
                        onChange={(val: any) => {
                          if (val === null) {
                            formVar.setFieldValue("purchaseDate", undefined);
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    className="mt-2"
                    icon={<PlusOutlined />}
                  >
                    Add More Variant
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
};

export default EditDigitalProduct;
