import React from "react";
/* eslint-disable */

import {
  message,
  Input,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
  Spin,
  Popconfirm,
  Divider,
  Tag,
  Card,
  InputNumber,
  DatePicker,
  Select,
  TimePicker,
  Popover,
} from "antd";
const { Search } = Input;
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useRef, useState } from "react";
import {
  ArrowLeftOutlined,
  BackwardOutlined,
  CarOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import CabServices from "../../../Services/CabServices";
import { dayjs } from "../../../../Utilities/dayjs";
import MasterHistory from "../../Masters/MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";
import AddEditTripLog from "./AddEditTripLog";

const TripLogs = () => {
  const inputRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [tableDataOriginal, setTableDataOriginal] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState("close");
  const [recordVal, setRecordVal] = useState<any>({});
  const [exist, setExist] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const [listParams, setListParams] = useState<any>({
    name: "",
    company: "",
    start: 0,
    length: 15,
  });
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [vehicleId, setVehicle] = useState<any>([]);
  const [driverId, setDriver] = useState<any>([]);
  const [passenger, setPassenger] = useState<any>([]);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [show, setShow] = useState<string>("");

  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  // Message on Already exist value
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "TripLogs Is Assigned  Can't be deleted",
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow("");
    setShowDrawer("");
    setLoading(false);
  };

  const search = (e: any) => {
    setListParams({
      name: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };

  //get list of variants
  const getLogStatus = (listParams: any) => {
    setLoading(true);
    CabServices.getTripLogsList(
      listParams.name,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        let logs: any = res?.items;
        res?.items?.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r.id,
            logs: r?.logs,
            totalKm: r?.totalKm,
            tripDate: dayjs(r?.tripDate).format("DD/MM/YYYY"),
            itIn: r?.logs[0]?.list,
            accountsIn: r?.logs[1]?.list,
            firstReading: r?.logs[0]?.odoMeter,
            lastReading:
              r?.logs?.length == 1 ? 0 : r?.logs[r?.logs?.length - 1]?.odoMeter,
          });
        });
        setTableData([...data]);
        setTableDataOriginal([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };
  const editRowData = (editList: any) => {
    CabServices.getTripLogsById(editList?.id).then(async (res: any) => {
      let value = {
        ...res,
        tripDate: dayjs(res?.tripDate),
        logs: res?.logs.map((itm: any) => ({
          ...itm,
          time: dayjs(itm?.time),
          driver: { label: itm?.driver?.name, value: itm?.driver?.id },
          round: { label: itm?.round?.name, value: itm?.round?.id },
          shift: { label: itm?.shift?.name, value: itm?.shift?.id },
          status: { label: itm?.status?.name, value: itm?.status?.id },
          vehicle: { label: itm?.vehicle?.name, value: itm?.vehicle?.id },
          list: itm?.list?.map((val: any) => ({
            ...val,
            // defaultPassengerType: {
            //   label: val?.defaultPassengerType?.name,
            //   value: val?.defaultPassengerType?.id,
            // },
          })),
        })),
      };
      setEditData(value);
      setRecordVal(editList);
      setShow("AddEditTripLogs");
    });
  };

  const deleteList = (listId: string) => {
    // CabService.isExist(Masters.Vehicle, listId).then((res) => {
    //   if (res === true) {
    //     warning();
    //   } else {
    // CabServices.getTripScheduleById(listId).then((res: any) => {
    //   if (res?.isEditAllowed) {
    //     CabServices.deleteTripSchedule(listId).then((res) => {
    //       if (res.status) {
    //         messageApi.success("Item is deleted successfully.");
    //         onDismiss();
    //       }
    //     });
    //   } else {
    //     messageApi.warning("Item added by admin can't be deleted.");
    //   }
    // });
  };
  useEffect(() => {
    getLogStatus(listParams);
    inputRef.current?.focus();
  }, [shouldRefresh, listParams]);

  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === form.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };

  // COLUMNS For Table
  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">VARIANT</span> */}
          <Search
            placeholder="Trip Schedule"
            className="InvSearchInput"
            allowClear
            size="small"
            bordered={false}
            onSearch={(e: any) => search(e)}
          />
        </div>
      ),
      dataIndex: "tripDate",
      // width: 240,

      render: (itm: any, record: any) => (
        <span
          // className="p-0 text-dark fw500"
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
    },
    {
      // width: 250,
      title: <div className="">Running K.M</div>,
      dataIndex: "totalKm",
      ellipsis: true,
      key: "totalKm",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: <div className="">First Reading</div>,
      dataIndex: "firstReading",
      // width: 150,
      ellipsis: true,
      key: "firstReading",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: <div className="">Last Reading</div>,
      dataIndex: "lastReading",
      // width: 150,
      ellipsis: true,
      key: "lastReading",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: <div className="">It Passenger</div>,
      width: 150,
      dataIndex: "itIn",
      ellipsis: true,
      key: "itIn",
      render: (val: any, record: any) =>
        val ? (
          <Row justify={"start"} align={"middle"} className="ps-2  font13 ">
            {val[0]?.passenger?.name}
            <div className="fw500" style={{ marginLeft: "2px" }}>
              {val?.length > 1 ? (
                <Popover
                  placement="rightTop"
                  trigger="click"
                  content={() => (
                    <>
                      <h3 className="my-1">Passenger</h3>
                      <div
                        style={{
                          maxHeight: 300,
                          maxWidth: 200,
                          overflow: "auto",
                        }}
                      >
                        {val?.map((v: any, index: any) => (
                          <div key={index}>
                            <Divider className="m-0" key={index} />
                            <div className="p-1" key={index + 1}>
                              {v?.passenger?.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                >
                  <div className="popoverStyle fw500">
                    {val?.length < 100 ? `+${val?.length - 1}` : `+99`}
                  </div>
                </Popover>
              ) : null}
            </div>
          </Row>
        ) : (
          <div className="ps-4">-</div>
        ),
    },
    {
      title: <div className="">Accounts Passenger</div>,
      dataIndex: "accountsIn",
      ellipsis: true,
      key: "accountsIn",
      render: (val: any, record: any) =>
        val ? (
          <Row justify={"start"} align={"middle"} className="ps-2  font13 ">
            {val[0]?.passenger?.name}
            <div className="fw500" style={{ marginLeft: "2px" }}>
              {val?.length > 1 ? (
                <Popover
                  placement="rightTop"
                  trigger="click"
                  content={() => (
                    <>
                      <h3 className="my-1">Passenger</h3>
                      <div
                        style={{
                          maxHeight: 300,
                          maxWidth: 200,
                          overflow: "auto",
                        }}
                      >
                        {val?.map((v: any, index: any) => (
                          <div key={index}>
                            <Divider className="m-0" key={index} />
                            <div className="p-1" key={index + 1}>
                              {v?.passenger?.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                >
                  <div className="popoverStyle fw500">
                    {val?.length < 100 ? `+${val?.length - 1}` : `+99`}
                  </div>
                </Popover>
              ) : null}
            </div>
          </Row>
        ) : (
          <div className="ps-4">-</div>
        ),
    },
    // {
    //   title: <div className="">Passenger</div>,
    //   width: 150,
    //   dataIndex: "passengerId",
    //   ellipsis: true,
    //   key: "passengerId",
    //   render: (val: any, record: any) =>
    //     val ? (
    //       <Row justify={"start"} align={"middle"} className="ps-2  font13 ">
    //         {val[0]?.name}
    //         <div className="fw500" style={{ marginLeft: "2px" }}>
    //           {val?.length > 1 ? (
    //             <Popover
    //               placement="rightTop"
    //               trigger="click"
    //               content={() => (
    //                 <>
    //                   <h3 className="my-1">Passenger</h3>
    //                   <div
    //                     style={{
    //                       maxHeight: 300,
    //                       maxWidth: 200,
    //                       overflow: "auto",
    //                     }}
    //                   >
    //                     {val?.map((v: any, index: any) => (
    //                       <div key={index}>
    //                         <Divider className="m-0" key={index} />
    //                         <div className="p-1" key={index + 1}>
    //                           {v?.name}
    //                         </div>
    //                       </div>
    //                     ))}
    //                   </div>
    //                 </>
    //               )}
    //             >
    //               <div className="popoverStyle fw500">
    //                 {val?.length < 100 ? `+${val?.length - 1}` : `+99`}
    //               </div>
    //             </Popover>
    //           ) : null}
    //         </div>
    //       </Row>
    //     ) : (
    //       <div className="ps-4">-</div>
    //     ),

    //   // render: (val: any) => <div className=" font13 ">{val}</div>,
    // },
    // {
    //   title: <div className="">Time In </div>,
    //   width: 100,
    //   dataIndex: "timeIn",
    //   ellipsis: true,
    //   key: "timeIn",
    //   render: (val: any) => <div className=" font13 ">{val}</div>,
    // },
    // {
    //   title: <div className="">Time Out</div>,
    //   width: 100,
    //   dataIndex: "timeOut",
    //   ellipsis: true,
    //   key: "timeOut",
    //   render: (val: any) => <div className=" font13 ">{val}</div>,
    // },
    {
      title: "  ",
      align: "right",
      // width: 250,
      key: "operation",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            {/* Edit */}
          </Button>
          {/* <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record.value)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button icon={<DeleteOutlined />} type="text" danger size="small">
              Delete
            </Button>
          </Popconfirm> */}
          {
            <Button
              type="text"
              icon={<HistoryOutlined />}
              size="small"
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}

      {/* <Row style={{ marginBottom: "10px" }} justify="end">
          <Col>
            <Button type="primary" onClick={() => setShow(true)}>
              Add New
            </Button>
          </Col>
        </Row> */}

      {show == "" ? (
        <Card
          title={"Logs"}
          bordered={false}
          extra={
            <>
              <Row justify={"end"} gutter={[18, 16]}>
                <Col>
                  <Button
                    type="primary"
                    ghost
                    size="middle"
                    // className="mb-3"
                    // title="Vendor"
                    style={{ fontWeight: "600" }}
                    icon={<PlusOutlined style={{}} />}
                    onClick={() => setShow("AddEditTripLogs")}
                  >
                    New
                  </Button>
                </Col>
              </Row>
            </>
          }
        >
          <Row>
            <Col lg={24}>
              <Table
                size="small"
                rowClassName={rowClassName}
                // className="InvTable"
                dataSource={tableData}
                columns={columns}
                loading={tableLoading}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: true,
                  total: totalRecords,
                  onChange: (page, pageSize) => {
                    changeListParams("start", (page - 1) * pageSize);
                    changeListParams("length", pageSize);
                    setShouldRefresh((x) => !x);
                  },
                  // pageSizeOptions: pageSizeOption,
                }}
                scroll={{ y: `calc(100vh - 300px)` }}
              />
            </Col>
          </Row>
        </Card>
      ) : (
        <Card>
          <AddEditTripLog
            setShow={setShow}
            data={editData}
            onDismiss={(res: boolean) => {
              if (res) {
                setEditData(undefined);
                setShouldRefresh((x) => !x);
                setShow("");
              } else {
                setEditData(undefined);
                setShow("");
              }
            }}
          />
        </Card>
      )}

      {showDrawer === "ShowHistory" && (
        <MasterHistory
          masterId={selectedRecord?.id}
          entity={EntitiesEnum.TripLogs}
          openDrawer={true}
          onDismiss={() => {
            setShow("");
            setShowDrawer("");
          }}
        />
      )}
    </>
  );
};

export default TripLogs;
