import React from "react";
/* eslint-disable */

import {
  message,
  Input,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
  Spin,
  Popconfirm,
  Divider,
  Tag,
  Card,
  InputNumber,
  DatePicker,
  Select,
  TimePicker,
  Popover,
} from "antd";
const { Search } = Input;
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useRef, useState } from "react";
import {
  CarOutlined,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import CabServices from "../../../Services/CabServices";
import { dayjs } from "../../../../Utilities/dayjs";
import MasterHistory from "../../Masters/MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";

const TripSchedule = () => {
  const inputRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [TripScheduleForm] = Form.useForm();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [tableDataOriginal, setTableDataOriginal] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState("close");
  const [recordVal, setRecordVal] = useState<any>({});
  const [exist, setExist] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const [listParams, setListParams] = useState<any>({
    name: "",
    company: "",
    start: 0,
    length: 15,
  });
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  // const [vehicleId, setVehicle] = useState<any>([]);
  // const [driverId, setDriver] = useState<any>([]);
  // const [passenger, setPassenger] = useState<any>([]);
  const [data, setData] = useState({
    vehicle: [],
    driver: [],
    passengerType: [],
    passenger: [],
  });

  // Update a specific part of the state
  const updateMasterData: any = (key: string, value: any) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const callApi = async () => {
    try {
      const [driverRes, vehicleRes, passengerTypeRes, passengerRes] =
        await Promise.all([
          CabServices.getDriverList("", "", undefined, undefined, false),
          CabServices.getVehicleList("", "", undefined, undefined, false),
          CabServices.getPassengerTypeList("", "", undefined, undefined, false),
          CabServices.getPassengerList("", "", undefined, undefined, false),
        ]);

      updateMasterData(
        "driver",
        driverRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
      updateMasterData(
        "vehicle",
        vehicleRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );

      updateMasterData(
        "passengerType",
        passengerTypeRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
      updateMasterData(
        "passenger",
        passengerRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
    } catch (error) {
      // Handle errors
    }
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [show, setShow] = useState<boolean>(false);

  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  // Message on Already exist value
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "TripSchedule Is Assigned  Can't be deleted",
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setShowDrawer("");
    setFieldChange(false);
    setLoading(false);
    TripScheduleForm.resetFields();
  };

  const search = (e: any) => {
    setListParams({
      name: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };
  const subVariant = (saveTog: boolean) => {
    TripScheduleForm.validateFields().then((values) => {
      let val = {
        ...values,
        vehicleId: {
          id: values?.vehicleId?.value,
          name: values?.vehicleId?.label,
        },
        driverId: {
          id: values?.driverId?.value,
          name: values?.driverId?.label,
        },
        defaultPassengerType: {
          id: values?.defaultPassengerType?.value,
          name: values?.defaultPassengerType?.label,
        },
        passengerId: values?.passengerId?.map((itm: any) => ({
          id: itm?.value,
          name: itm?.label,
        })),
      };

      CabServices.postTripSchedule(val).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            onDismiss();
          } else {
            TripScheduleForm.resetFields();
            setShouldRefresh((x) => !x);
          }
        } else {
          errorMsg(res?.message);
        }
      });
    });
  };
  //get list of variants
  const getTripSchedule1 = (listParams: any) => {
    setLoading(true);
    CabServices.getTripScheduleList(
      listParams.name,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items?.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r.id,
            label: r?.name,
            value: r?.id,
            vehicleId: r?.vehicleId,
            driverId: r?.driverId,
            passengerId: r?.passengerId,
            timeIn: dayjs(r?.timeIn).format("hh:mm A"),
            timeOut: dayjs(r?.timeOut).format("hh:mm A"),
            // startDate:
            //   r?.startDate === "0001-01-01T00:00:00"
            //     ? "-"
            //     : dayjs(r?.startDate).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setTableDataOriginal([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const editRowData = (editList: any) => {
    CabServices.getTripScheduleById(editList?.value).then(async (res: any) => {
      TripScheduleForm.setFieldsValue({
        ...res,
        driverId: {
          label: res?.driverId?.name,
          value: res?.driverId?.id,
        },
        vehicleId: {
          label: res?.vehicleId?.name,
          value: res?.vehicleId?.id,
        },
        defaultPassengerType: {
          label: res?.defaultPassengerType?.name,
          value: res?.defaultPassengerType?.id,
        },
        passengerId: res?.passengerId?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        })),
        timeOut: dayjs(new Date(res?.timeOut)),
        timeIn: dayjs(new Date(res?.timeIn)),
      });
      setEditData(res);
      setShow(true);
      setRecordVal(editList);
      setUpdateToggle("show");
      // await CabService.isExist(Masters.Vehicle, editList.value).then((res) => {
      //   if (res === true) {
      //     setExist(true);
      //   } else {
      //     setExist(false);
      //   }
      // });
    });
  };

  const updateData = () => {
    TripScheduleForm.validateFields().then(async (values) => {
      let val = {
        ...values,
        id: editData?.id,
        vehicleId: {
          id: values?.vehicleId?.value,
          name: values?.vehicleId?.label,
        },
        driverId: {
          id: values?.driverId?.value,
          name: values?.driverId?.label,
        },
        defaultPassengerType: {
          id: values?.defaultPassengerType?.value,
          name: values?.defaultPassengerType?.label,
        },
        passengerId: values?.passengerId.map((itm: any) => ({
          id: itm?.value,
          name: itm?.label,
        })),
      };
      await CabServices.postTripSchedule(val).then((res: any) => {
        if (res?.message) {
          messageApi.error(res?.message);
        } else {
          onDismiss();
          setUpdateToggle("close");
          info("Updated Successfully");
          setSelectedRowKeys("");
        }
      });
    });
  };

  const deleteList = (listId: string) => {
    // CabService.isExist(Masters.Vehicle, listId).then((res) => {
    //   if (res === true) {
    //     warning();
    //   } else {
    CabServices.getTripScheduleById(listId).then((res: any) => {
      if (res?.isEditAllowed) {
        CabServices.deleteTripSchedule(listId).then((res) => {
          if (res.status) {
            messageApi.success("Item is deleted successfully.");
            onDismiss();
          }
        });
      } else {
        messageApi.warning("Item added by admin can't be deleted.");
      }
    });
  };
  useEffect(() => {
    getTripSchedule1(listParams);
    inputRef.current?.focus();
  }, [shouldRefresh, listParams]);
  useEffect(() => {
    callApi();
  }, []);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === TripScheduleForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };

  // COLUMNS For Table
  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">VARIANT</span> */}
          <Search
            placeholder="Trip Schedule"
            className="InvSearchInput"
            allowClear
            size="small"
            bordered={false}
            onSearch={(e: any) => search(e)}
          />
        </div>
      ),
      dataIndex: "label",
      width: "12%",

      render: (itm: any, record: any) => (
        <span
          // className="p-0 text-dark fw500"
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
    },
    {
      width: "10%",
      title: <div className="">Vehicle </div>,
      dataIndex: "vehicleId",
      ellipsis: true,
      key: "vehicleId",
      render: (val: any) => <div className=" font13 ">{val?.name}</div>,
    },
    {
      title: <div className="">Driver</div>,
      dataIndex: "driverId",
      width: "10%",
      ellipsis: true,
      key: "driverId",
      render: (val: any) => <div className=" font13 ">{val?.name}</div>,
    },
    {
      title: <div className="">Passenger</div>,
      width: "14%",
      dataIndex: "passengerId",
      ellipsis: true,
      key: "passengerId",
      render: (val: any, record: any) =>
        val ? (
          <Row justify={"start"} align={"middle"} className="ps-2  font13 ">
            {val[0]?.name}
            <div className="fw500" style={{ marginLeft: "2px" }}>
              {val?.length > 1 ? (
                <Popover
                  placement="rightTop"
                  trigger="click"
                  content={() => (
                    <>
                      <h3 className="my-1">Passenger</h3>
                      <div
                        style={{
                          maxHeight: 300,
                          maxWidth: 200,
                          overflow: "auto",
                        }}
                      >
                        {val?.map((v: any, index: any) => (
                          <div key={index}>
                            <Divider className="m-0" key={index} />
                            <div className="p-1" key={index + 1}>
                              {v?.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                >
                  <div className="popoverStyle fw500">
                    {val?.length < 100 ? `+${val?.length - 1}` : `+99`}
                  </div>
                </Popover>
              ) : null}
            </div>
          </Row>
        ) : (
          <div className="ps-4">-</div>
        ),

      // render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: <div className="">Time In </div>,
      width: "7%",
      dataIndex: "timeIn",
      ellipsis: true,
      key: "timeIn",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: <div className="">Time Out</div>,
      width: "7%",
      dataIndex: "timeOut",
      ellipsis: true,
      key: "timeOut",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: "  ",
      width: "14%",
      key: "operation",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            {/* Edit */}
          </Button>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record.value)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button icon={<DeleteOutlined />} type="text" danger size="small">
              {/* Delete */}
            </Button>
          </Popconfirm>
          {
            <Button
              type="text"
              icon={<HistoryOutlined />}
              size="small"
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Schedule"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow(true)}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {/* <Row style={{ marginBottom: "10px" }} justify="end">
          <Col>
            <Button type="primary" onClick={() => setShow(true)}>
              Add New
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col lg={24}>
            <Table
              size="small"
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                // pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            TripScheduleForm.resetFields();
            setUpdateToggle("close");
            setSelectedRowKeys("");
          }}
          zIndex={1005}
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // title={updateToggle === "close" ? "Add TripSchedule" : "Update TripSchedule"}
          title={
            updateToggle === "close" ? (
              "Add Trip Schedule"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update TripSchedule
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Trip Schedule"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag> */}
              </>
            )
          }
          // onOk={() => {
          //   subVariant();
          // }}
          footer={
            <>
              {updateToggle === "close" ? (
                <div className="ModelFooterStyle">
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      TripScheduleForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subVariant(true);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subVariant(false);
                    }}
                  >
                    Save and New
                  </Button>
                </div>
              ) : (
                <div className="ModelFooterStyle">
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      TripScheduleForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  {/* 
                <Popconfirm
                  title={
                    exist === true
                      ? "Do you wan't to update this assigned TripSchedule"
                      : "Update TripSchedule"
                  }
                  // onConfirm={() =>  }
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  onConfirm={() => updateData()}
                  onCancel={() => {
                    setShow(false);
                    TripScheduleForm.resetFields();
                    setUpdateToggle("close");
                  }}
                > */}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editData?.isEditAllowed}
                  >
                    Update
                  </Button>
                  {/* </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          {/* /^\S[\S\s]*$/ */}
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="variantBasic"
            className="InvAddForm"
            layout="vertical"
            form={TripScheduleForm}
            size="large"
            // wrapperCol={{ span: 18 }}
            // style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={[10, 10]}>
              <Col lg={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  required
                  rules={[
                    // {
                    //   // pattern: /^\b[a-zA-Z][^\s\W]*$/,
                    //   pattern: pattern,
                    //   message:
                    //     "Spaces, digits, and special symbols are not allowed at beginning or end of the input",
                    // },
                    {
                      required: true,
                      message: "Please fill the field",
                    },
                    // {
                    //   whitespace: true,
                    // },
                  ]}
                >
                  <Input
                    placeholder="Enter Trip Schedule Name"
                    ref={inputRef}
                    onBlur={() => {
                      CheckChanges("name");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Vehicle Name" name={"vehicleId"}>
                  <Select
                    labelInValue
                    options={data?.vehicle}
                    placeholder="Select Vehicle Name"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col lg={12}>
                <Form.Item label="Driver Name" name={"driverId"}>
                  <Select
                    labelInValue
                    options={data?.driver}
                    placeholder="Select Driver Name"
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Default Passenger Type"
                  name={"defaultPassengerType"}
                >
                  <Select
                    labelInValue
                    options={data?.passengerType}
                    placeholder="Select Passenger Name"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 10]}>
              <Col lg={12}>
                <Form.Item label="Time In" name={"timeIn"}>
                  <TimePicker
                    format={"hh:mm A"}
                    placeholder="Input In Time"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Time Out" name={"timeOut"}>
                  <TimePicker
                    format={"hh:mm A"}
                    placeholder="Input Out Time"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={24}>
                <Form.Item label="Passenger" name={"passengerId"}>
                  <Select
                    options={data?.passenger}
                    labelInValue
                    mode="multiple"
                    placeholder="Select Passenger"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.TripSchedule}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default TripSchedule;
