import {
  Button,
  Divider,
  Row,
  Col,
  Input,
  Select,
  Form,
  DatePicker,
  Space,
  InputNumber,
  Spin,
  notification,
  message,
  TimePicker,
  InputRef,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CabServices from "../../../Services/CabServices";
import {
  CloseOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import AddPassenger from "./AddPassenger";
import Driver from "../Masters/Driver/Driver";
import LogStatus from "../Masters/LogStatus/LogStatus";
import PassengerType from "../Masters/PassengerType/PassengerType";
import Round from "../Masters/Round/Round";
import Shift from "../Masters/Shift/Shift";
import Vehicle from "../Masters/Vehicle/Vehicle";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import { dayjs } from "../../../../Utilities/dayjs";

interface AddEditTripLogProp {
  setShow: any;
  data: any;
  onDismiss: (res: boolean) => void;
}
const AddInOption = ({ funcType, data, setData, updateData }: any) => {
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [status, setStatus] = useState<any>("success");
  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = async (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "driver") {
        await CabServices.postDriver({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Driver is already exist");
          } else {
            setData(() => ({
              ...data,
              driver: [
                { label: r?.result?.name, value: r?.result?.id },
                ...data?.driver,
              ],
            }));
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "shift") {
        await CabServices.postShift({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Shift is already exist");
          } else {
            setData(() => ({
              ...data,
              shift: [
                { label: r?.result?.name, value: r?.result?.id },
                ...data?.shift,
              ],
            }));
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "vehicle") {
        await CabServices.postVehicle({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Vehicle is already exist");
          } else {
            setData(() => ({
              ...data,
              vehicle: [
                { label: r?.result?.name, value: r?.result?.id },
                ...data?.vehicle,
              ],
            }));
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "round") {
        await CabServices.postRound({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Round is already exist");
          } else {
            setData(() => ({
              ...data,
              round: [
                { label: r?.result?.name, value: r?.result?.id },
                ...data?.round,
              ],
            }));
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "passengerType") {
        await CabServices.postPassengerType({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Passenger Type is already exist");
          } else {
            setData(() => ({
              ...data,
              PassengerType: [
                { label: r?.result?.name, value: r?.result?.id },
                ...data?.PassengerType,
              ],
            }));
            setName("");
            inputRef.current?.focus();
          }
        });
      }
      // else if (funcType === "passenger") {
      //   await CabServices.postPassenger({ name: name }).then((r: any) => {
      //     if (r?.message) {
      //       message.error("Passenger is already exist");
      //     } else {
      //       setData(() => ({
      //         ...data,
      //         passenger: [
      //           { label: r?.result?.name, value: r?.result?.id },
      //           ...data?.passenger,
      //         ],
      //       }));
      //       setName("");
      //       inputRef.current?.focus();
      //     }
      //   });
      // }
      else if (funcType === "status") {
        await CabServices.postLogStatus({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Status is already exist");
          } else {
            setData(() => ({
              ...data,
              status: [
                { label: r?.result?.name, value: r?.result?.id },
                ...data?.status,
              ],
            }));
            setName("");
            inputRef.current?.focus();
          }
        });
      }
    }
  };
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 0px 4px" }}>
        <Input
          placeholder="Enter"
          size="small"
          ref={inputRef}
          value={name}
          onChange={onNameChange}
          status={status}
          onPressEnter={addOption}
        />
        <Button
          type="primary"
          ghost
          icon={<PlusOutlined />}
          size="small"
          onClick={addOption}
        />
      </Space>
    </>
  );
};
const AddEditTripLog = (props: AddEditTripLogProp) => {
  const [form] = useForm();
  const { data, setData } = useContext(ApplicationContext);
  const [messageApi, contextHolder] = message.useMessage();
  const inputRef = useRef<InputRef>(null);
  const [form1] = useForm();
  const [page, setPage] = React.useState<boolean>(false);

  const [passengers, setPassengers] = useState<any>({
    row: {},
    index: -1,
    name: -1,
  });
  const [show, setShow] = useState<string>("");
  // const [data, setData] = useState({
  //   vehicle: [],
  //   driver: [],
  //   trip: [],
  //   shift: [],
  //   round: [],
  //   passengerType: [],
  //   status: [],
  // });
  // Update a specific part of the state
  const updateData = (key: string, value: any) => {
    setData((prevData: any) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const callApi = async () => {
    setPage(true);
    try {
      const [
        driverRes,
        vehicleRes,
        shiftRes,
        roundRes,
        tripScheduleRes,
        passengerTypeRes,
        logStatusRes,
      ] = await Promise.all([
        CabServices.getDriverList("", "", undefined, undefined, false),
        CabServices.getVehicleList("", "", undefined, undefined, false),
        CabServices.getShiftList("", "", undefined, undefined, false),
        CabServices.getRoundList("", "", undefined, undefined, false),
        CabServices.getTripScheduleList("", "", undefined, undefined, false),
        CabServices.getPassengerTypeList("", "", undefined, undefined, false),
        CabServices.getLogStatusList("", "", undefined, undefined, false),
      ]);

      updateData(
        "driver",
        driverRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
      updateData(
        "vehicle",
        vehicleRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
      updateData(
        "shift",
        shiftRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
      updateData(
        "round",
        roundRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
      updateData(
        "passengerType",
        passengerTypeRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );
      updateData(
        "status",
        logStatusRes?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }))
      );

      if (tripScheduleRes !== null && tripScheduleRes.totalRecords > 0) {
        const data = tripScheduleRes?.items.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
        }));
        updateData("trip", data);
      }
    } catch (error) {
      // Handle errors
    }

    setPage(false);
  };

  const showPassenger = (name: number) => {
    setPassengersFcn(name);
    setShow("addPassenger");
  };
  const setPassengersFcn = (name: number) => {
    let getData = form.getFieldValue(["logs", name]);
    let log = form1.getFieldValue("trip");
    setPassengers(() => {
      return {
        ...passengers,
        row: {
          ...getData,
          trip: log,
        },
        index: name,
        name: name,
      };
    });
  };
  const onSaveData = () => {
    form.validateFields().then(async (res: any) => {
      const data = {
        ...res,
        id: props?.data?.id,
        logs: res?.logs.map((itm: any) => ({
          ...itm,
          driver: { name: itm?.driver?.label, id: itm?.driver?.value },
          round: { name: itm?.round?.label, id: itm?.round?.value },
          shift: { name: itm?.shift?.label, id: itm?.shift?.value },
          status: { name: itm?.status?.label, id: itm?.status?.value },
          vehicle: { name: itm?.vehicle?.label, id: itm?.vehicle?.value },
        })),
      };
      await CabServices.postTripLogs(data)
        .then((res: any) => {
          messageApi.success("Submitted Successfully");
          form.resetFields();
          setShow("");
          props.onDismiss(true);
        })
        .catch((err: any) => {
          messageApi.error(err);
        });
    });
  };
  useEffect(() => {
    callApi();
    if (props?.data) {
      form.setFieldsValue(props?.data);
    }
  }, []);
  console.log(data, "DATA");

  // useEffect(() => {
  //   const dependencies = {
  //     driver: "someValue",
  //     vehicle: "someValue",
  //     shift: "someValue",
  //     round: "someValue",
  //     trip: "someValue",
  //     passengerType: "someValue",
  //     passenger: "someValue",
  //     status: "someValue",
  //   };

  //   const fetchData = async () => {
  //     await callApi(updateData, setPage, dependencies);
  //   };

  //   fetchData();
  // }, []);
  // const callApi = async (
  //   updateData,
  //   setPage,
  //   dependencies = {
  //     driver: "",
  //     vehicle: "",
  //     shift: "",
  //     round: "",
  //     trip: "",
  //     passengerType: "",
  //     status: "",
  //   }
  // ) => {
  //   setPage(true);

  //   try {
  //     const [
  //       driverRes,
  //       vehicleRes,
  //       shiftRes,
  //       roundRes,
  //       tripScheduleRes,
  //       passengerTypeRes,
  //       logStatusRes,
  //     ] = await Promise.all([
  //       CabServices.getDriverList("", "", undefined, undefined, false),
  //       CabServices.getVehicleList("", "", undefined, undefined, false),
  //       CabServices.getShiftList("", "", undefined, undefined, false),
  //       CabServices.getRoundList("", "", undefined, undefined, false),
  //       CabServices.getTripScheduleList("", "", undefined, undefined, false),
  //       CabServices.getPassengerTypeList("", "", undefined, undefined, false),
  //       CabServices.getLogStatusList("", "", undefined, undefined, false),
  //     ]);

  //     updateData(
  //       "driver",
  //       driverRes?.items?.map((itm: any) => ({
  //         label: itm?.name,
  //         value: itm?.id,
  //       }))
  //     );
  //     updateData(
  //       "vehicle",
  //       vehicleRes?.items?.map((itm: any) => ({
  //         label: itm?.name,
  //         value: itm?.id,
  //       }))
  //     );
  //     updateData(
  //       "shift",
  //       shiftRes?.items?.map((itm: any) => ({
  //         label: itm?.name,
  //         value: itm?.id,
  //       }))
  //     );
  //     updateData(
  //       "round",
  //       roundRes?.items?.map((itm: any) => ({
  //         label: itm?.name,
  //         value: itm?.id,
  //       }))
  //     );
  //     updateData(
  //       "passengerType",
  //       passengerTypeRes?.items?.map((itm: any) => ({
  //         label: itm?.name,
  //         value: itm?.id,
  //       }))
  //     );
  //     updateData(
  //       "status",
  //       logStatusRes?.items?.map((itm: any) => ({
  //         label: itm?.name,
  //         value: itm?.id,
  //       }))
  //     );

  //     if (tripScheduleRes !== null && tripScheduleRes.totalRecords > 0) {
  //       const data = tripScheduleRes?.items.map((itm: any) => ({
  //         label: itm?.name,
  //         value: itm?.id,
  //       }));
  //       updateData("trip", data);
  //     }
  //   } catch (error) {
  //     // Handle errors
  //   }

  //   setPage(false);
  // };
  return (
    <>
      {contextHolder}
      <Row justify={"end"}>
        <Button
          size="small"
          style={{ margin: "0" }}
          icon={<CloseOutlined />}
          onClick={() => {
            props.onDismiss(false);
            form.resetFields();
          }}
        ></Button>
      </Row>
      <Spin
        spinning={page}
        // delay={500}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          name="variantBasic"
          className="InvAddForm"
          initialValues={{
            tripDate: dayjs(),
            logs: [
              {
                driver: { ...data?.driver[0] },
                vehicle: { ...data?.vehicle[0] },
              },
            ],
          }}
          layout="vertical"
          form={form}
          size="middle"
          // wrapperCol={{ span: 18 }}
          // style={{ maxWidth: 600 }}
          autoComplete="off"
        >
          <Row gutter={[10, 0]}>
            <Col>
              <Form.Item
                label="Trip Date"
                name="tripDate"
                required
                rules={[
                  {
                    required: true,
                    message: "Please fill the field",
                  },
                ]}
              >
                <DatePicker placeholder="Select date" format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[10, 10]}>
            <Col lg={24}>
              <Form.List name="logs">
                {(fields, { add, remove }) => (
                  <>
                    <Row justify={"end"}>
                      <Button
                        type="primary"
                        ghost
                        onClick={() => add()}
                        size="middle"
                        icon={<PlusOutlined />}
                      ></Button>
                    </Row>
                    {fields.map(({ key, name }, index) => (
                      <Row key={index} gutter={[8, 20]}>
                        <Col lg={2}>
                          <Form.Item
                            label={index === 0 ? "Shift" : null}
                            name={[name, "shift"]}
                          >
                            <Select
                              labelInValue
                              options={data?.shift}
                              placeholder="Select Shift"
                              style={{ width: "100%" }}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <AddInOption
                                    funcType={"shift"}
                                    data={data}
                                    setData={setData}
                                  />
                                </>
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            label={index === 0 ? "Round" : null}
                            name={[name, "round"]}
                          >
                            <Select
                              labelInValue
                              options={data?.round}
                              placeholder="Select Round"
                              style={{ width: "100%" }}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <AddInOption
                                    funcType={"round"}
                                    data={data}
                                    setData={setData}
                                  />
                                </>
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={3}>
                          <Form.Item
                            label={index === 0 ? "Vehicle" : null}
                            name={[name, "vehicle"]}
                          >
                            <Select
                              labelInValue
                              options={data?.vehicle}
                              placeholder="Select Vehicle"
                              style={{ width: "100%" }}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <AddInOption
                                    funcType={"vehicle"}
                                    data={data}
                                    setData={setData}
                                  />
                                </>
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={3}>
                          <Form.Item
                            label={index === 0 ? "Driver" : null}
                            name={[name, "driver"]}
                          >
                            <Select
                              style={{ width: "100%" }}
                              labelInValue
                              options={data?.driver}
                              placeholder="Select Driver"
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <AddInOption
                                    funcType={"driver"}
                                    data={data}
                                    setData={setData}
                                  />
                                </>
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            label={index === 0 ? "Status" : null}
                            name={[name, "status"]}
                          >
                            <Select
                              placeholder="Select Status"
                              options={data?.status}
                              labelInValue
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <AddInOption
                                    funcType={"status"}
                                    data={data}
                                    setData={setData}
                                  />
                                </>
                              )}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={2}>
                          <Form.Item
                            label={index === 0 ? "Time" : null}
                            name={[name, "time"]}
                          >
                            <TimePicker
                              placeholder="Time"
                              format="hh:mm:A"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={3}>
                          <Form.Item
                            label={index === 0 ? "Odo Meter" : null}
                            name={[name, "odoMeter"]}
                            // rules={[
                            //   { required: true, message: "Missing first name" },
                            // ]}
                          >
                            <InputNumber
                              placeholder="Enter Odometer Reading"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={4}>
                          <Form.Item
                            label={index === 0 ? "Description" : null}
                            name={[name, "description"]}
                          >
                            <Input
                              placeholder="Enter Description"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={1}>
                          <Space align={index === 0 ? "center" : "baseline"}>
                            <Form.Item
                              label={index === 0 ? " " : null}
                              name={[name, "passenger"]}
                            >
                              <Button
                                size="middle"
                                icon={<UsergroupAddOutlined />}
                                onClick={() => {
                                  showPassenger(name);
                                  setShow("addPassenger");
                                }}
                              >
                                Passenger
                              </Button>
                            </Form.Item>
                            <div>
                              <>
                                <Button
                                  style={{
                                    marginTop: index === 0 ? 6 : 0,
                                  }}
                                  danger
                                  size="middle"
                                  onClick={() => {
                                    remove(name);
                                  }}
                                  icon={<DeleteOutlined />}
                                />
                              </>
                            </div>
                          </Space>
                        </Col>
                      </Row>
                    ))}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          <Divider style={{ width: "1005" }} />
        </Form>
        <Button
          onClick={() => {
            onSaveData();
          }}
        >
          Save
        </Button>
      </Spin>
      {/* {show === "Driver" && <Driver from={"AddEditTripLog"} />}
      {show === "LogStatus" && <LogStatus />}
      {show === "PassengerType" && <PassengerType />}
      {show === "Round" && <Round />}
      {show === "Shift" && <Shift />}
      {show === "Vehicle" && <Vehicle />} */}
      {show === "addPassenger" && (
        <AddPassenger
          row={form?.getFieldValue(["logs", passengers?.name])}
          // index={passengers}
          tripOptions={data?.trip}
          statusOptions={data?.passengerType}
          onDismiss={(values: any) => {
            setShow("");
          }}
          onSave={(values: any) => {
            setShow("");
            form?.setFieldValue(["logs", passengers?.name], {
              ...form?.getFieldValue(["logs", passengers?.name]),
              ...values,
            });
          }}
        />
      )}
    </>
  );
};

export default AddEditTripLog;
