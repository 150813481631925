import React, { useEffect, useState } from "react";
import { Button, Drawer, Spin, Tabs, TabsProps } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import EditUser from "./EditUser";
// import UserHistory from "./UserHistory";
import UserRights from "../../Services/UserRights";
import UserHistory from "./UserHistory";
import MasterService from "../../Services/MasterService";

const UserDetails = ({ userId, openDrawer, setShow, onDismiss }: any) => {
  const [loading, setLoading] = useState(true);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [apiData, setApiData] = useState<any>({});

  useEffect(() => {
    async function getData() {
      await UserRights.getUserDetails(userId).then((res) => {
        if (res) {
          setApiData({
            ...res,
            id: res?.id,
            name: res?.name?.first,
            company: res?.company?.name,
            email: res?.email,
            lastLogin: res?.lastLogin?.time,
            phoneNumber: res?.phoneNumber,
            picture: res?.picture,
            role: res?.role,
            status: res?.status,
            address: res?.address,
            userCategory: res?.userCategory,
          });
          setLoading(false);
        }
      });
    }
    getData();
    // getUserCategory();
  }, [userId, shouldRefresh]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Detail View`,
      children: loading ? (
        <div style={{ height: "100vh", position: "relative" }}>
          <Spin
            style={{ position: "absolute", top: "50%", left: "45%" }}
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
      ) : (
        <EditUser
          setShouldRefresh={setShouldRefresh}
          userData={apiData}
          // catData={catData}
          setIsDataSaved={setIsDataSaved}
          onDismiss={onDismiss}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: `History`,
    //   children: <UserHistory id={userId} shouldRefresh={shouldRefresh} />,
    // },
  ];

  const [activeKey, setActiveKey] = useState(items[0].key);
  const onTabChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  return (
    <>
      <Drawer
        title={apiData?.name}
        width="35vw"
        zIndex={1005}
        onClose={() => onDismiss(isDataSaved)}
        open={openDrawer}
        extra={
          <Button
            icon={<CloseOutlined />}
            size="small"
            onClick={() => onDismiss(isDataSaved)}
            className="border-none"
          />
        }
        className="InvDetailsDrawer"
      >
        <Tabs
          activeKey={activeKey}
          items={items}
          onChange={onTabChange}
          className="InvTab"
        />
      </Drawer>
    </>
  );
};

export default UserDetails;
