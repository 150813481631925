import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class PcConfiguration {
  route = `v1/api/IPcConfiguration`;
  getPcConfiguration = (nameSearch?: string, start?: any, length?: any) =>
    ApiUtility.getResult(`${this.route}/GetPcConfigReport`, {
      nameSearch,
      start,
      length,
    });
}

export default new PcConfiguration();
