import React, { useEffect, useState } from "react";
import { Button, Drawer, Spin, Tabs, TabsProps } from "antd";
import {
  CloseOutlined,
  // CloseSquareTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
// import { ProductStatusFlag } from "../../Context/ApplicationContext/ApplicationState";
import { EntitiesEnum } from "../../Services/LogService";
import ProductService, {
  IProductApiProps,
} from "../../Services/ProductService";
import EditPaidSoftware from "./EditPaidSoftware";
import PaidSoftwareHistory from "./PaidSoftwareHistory";
interface IProductDetailsProps {
  openDrawer?: any;
  // isPending: number;
  selectedRecord: any;
  // category?: Category;
  // subCategoryOptions?: any;
  onDismiss: (rec: boolean) => void;
}
const PaidSoftwareDetails = ({
  // isPending,
  openDrawer,
  selectedRecord,
  onDismiss,
}: IProductDetailsProps) => {
  // const [show, setShow] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [apiData, setApiData] = useState<IProductApiProps>({
    id: "",
    name: "",
    category: 1,
    stockType: 0,
    subCategory: { id: "", name: "" },
    productGroup: { id: "", name: "" },
    itemId: "",
    brand: "",
    vendor: { id: "", name: "" },
    purchaseDate: "",
    invoiceNumber: "",
    purchaseType: 0,
    transactionId: "",
    numberOfStock: 0,
    tax: 0,
    pricePerUnit: 0,
    totalPrice: 0,
    expiryDate: "",
    variantType: { id: "", name: "" },
    variantOption: { id: "", name: "" },
    variants: [],
    seatAllotted: { id: "", name: "" },
    status: 0,
    assignee: [],
    workFromHome: false,
    description: "",
    isEditAllowed: false,
    issueDate: "",
    serialNo: "",
    currency: 0,
    gst: { name: "", id: "" },
    totalAmount: 0,
    gstAmount: 0,
    projectName: "",
    subscription: {
      startDate: "",
      endDate: "",
    },
  });

  useEffect(() => {
    setLoading(true);
    async function getData() {
      await ProductService.get(selectedRecord?.id).then((res) => {
        if (res) {
          setApiData({ ...res });
          setLoading(false);
        }
      });
    }
    getData();
  }, [selectedRecord, shouldRefresh]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Detail View`,
      children: loading ? (
        <div style={{ height: "100vh", position: "relative" }}>
          <Spin
            style={{ position: "absolute", top: "50%", left: "45%" }}
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
      ) : (
        <EditPaidSoftware
          productData={apiData}
          setIsDataSaved={setIsDataSaved}
          setShouldRefresh={setShouldRefresh}
          // subCategoryOptions={subCategoryOptions}
        />
      ),
    },
    {
      key: "2",
      label: `History`,
      children: (
        <PaidSoftwareHistory
          id={selectedRecord?.id}
          entity={EntitiesEnum.Product}
          shouldRefresh={shouldRefresh}
        />
      ),
    },
  ];

  const [activeKey, setActiveKey] = useState(items[0].key);
  const onTabChange = (newActiveKey: string) => setActiveKey(newActiveKey);

  // const tabExtra = (
  //   <>
  //     <Button
  //       type="primary"
  //       disabled={
  //         isPending === ProductStatusFlag.Pending
  //           ? true
  //           : false || !apiData?.isEditAllowed
  //       }
  //       onClick={() => {
  //         setShow("StockAjustment");
  //       }}
  //     >
  //       Stock Adjustment
  //     </Button>
  //   </>
  // );

  return (
    <>
      <Drawer
        width="35vw"
        zIndex={1005}
        open={openDrawer}
        // closable={false}
        title={apiData?.name}
        className="InvDetailsDrawer"
        onClose={() => onDismiss(isDataSaved)}
        extra={
          <Button
            // className="border-none"
            // icon={<CloseSquareTwoTone />}
            icon={<CloseOutlined />}
            onClick={() => onDismiss(isDataSaved)}
            size="small"
          />
        }
      >
        <Tabs
          items={items}
          className="InvTab"
          activeKey={activeKey}
          onChange={onTabChange}
          // tabBarExtraContent={tabExtra}
        />
      </Drawer>
    </>
  );
};

export default PaidSoftwareDetails;
