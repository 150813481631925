import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { ProductStatusFlag } from "../Context/ApplicationContext/ApplicationState";

export enum FilterTypeCategory {
  All,
  Cleaning,
  Stationary,
  Garden,
  MedicalKit,
  PaidSoftware,
  DigitalProducts,
  OfficeEquipments,
}
class DashboarSerives {
  // route = `${process.env.REACT_APP_API_BASE_URL}/v1/api/Inv_Product`
  route = `v1/api`;

  getApprovalList = (
    start: number,
    length: number,
    productStatusFlag: ProductStatusFlag,
    category: FilterTypeCategory,
    productName: string,
    subCategory: string,
    userName: string
  ) =>
    ApiUtility.getResult(`${this.route}/IProducts/Approvals`, {
      start,
      length,
      productStatusFlag,
      category,
      productName,
      subCategory,
      userName,
    });

  postList = (List: any[], flag: ProductStatusFlag) =>
    ApiUtility.postForm(`${this.route}/IProducts/adminAction`, {
      products: List,
      flag: flag,
    });

  getInventoryExpenses = () =>
    ApiUtility.getResult(`${this.route}/IDashboard`, {});
  getProductList = (params: any) =>
    ApiUtility.getResult(`${this.route}/IDashboard/GetProducts`, { ...params });
  geExpiryList = (params: any) =>
    ApiUtility.getResult(`${this.route}/IDashboard/ExpiryList`, { ...params });
}

export default new DashboarSerives();
