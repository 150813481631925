import React, { useEffect, useState } from "react";
import { Button, Drawer, Spin, Tabs, TabsProps } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import ProductService, {
  Category,
  IProductApiProps,
} from "../../Services/ProductService";
import EditDigitalProduct from "./EditDigitalProduct";
import { ProductStatusFlag } from "../../Context/ApplicationContext/ApplicationState";
import MoveDigitalProduct from "./MoveDigitalProduct";
import DigitalModifyState from "./DigitalModifyState";
import { EntitiesEnum } from "../../Services/LogService";
import DigitalProductHistory from "./DigitalProductHistory";
interface IProductDetailsProps {
  category: Category;
  isPending: number;
  selectedRecord: any;
  openDrawer: any;
  onDismiss: (rec: boolean) => void;
  subCategoryOptions: any;
  seatingOptions: any;
}
const DigitalProductDetails = ({
  category,
  isPending,
  openDrawer,
  subCategoryOptions,
  selectedRecord,
  seatingOptions,
  onDismiss,
}: IProductDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [show, setShow] = useState("");
  const [apiData, setApiData] = useState<IProductApiProps>({
    id: "",
    name: "",
    category: 1,
    stockType: 0,
    subCategory: { id: "", name: "" },
    productGroup: { id: "", name: "" },
    itemId: "",
    brand: "",
    vendor: { id: "", name: "" },
    purchaseDate: "",
    invoiceNumber: "",
    purchaseType: 0,
    transactionId: "",
    numberOfStock: 0,
    tax: 0,
    pricePerUnit: 0,
    totalPrice: 0,
    expiryDate: "",
    variantType: { id: "", name: "" },
    variantOption: { id: "", name: "" },
    variants: [],
    seatAllotted: { id: "", name: "" },
    status: 0,
    assignee: [],
    workFromHome: false,
    description: "",
    isEditAllowed: false,
    serialNo: "",
    currency: 0,
  });
  const [activeKey, setActiveKey] = useState("1");

  useEffect(() => {
    setLoading(true);
    async function getData() {
      await ProductService.get(selectedRecord?.id).then((res) => {
        if (res) {
          setApiData({ ...res });
          setLoading(false);
        }
      });
    }
    getData();
  }, [selectedRecord, shouldRefresh]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Detail View`,
      children: loading ? (
        <div style={{ height: "100vh", position: "relative" }}>
          <Spin
            style={{ position: "absolute", top: "50%", left: "45%" }}
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
      ) : (
        <EditDigitalProduct
          setShow={setShow}
          isPending={isPending}
          productData={apiData}
          setIsDataSaved={setIsDataSaved}
          setShouldRefresh={setShouldRefresh}
          subCategoryOptions={subCategoryOptions}
          seatingOptions={seatingOptions}
        />
      ),
    },
    {
      key: "2",
      label: `History`,
      children: (
        <DigitalProductHistory
          id={selectedRecord?.id}
          entity={EntitiesEnum.Product}
          shouldRefresh={shouldRefresh}
        />
      ),
    },
  ];

  const onTabChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };
  const tabExtra = (
    <>
      <Button
        type="primary"
        ghost
        disabled={
          isPending === ProductStatusFlag.Pending
            ? true
            : false || !apiData?.isEditAllowed
        }
        onClick={() => {
          setShow("Move");
        }}
      >
        Move
      </Button>
    </>
  );
  return (
    <>
      <Drawer
        onClose={() => onDismiss(isDataSaved)}
        title={apiData?.name}
        width="35vw"
        zIndex={1005}
        open={openDrawer}
        className="InvDetailsDrawer"
        extra={
          <Button
            icon={<CloseOutlined />}
            size="small"
            onClick={() => onDismiss(isDataSaved)}
            // className="border-none"
          />
        }
      >
        <Tabs
          activeKey={activeKey}
          items={items}
          onChange={onTabChange}
          tabBarExtraContent={tabExtra}
          className="InvTab"
        />

        {show === "Move" && (
          <MoveDigitalProduct
            setIsDataSaved={setIsDataSaved}
            seatingOptions={seatingOptions}
            category={category}
            productData={apiData}
            onDismiss={(rec: boolean) => {
              setShow("");
              if (rec) setShouldRefresh((x: boolean) => !x);
            }}
          />
        )}
        {show === "ModifyState" && (
          <DigitalModifyState
            productData={apiData}
            setIsDataSaved={setIsDataSaved}
            onDismiss={(rec: boolean) => {
              setShow("");
              if (rec) {
                setShouldRefresh((x: boolean) => !x);
                // onDismiss(rec)
              }
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default DigitalProductDetails;
