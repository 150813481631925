/* eslint-disable */
import { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tag,
  TimePicker,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import MastersService from "../../../../CapsitechConstruction/Services/MastersService";
import MasterService from "../../../Services/MasterService";
import { dayjs } from "../../../../Utilities/dayjs";
import MasterHistory from "../../Masters/MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";
// import { pageSizeOption } from "../../CapsitechInventoryComponents/Context/ApplicationContext/ApplicationState";
// import MastersService from "../Services/MastersService";
// import { dayjs } from "../../Utilities/dayjs";
// import { EntitiesEnum } from "../../CapsitechInventoryComponents/Services/LogService";
// import MasterHistory from "../../CapsitechInventoryComponents/Pages/Masters/MasterHistory";
// import MasterService, {
//   Masters,
// } from "../../CapsitechInventoryComponents/Services/MasterService";

const { Search } = Input;
const ChangeFieldsInitialValues = {
  name: false,
};
const FieldNameObj: any = {
  name: "Name",
};

type Props = {};

const UserCategory = (props: Props) => {
  const [userCategoryForm] = Form.useForm();
  const inputRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<string>("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(true);
  const [show, setShow] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [exist, setExist] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [empty, setIsEmpty] = useState<any>("success");
  const [oldValues, setOldValues] = useState<any>({});
  const [editData, setEditData] = useState<any>({});
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const [listParams, setListParams] = useState<any>({
    name: "",
    company: "",
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading === "table",
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getUserCategory = (listParams: any) => {
    setLoading("table");
    MasterService.getUserCategoryList(
      listParams.name,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            ...r,
            key: r?.id,
            id: r?.id,
            name: r?.name,
            value: r?.id,
            recordStatus: r?.recordStatus,
            isEditAllowed: r?.isEditAllowed,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setLoading("");
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setFieldChange(false);
    setLoading("");
    setShowDrawer("");
    userCategoryForm.resetFields();
  };
  // Message on Submit

  useEffect(() => {}, [exist]);

  useEffect(() => {
    // setLoading(true);
    inputRef.current?.focus();

    getUserCategory(listParams);
    // getuserCategory();
  }, [shouldRefresh, listParams]);

  const subUserCategory = async (saveTog: boolean) => {
    await userCategoryForm.validateFields().then(async (values) => {
      setDisableBtn(true);
      if (values) {
        let val: any = {
          ...values,
          inTime: values?.inTime?.format("hh:mm A"),
          outTime: values?.outTime?.format("hh:mm A"),
        };

        await MasterService.postUserCategory(val).then((res: any) => {
          if (res?.status === true) {
            if (saveTog === true) {
              info("submitted");
              // setLoading("");

              setDisableBtn(false);
              // getuserCategory();
              onDismiss();
            } else {
              setDisableBtn(false);
              userCategoryForm.resetFields();
              setShouldRefresh((x) => !x);
            }
          } else {
            setDisableBtn(false);
            setLoading("");
            errorMsg(res?.message);
          }
          // if (res?.status) {
          //   info("Added Successfully");
          //   onDismiss();
          //   userCategoryForm.resetFields();
          // } else {
          //   errorMsg(res?.message);
          // }
        });
      } else {
      }
    });
  };

  const searchUserCategory = (e: any) => {
    setListParams({
      name: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };

  const editRowData = async (item: any) => {
    setLoading("modal");
    setShow(true);
    await MasterService.getUserCategoryById(item?.id).then(async (res: any) => {
      let v = {
        ...res,
        inTime: res?.inTime ? dayjs(res?.inTime, "hh:mm A") : undefined,
        outTime: res?.outTime ? dayjs(res?.outTime, "hh:mm A") : undefined,
      };
      setLoading("");
      setUpdateToggle(false);
      userCategoryForm.setFieldsValue({ ...v });
      setEditData({ ...res });
      // setOldValues({ ...res });
      // await MastersService.isExist(Masters.userCategory, item.id).then(
      //   (res) => {
      //     if (res === true) {
      //       setExist(true);
      //     } else {
      //       setExist(false);
      //     }
      //   }
      // );
    });
  };

  const updateData = () => {
    // setDisableBtn(true);
    let values = userCategoryForm.getFieldsValue(true);
    // let changesInFields1 = userCategoryForm.isFieldsTouched();
    // if (fieldChange) {
    let val = {
      ...values,
      inTime: values?.inTime?.format("hh:mm A"),
      outTime: values?.outTime?.format("hh:mm A"),
    };
    userCategoryForm.validateFields().then(async () => {
      // let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;
      await MasterService.postUserCategory(val).then((res: any) => {
        if (res?.result) {
          setDisableBtn(false);
          onDismiss();
          setUpdateToggle(true);
          info("Updated Successfully");
          setSelectedRowKeys("");
        } else {
          setDisableBtn(false);
          errorMsg(res?.message);
        }
      });
    });
    // } else {
    //   setShow(false);
    // }
  };

  const deleteList = (record: any) => {
    MasterService.getUserCategoryById(record?.id).then((res: any) => {
      if (res?.isEditAllowed) {
        // MasterService.isExist(
        //   Masters.ConstructionUserCategory,
        //   record?.id
        // ).then((res) => {
        if (res === true) {
          messageApi.open({
            type: "error",
            content: "UserCategory is already used in purchase  ",
          });
        } else {
          MasterService.deleteUserCategory(record?.id).then((res: any) => {
            messageApi.success("UserCategory is Deleted Successfully");
            // getuserCategory();
            onDismiss();
          });
        }
        // });
      } else {
        messageApi.open({
          type: "warning",
          content: "userCategory is added by admin Can't be deleted ",
        });
      }
    });
  };
  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "userCategory Is Assigned  Can't be deleted",
    });
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === userCategoryForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          {/* <span className="searchDivStyle ms-2">MEASUREMENT </span> */}

          <Search
            placeholder="User Category"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchUserCategory(e)}
            size="small"
            bordered={false}
          />
        </>
      ),
      dataIndex: "name",
      render: (itm: any, record: any) => (
        <span
          className="font13 ps-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
      width: 240,
    },
    {
      title: "From",
      dataIndex: "inTime",
      // width: "15%",
      render: (text: any) => <span className="font13 ps-2">{text}</span>,
    },
    {
      title: "To",
      dataIndex: "outTime",
      // width: "15%",
      render: (text: any) => <span className="font13 ps-2">{text}</span>,
    },
    {
      title: (
        <>
          <div className="searchDivStyle">Date</div>
        </>
      ),

      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13">{val}</div>,
    },
    {
      title: "  ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="text"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
            size="small"
          >
            {/* Edit */}
          </Button>
          {
            // <Button icon={<DeleteOutlined />} type="text" danger onClick={() => {
            //   deleteList(record.id)
            // }}>
            //   Delete
            // </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteList(record)}
              okText="Yes"
              cancelText="No"
              placement="top"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} type="text" danger size="small">
                {/* Delete */}
              </Button>
            </Popconfirm>
          }
          {
            <Button
              // style={{
              //   backgroundColor: "rgb(255 255 255)",
              //   color: "black",
              //   border: "2px solid black",
              //   borderRadius: "50%",
              // }}
              // color="#108ee9"
              size="small"
              type="text"
              icon={<HistoryOutlined />}
              // className="cursorPointer"
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"User Category"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col lg={24}>
            <Table
              // rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                // pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
              size="small"
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            userCategoryForm.resetFields();
            setUpdateToggle(true);
            setSelectedRowKeys("");
          }}
          zIndex={1005}
          title={
            updateToggle === true ? (
              "Add User Category"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update userCategory
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update user category"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>  */}
              </>
            )
          }
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subUserCategory();
          // }}
          footer={
            <>
              {updateToggle === true ? (
                <div
                  style={{
                    gap: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      userCategoryForm.resetFields();
                      setUpdateToggle(true);
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    // loading={loading === "ButtonSpinner"}
                    disabled={disableBtn}
                    onClick={() => {
                      subUserCategory(true);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    // loading={loading === "ButtonSpinner"}
                    disabled={disableBtn}
                    onClick={() => {
                      subUserCategory(false);
                    }}
                  >
                    Save and New
                  </Button>
                </div>
              ) : (
                <div
                  style={{
                    gap: "10px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      userCategoryForm.resetFields();
                      setUpdateToggle(true);
                      setSelectedRowKeys("");
                      setLoading("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editData?.isEditAllowed || disableBtn}
                  >
                    Update
                  </Button>
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />

          <Spin
            spinning={loading == "modal"}
            indicator={<LoadingOutlined />}
            style={{ height: "100%" }}
          >
            <Form
              form={userCategoryForm}
              size="large"
              layout="vertical"
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              autoComplete="off"
              className="InvAddForm"
            >
              <Form.Item
                label="Enter User Category"
                name="name"
                rules={[
                  // {
                  //   pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                  //   message:
                  //     "Spaces, digits, and special symbols are not allowed at the beginning or end of the value.",
                  // },
                  // {
                  //   required: true,
                  //   message: "Fill the field",
                  // },

                  {
                    required: true,
                    message: "Please enter userCategory",
                  },
                  {
                    whitespace: true,
                  },
                ]}
              >
                <Input ref={inputRef} onBlur={() => CheckChanges("name")} />
              </Form.Item>
              <Form.Item
                label="In Time"
                name="inTime"
                rules={[{ required: true, message: "Please select time!" }]}
              >
                <TimePicker format={"hh:mm A"} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label="Out Time"
                name="outTime"
                rules={[{ required: true, message: "Please select time!" }]}
              >
                <TimePicker format={"hh:mm A"} style={{ width: "100%" }} />
              </Form.Item>
            </Form>
          </Spin>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>

        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.UserCategory}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default UserCategory;
