import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import {
  Divider,
  Row,
  Col,
  Select,
  Button,
  Form,
  message,
  Input,
  Table,
  Drawer,
  Spin,
} from "antd";
import ProductService, { Category } from "../../Services/ProductService";
import {
  CaretRightOutlined,
  CloseOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

const { Search } = Input;

interface IMoveProductProps {
  category: Category;
  seatingOptions: any;
  onDismiss: (rec: boolean) => void;
}
interface DataType {
  key: string;
  id: string;
  name: string;
  seatAllotted: number;
  subCategory: string;
}
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div
        className="searchDivStyle"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>{title}</div>
        <Search
          placeholder="Search"
          className="InvSearchInput"
          allowClear
          onChange={(e) => changeListParams(inputName, e.target?.value?.trim())}
          onSearch={(val) => {
            setListParams({ ...listParams, start: 0 });
            setShouldRefresh((x: boolean) => !x);
          }}
          size="small"
          bordered={false}
          style={{
            marginLeft: 0,
            padding: "4px 0px",
            borderBottom: "1px solid #d3d3d3",
          }}
        />
      </div>
    </>
  );
};
const MoveManyProducts = (props: IMoveProductProps) => {
  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [seating, setSeating] = useState({ id: "", name: "" });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [targetRowKeys, setTargetRowKeys] = useState<any[]>([]);
  const [targetRowData, setTargetRowData] = useState([]);
  const [data, setData] = useState<any>([]);
  const [listParams, setListParams] = useState({
    nameSearch: "",
    subCategorySearch: "",
    seatName: "",
    start: 0,
    length: 15,
  });

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };

  useEffect(() => {
    setLoading(true);
    ProductService.getList(
      props.category,
      listParams.nameSearch,
      listParams.subCategorySearch,
      "",
      "",
      listParams.seatName,
      "",
      "",
      listParams.start,
      listParams.length,
      "",
      "",
      false
    ).then((res: any) => {
      if (res !== null || res?.totalRecords !== 0) {
        // res?.items?.map;
        setData(
          res?.items?.map((data: any, i: number) => ({
            key: data?.id,
            id: data?.id,
            name: data?.name,
            category: data?.category,
            subCategory: data?.subCategory,
            seatAllotted: data?.seatAllotted,
            assignee: data?.assignee,
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  }, [props.category, listParams, shouldRefresh]);

  const onSave = () => {
    form.validateFields().then((val) => {
      setBtnDisabled(true);
      ProductService.moveMultipleProduct(
        targetRowKeys as string[],
        seating
      ).then((res) => {
        if (res?.status) {
          messageApi.success("Product moved successfully.");
          form.resetFields();
          setBtnDisabled(false);
          props?.onDismiss(true);
        } else {
          messageApi.error(res?.message);
          setBtnDisabled(false);
        }
      });
    });
  };

  const sourceColumns: ColumnsType<DataType> = [
    {
      title: (
        <THeader
          title={"SEAT NO."}
          inputName={"seatName"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
        />
      ),
      dataIndex: "seatAllotted",
      key: "seatAllotted",
      width: 50,
      render: (seatAllotted: any, record: any) =>
        seatAllotted === null || seatAllotted?.id === null
          ? `No Seat`
          : seatAllotted?.name,
    },
    {
      title: (
        <THeader
          title={"NAME"}
          inputName={"nameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
        />
      ),
      dataIndex: "name",
      key: "name",
      width: 100,
    },
    {
      title: (
        <THeader
          title={"SUB-CATEGORY"}
          inputName={"subCategorySearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subCategory",
      key: "subCategory",
      width: 50,
      render: (val) => (val === null || val?.id === null ? "-" : val?.name),
    },
  ];

  const targetColumns: ColumnsType<DataType> = [
    {
      title: (
        <THeader
          title={"NAME"}
          inputName={"nameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
        />
      ),
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      dataIndex: "delete",
      width: 60,
      align: "center",
      render: (_, record) => (
        <Button
          size="small"
          type="text"
          danger
          icon={<DeleteOutlined />}
          onClick={() => {
            setTargetRowKeys(
              targetRowKeys.filter((itm: any) => itm !== record?.key)
            );
            setSelectedRowKeys(
              selectedRowKeys.filter((itm: any) => itm !== record?.key)
            );
          }}
        />
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: (newselectedRowKeys: React.Key[], rowData: any) => {
      setSelectedRowKeys(newselectedRowKeys);
      setSelectedRowData(rowData);
      if (newselectedRowKeys?.length < selectedRowKeys?.length) {
        setTargetRowData(rowData);
        setTargetRowKeys(newselectedRowKeys);
      }
    },
  };
  const hasSelected = selectedRowKeys.length > 0;

  const targetData = targetRowData.filter((itm: any) =>
    targetRowKeys.includes(itm?.id)
  );

  return (
    <>
      {contextHolder}
      <Drawer
        open={true}
        width="65vw"
        zIndex={1005}
        title="Move Products"
        className="InvDetailsDrawer MoveManyDrawer"
        onClose={() => {
          form.resetFields();
          props.onDismiss(false);
        }}
        extra={
          <Button
            icon={<CloseOutlined />}
            size="small"
            onClick={() => props?.onDismiss(false)}
            // className="border-none"
          />
        }
        footer={
          <>
            <Button
              className="mx-2"
              onClick={() => {
                props.onDismiss(false);
              }}
            >
              Cancel
            </Button>
            <Button type="primary" disabled={btnDisabled} onClick={onSave}>
              Save
            </Button>
          </>
        }
      >
        <Divider className="m-0" />
        <Spin
          spinning={loading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Form form={form} layout="horizontal" colon={false}>
            <Row gutter={[45, 0]} className="pt-2" align={"middle"}>
              <Col>
                <Form.Item
                  label="Destination Seat"
                  name="seatAllotted"
                  rules={[{ required: true, message: "Please select seat." }]}
                  labelCol={{ span: 10 }}
                  labelAlign="left"
                >
                  <Select
                    listHeight={100}
                    labelInValue
                    style={{ width: 200 }}
                    placeholder="Select Destination Seat"
                    allowClear
                    options={props.seatingOptions}
                    showSearch
                    onChange={(val) =>
                      setSeating({ id: val?.value, name: val?.label })
                    }
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row justify={"space-between"}>
            <Col span={11}>
              <Table
                size="small"
                style={{ border: "1px solid #f0f0f0" }}
                columns={sourceColumns}
                dataSource={data}
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                className="InvTable"
                caption={
                  <>
                    <Row
                      className="m-2"
                      justify={"space-between"}
                      align={"middle"}
                    >
                      <Col>{`Selected ${selectedRowKeys.length} products`}</Col>
                      <Col>
                        <Button
                          type="primary"
                          disabled={!hasSelected}
                          onClick={() => {
                            setSelectedRowKeys([]);
                            setTargetRowKeys([]);
                          }}
                        >
                          Deselect all
                        </Button>
                      </Col>
                    </Row>
                    <Divider className="m-0" />
                  </>
                }
                pagination={{
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: false,
                  total: totalRecords,
                  position: ["bottomRight"],
                  onChange: (page, pageSize) => {
                    changeListParams("start", (page - 1) * pageSize);
                    // changeListParams("length", pageSize);
                    setShouldRefresh((x) => !x);
                  },
                }}
              />
            </Col>
            <Col span={1} className="my-auto">
              <Button
                icon={<CaretRightOutlined />}
                disabled={!hasSelected}
                onClick={() => {
                  setTargetRowKeys([...selectedRowKeys]);
                  setTargetRowData(selectedRowData);
                }}
              />
            </Col>
            <Col span={11} style={{ border: "1px solid #f0f0f0" }}>
              <Table
                size="small"
                columns={targetColumns}
                dataSource={targetData}
                pagination={false}
                caption={
                  <>
                    <Row className="m-2 pb-2" justify={"space-between"}>
                      <Col>{`Move ${targetRowKeys.length} product`}</Col>
                    </Row>
                    <Divider className="m-0" />
                  </>
                }
                className="InvTable"
              />
            </Col>
          </Row>
        </Spin>
      </Drawer>
    </>
  );
};

export default MoveManyProducts;
