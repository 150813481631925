/* eslint-disable */
import { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import LogService, { EntitiesEnum } from "../../../Services/LogService";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import MasterHistory from "../MasterHistory";
import "../../../Common/Common.css";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";

const { Search } = Input;
const ChangeFieldsInitialValues = {
  name: false,
};
const FieldNameObj: any = {
  name: "Name",
};
const Unit = (props: any) => {
  const inputRef = useRef<any>(null);
  const { getUnits } = useContext(ApplicationContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [unitForm] = Form.useForm();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(true);
  const [show, setShow] = useState<boolean>(false);
  const [exist, setExist] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [empty, setIsEmpty] = useState<any>("success");
  const [oldValues, setOldValues] = useState<any>({});
  const [editData, setEditData] = useState<any>({});
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const [listParams, setListParams] = useState<any>({
    unitSearch: "",
    company: "",
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getUnits1 = (listParams: any) => {
    setLoading(true);
    MasterService.getUnitOptions(
      listParams.unitSearch,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r?.id,
            name: r?.name,
            value: r?.id,
            recordStatus: r?.recordStatus,
            isEditAllowed: r?.isEditAllowed,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setFieldChange(false);
    setLoading(false);
    setShowDrawer("");
    unitForm.resetFields();
  };
  // Message on Submit

  useEffect(() => {}, [exist]);

  useEffect(() => {
    setLoading(true);
    inputRef.current?.focus();

    getUnits1(listParams);
    // getUnits();
  }, [shouldRefresh, listParams]);

  const subUnit = (saveTog: boolean) => {
    unitForm.validateFields().then((values) => {
      MasterService.postUnit(values).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            getUnits();
            onDismiss();
          } else {
            unitForm.resetFields();
            setShouldRefresh((x) => !x);
          }
        } else {
          errorMsg(res?.message);
        }
        // if (res?.status) {
        //   info("Added Successfully");
        //   onDismiss();
        //   unitForm.resetFields();
        // } else {
        //   errorMsg(res?.message);
        // }
      });
    });
  };

  const searchUnit = (e: any) => {
    setListParams({
      unitSearch: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };

  const editRowData = async (item: any) => {
    MasterService.getUnit(item?.id).then(async (res: any) => {
      unitForm.setFieldsValue(res);
      setShow(true);
      setUpdateToggle(false);
      // unitForm.setFieldsValue({ ...item });
      setEditData({ ...res });
      setOldValues({ ...res });
      await MasterService.isExist(Masters.unit, item.id).then((res) => {
        if (res === true) {
          setExist(true);
        } else {
          setExist(false);
        }
      });
    });
  };

  const updateData = () => {
    let values = unitForm.getFieldsValue(true);
    if (fieldChange) {
      unitForm.validateFields().then(async () => {
        let val = {
          id: values?.id,
          name: values?.name.toString(),
        };
        await MasterService.postUnit(val).then((res: any) => {
          if (res?.result) {
            onDismiss();
            setUpdateToggle(true);
            info("Updated Successfully");
            getUnits();
            setSelectedRowKeys("");
          } else {
            errorMsg(res?.message);
          }
        });
      });
    } else {
      setShow(false);
    }
    // let a = unitForm.isFieldValidating("name");
    // console.log(a);

    // MasterService.postUnit(val).then(() => {
    //   onDismiss();
    //   setUpdateToggle(true);
    //   info("Updated Successfully");
    // });
  };

  const deleteList = (record: any) => {
    MasterService.getExistUnit(Masters.unit, record?.id).then((res) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getUnit(record?.id).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteUnit(record?.id).then((res) => {
              messageApi.success("Unit is Deleted Successfully");
              getUnits();
              onDismiss();
            });
          } else {
            messageApi.open({
              type: "warning",
              content: "Unit is added by admin Can't be deleted ",
            });
          }
        });
      }
    });
  };
  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Unit Is Assigned  Can't be deleted",
    });
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === unitForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          {/* <span className="searchDivStyle ms-2">UNIT </span> */}

          <Search
            placeholder="Unit"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchUnit(e)}
            size="small"
            bordered={false}
          />
        </>
      ),
      dataIndex: "name",
      render: (itm: any, record: any) => (
        <span
          // className="p-0 text-dark fw500"
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
      width: 240,
    },
    {
      title: (
        <>
          <div className="searchDivStyle">Date</div>
        </>
      ),

      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: "  ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            {/* Edit */}
          </Button>
          {
            // <Button icon={<DeleteOutlined />} type="text" danger onClick={() => {
            //   deleteList(record.id)
            // }}>
            //   Delete
            // </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteList(record)}
              okText="Yes"
              cancelText="No"
              placement="top"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button
                icon={<DeleteOutlined />}
                type="text"
                danger
                className="invSelectVendor"
                size="small"
              >
                {/* Delete */}
              </Button>
            </Popconfirm>
          }
          {
            <Button
              // style={{
              //   backgroundColor: "rgb(255 255 255)",
              //   color: "black",
              //   border: "2px solid black",
              //   borderRadius: "50%",
              // }}
              // color="#108ee9"
              size="small"
              type="text"
              icon={<HistoryOutlined />}
              // className="cursorPointer"
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Unit"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            unitForm.resetFields();
            setUpdateToggle(true);
            setSelectedRowKeys("");
          }}
          zIndex={1005}
          title={
            updateToggle === true ? (
              "Add Unit"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Unit
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Unit"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>  */}
              </>
            )
          }
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subUnit();
          // }}
          footer={
            <>
              {updateToggle === true ? (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        unitForm.resetFields();
                        setUpdateToggle(true);
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        subUnit(true);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        subUnit(false);
                      }}
                    >
                      Save and New
                    </Button>
                  </Space>
                </div>
              ) : (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        unitForm.resetFields();
                        setUpdateToggle(true);
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => updateData()}
                      disabled={!editData?.isEditAllowed}
                    >
                      Update
                    </Button>
                  </Space>
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="unitBasic"
            form={unitForm}
            size="large"
            layout="vertical"
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="InvAddForm"
          >
            <Form.Item
              label="Enter Unit"
              name="name"
              rules={[
                // {
                //   pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                //   message:
                //     "Spaces, digits, and special symbols are not allowed at the beginning or end of the value.",
                // },
                // {
                //   required: true,
                //   message: "Fill the field",
                // },
                {
                  pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                  message:
                    "Please enter a valid char value only for the Unit field",
                },

                {
                  required: true,
                  message: "Please enter unit",
                },
                {
                  whitespace: true,
                },
              ]}
            >
              <Input ref={inputRef} onBlur={() => CheckChanges("name")} />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>

        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Unit}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Unit;
