/* eslint-disable */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import { EntitiesEnum } from "../../../Services/LogService";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import MasterHistory from "../MasterHistory";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";

const { Search } = Input;
const Designation = (props: any) => {
  const { getDesignation } = useContext(ApplicationContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [designationForm] = Form.useForm();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(true);
  const [show, setShow] = useState<boolean>(false);
  const [exist, setExist] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const inputRef = useRef<any>(null);
  const [empty, setIsEmpty] = useState<any>("success");
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [fieldChange, setFieldChange] = useState<boolean>(false);

  const [listParams, setListParams] = useState<any>({
    designationSearch: "",
    company: "",
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getDesignation1 = (listParams: any) => {
    setLoading(true);
    MasterService.getDesignationOptions(
      listParams.designationSearch,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r?.id,
            name: r?.name,
            value: r?.id,
            recordStatus: r?.recordStatus,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setLoading(false);
    setShowDrawer("");
    setFieldChange(false);

    designationForm.resetFields();
  };
  // Message on Submit

  useEffect(() => {
    inputRef.current?.focus();
  }, [exist, shouldRefresh]);

  useEffect(() => {
    setLoading(true);
    getDesignation1(listParams);
    // getUnits();
  }, [shouldRefresh, listParams]);

  const saveDesignation = (saveTog: boolean) => {
    designationForm.validateFields().then((values) => {
      MasterService.postDesignation(values).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            onDismiss();
          } else {
            designationForm.resetFields();
            setShouldRefresh(!shouldRefresh);
          }
        } else {
          errorMsg(res?.message);
        }
        // if (res?.status) {
        //   info("Added Successfully");
        //   onDismiss();
        //   designationForm.resetFields();
        // } else {
        //   errorMsg(res?.message);
        // }
      });
    });
  };

  const searchDesignation = (e: any) => {
    setListParams({
      designationSearch: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };

  const editRowData = async (item: any) => {
    MasterService.getDesignation(item?.id).then(async (res: any) => {
      setShow(true);
      setUpdateToggle(false);
      setEditData(res);
      // departmentForm.setFieldsValue({ ...item });
      await MasterService.isExist(Masters.Designation, item.id).then((res) => {
        if (res === true) {
          setExist(true);
        } else {
          setExist(false);
        }
      });
      designationForm.setFieldsValue(res);
    });

    // // console.log(item?.id);
    // MasterService.getDesignation(item?.id).then((res: any) => {
    //   designationForm.setFieldsValue(res);
    // });
    // setShow(true);
    // setUpdateToggle(false);
    // // designationForm.setFieldsValue({ ...item });
    // await MasterService.isExist(Masters.Designation, item.id).then((res) => {
    //   if (res === true) {
    //     setExist(true);
    //   } else {
    //     setExist(false);
    //   }
    // });
  };

  const updateData = async () => {
    let values = designationForm.getFieldsValue(true);
    if (fieldChange) {
      designationForm.validateFields().then(async () => {
        let val = {
          id: values?.id,
          name: values?.name.toString(),
        };

        await MasterService.postDesignation(val).then((res: any) => {
          if (res?.message) {
            messageApi.error(res?.message);
          } else {
            setSelectedRowKeys("");
            onDismiss();
            setUpdateToggle(true);
            info("Updated Successfully");
            // getDesignation();
          }

          // getDesignation1(); call Application state 's  getDesignation()
        });
      });
    } else {
      setShow(false);
    }

    // let a = designationForm.isFieldValidating("name");
    // console.log(a);

    // MasterService.postUnit(val).then(() => {
    //   onDismiss();
    //   setUpdateToggle(true);
    //   info("Updated Successfully");
    // });
  };

  const deleteList = (listId: any) => {
    MasterService.getExistDesignation(Masters.Designation, listId).then(
      (res) => {
        if (res === true) {
          warning();
        } else {
          MasterService.getDesignation(listId).then((res: any) => {
            if (res?.isEditAllowed) {
              MasterService.deleteDesignation(listId).then((res: any) => {
                // errorMsg("Designation is Deleted Successfully");
                messageApi.success("Designation is deleted successfully.");
                onDismiss();
              });
            } else {
              messageApi.warning(
                "Designation is added by admin  can't be deleted"
              );
            }
          });
        }
      }
    );
    //   MasterService.getExistDesignation(Masters.Designation, listId).then(
    //     (res) => {
    //       if (res === true) {
    //         warning();
    //       } else {
    //         MasterService.deleteDesignation(listId).then((res) => {
    //           errorMsg("Designation is Deleted Successfully");
    //           onDismiss();
    //         });
    //       }
    //     }
    //   );
  };

  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Designation Is Assigned  Can't be deleted",
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          {/* <span className="searchDivStyle ms-2">DESIGNATION </span> */}

          <Search
            placeholder="Designation"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchDesignation(e)}
            size="small"
            bordered={false}
          />
        </>
      ),
      dataIndex: "name",
      render: (itm: any, record: any) => (
        <span
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
      width: 240,
    },
    {
      title: (
        <>
          <div className="">Date</div>
        </>
      ),

      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: "  ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            {/* Edit */}
          </Button>
          {
            // <Button icon={<DeleteOutlined />} type="text" danger onClick={() => {
            //   deleteList(record.id)
            // }}>
            //   Delete
            // </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteList(record.id)}
              okText="Yes"
              cancelText="No"
              placement="top"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} type="text" danger size="small">
                {/* Delete */}
              </Button>
            </Popconfirm>
          }
          {
            <Button
              type="text"
              size="small"
              icon={<HistoryOutlined />}
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === designationForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };
  return (
    <>
      <Card
        title={"Designation"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {contextHolder}

        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setSelectedRowKeys("");
            setShow(false);
            designationForm.resetFields();
            setUpdateToggle(true);
          }}
          zIndex={1005}
          title={
            updateToggle === true ? (
              "Add Designation"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Designation
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Designation"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag> */}
              </>
            )
          }
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subUnit();
          // }}
          footer={
            <>
              {updateToggle === true ? (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        designationForm.resetFields();
                        setUpdateToggle(true);
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        saveDesignation(true);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        saveDesignation(false);
                      }}
                    >
                      Save and New
                    </Button>
                  </Space>
                </div>
              ) : (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        designationForm.resetFields();
                        setUpdateToggle(true);
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>
                    {/* {exist === true ? (
                  <Popconfirm
                    title={
                      exist === true
                        ? "Do you wan't to update this assigned Unit"
                        : "Update Unit"
                    }
                    // onConfirm={() =>  }
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                    onConfirm={() => updateData()}
                    onCancel={() => {
                      setShow(false);
                      designationForm.resetFields();
                      setUpdateToggle(true);
                    }}
                  >
                    <Button type="primary" ghost>
                      Update
                    </Button>
                  </Popconfirm>
                ) : ( */}
                    <Button
                      type="primary"
                      ghost
                      onClick={() => updateData()}
                      disabled={!editData?.isEditAllowed}
                    >
                      Update
                    </Button>
                  </Space>
                  {/* )} */}
                  {/* <Popconfirm
                  title={
                    exist === true
                      ? "Do you wan't to update this assigned Unit"
                      : "Update Unit"
                  }
                  // onConfirm={() =>  }
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  onConfirm={() => updateData()}
                  onCancel={() => {
                    setShow(false);
                    designationForm.resetFields();
                    setUpdateToggle(true);
                  }}
                >
                  <Button type="primary" ghost>
                    Update
                  </Button>
                </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="designationBasic"
            form={designationForm}
            size="large"
            layout="vertical"
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="InvAddForm"
          >
            <Form.Item
              label="Enter Designation"
              name="name"
              rules={[
                // {
                //   pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                //   message:
                //     "Spaces, digits, and special symbols are not allowed at the beginning or end of the value.",
                // },
                // {
                //   required: true,
                //   message: "Fill the field",
                // },
                // {
                //   pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                //   message: "Spaces, digits, and special symbols are not allowed ",
                // },

                {
                  required: true,
                  message: "Please enter designation",
                },
                {
                  whitespace: true,
                },
              ]}
            >
              <Input ref={inputRef} onBlur={() => CheckChanges("name")} />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Designation}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Designation;
