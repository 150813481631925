/* eslint-disable no-lone-blocks */
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Table,
  Input,
  Spin,
  message,
  Drawer,
  Form,
  Row,
  Col,
  Checkbox,
  Divider,
  Card,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import "../../Common/Common.css";
import UserRights from "../../Services/UserRights";
import { dayjs } from "../../../Utilities/dayjs";
import { pageSizeOption } from "../../Context/ApplicationContext/ApplicationState";

const { Search } = Input;
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="">
        {/* <div>{title}</div> */}

        <Search
          placeholder={title}
          className="InvSearchInput"
          allowClear
          onChange={(e) => {
            changeListParams(inputName, e.target?.value);
          }}
          onSearch={(val) => {
            setListParams({ ...listParams, start: 0 });
            setShouldRefresh((x: boolean) => !x);
          }}
          size="small"
          bordered={false}
        />
      </div>
    </>
  );
};

const UserAccessRights = () => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [data, setData] = useState<any>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [messageApi, contextHolder] = message.useMessage();
  const [show, setShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userAccessForm] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState<any>();

  const [listParams, setListParams] = useState({
    roleSearch: "",
    setLength: true,
    start: 0,
    length: 15,
  });
  const [messageApi, contextHolder] = message.useMessage();

  const inputRef = useRef<any>(null);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onDismiss = () => {
    setOpenDrawer(false);
    // setShouldRefresh((x) => !x);
    userAccessForm.resetFields();
  };

  // Message on Submit
  const info = (inf: any) => {
    messageApi.success(inf);
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Roles"}
          inputName={"roleSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "role",
      key: "role",
      width: 220,
      render: (role, record) => (
        <Button
          type="link"
          size="small"
          onClick={() => {
            GetRoleDetails(role, record);
            setSelectedRecord(record);
            setShow(false);
            setOpenDrawer(true);
          }}
          className="ps-1 text-dark "
        >
          {role}
        </Button>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (val: any) => <div className="ps-1 font13 ">{val}</div>,
    },
    // {
    //   title: "  ",
    //   align: "right",
    //   key: "operation",
    //   render: (_, record) => (
    //     <>
    //       {
    //         <Popconfirm
    //           title="are you sure ?"
    //           // onConfirm={() => deleteList(record.id)}
    //           okText="Yes"
    //           cancelText="No"
    //           placement="top"
    //           icon={<DeleteOutlined style={{ color: "red" }} />}
    //         >
    //           <Button icon={<DeleteOutlined />} type="text" danger>
    //             Delete
    //           </Button>
    //         </Popconfirm>
    //       }
    //     </>
    //   ),
    // },
  ];
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const getUserRole = (listParams: any) => {
    setLoading(true);
    UserRights.getUserRole(
      listParams.roleSearch,
      listParams.setLength,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res?.result !== null || res?.result.totalRecords !== 0) {
        setData(
          res?.items?.map((data: any, i: number) => ({
            key: i,
            id: data?.id,
            role: data?.role,
            createdBy: dayjs(data?.createdBy.date).format("DD/MM/YYYY"),
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      } else {
        setLoading(false);
      }
    });
  };

  const GetRoleDetails = (role: string, record: any) => {
    UserRights.getRights(role).then((res: any) => {
      userAccessForm.setFieldsValue(res);
      setSelectedRole(res);
    });
  };

  useEffect(() => {
    getUserRole(listParams);
    inputRef.current?.focus();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, shouldRefresh]);

  const getStatus = (target: any) => {
    let flag: boolean = false;
    for (let val in target) {
      if (target[val]) {
        flag = true;
        break;
      } else {
        flag = false;
      }
    }
    return flag;
  };
  const subForm = () => {
    userAccessForm.validateFields().then((values) => {
      let obj: any = {
        ...values,
      };
      obj.userContent.products["showProduct"] = getStatus(
        values?.userContent?.products
      );
      obj.userContent.master["showMaster"] = getStatus(
        values?.userContent?.master
      );
      obj.userContent.dashboard["showDashboard"] = getStatus(
        values?.userContent?.dashboard
      );
      obj.userContent.construction["showConstruction"] =
        values?.userContent?.construction?.showPurchase ||
        getStatus(values?.userContent?.construction?.master);
      obj.userContent.construction.master["showMaster"] = getStatus(
        values?.userContent?.construction?.master
      );
      // obj.userContent.cab["showCab"] = getStatus(values?.userContent?.cab);
      obj.userContent.cab["showCab"] =
        getStatus(values?.userContent?.cab?.master) ||
        getStatus(values?.userContent?.cab?.trip);
      obj.userContent.cab.master["showMaster"] = getStatus(
        values?.userContent?.cab?.master
      );
      obj.userContent.cab.trip["showTrip"] = getStatus(
        values?.userContent?.cab?.trip
      );
      obj.userContent.attendance["showAttendance"] = getStatus(
        values?.userContent?.attendance
      );
      console.log(obj);

      UserRights.post(obj).then((res: any) => {
        if (res?.status) {
          info(res?.result?.role + " added successfully ");
          onDismiss();
          setShouldRefresh((x: any) => !x);
        } else {
          messageApi.error(res?.message);
        }
      });
    });
  };
  const updateFrom = () => {
    userAccessForm.validateFields().then((values) => {
      let obj: any = {
        ...values,
        id: selectedRecord?.id,
      };

      obj.userContent.products["showProduct"] = getStatus(
        values?.userContent?.products
      );
      obj.userContent.master["showMaster"] = getStatus(
        values?.userContent?.master
      );
      obj.userContent.dashboard["showDashboard"] = getStatus(
        values?.userContent?.dashboard
      );
      obj.userContent.construction["showConstruction"] =
        values?.userContent?.construction?.showPurchase ||
        getStatus(values?.userContent?.construction?.master);
      obj.userContent.construction.master["showMaster"] = getStatus(
        values?.userContent?.construction?.master
      );
      obj.userContent.attendance["showAttendance"] = getStatus(
        values?.userContent?.attendance
      );
      obj.userContent.cab["showCab"] =
        getStatus(values?.userContent?.cab?.master) ||
        getStatus(values?.userContent?.cab?.trip);
      obj.userContent.cab.master["showMaster"] = getStatus(
        values?.userContent?.cab?.master
      );
      obj.userContent.cab.trip["showTrip"] = getStatus(
        values?.userContent?.cab?.trip
      );
      obj.userContent.attendance["showAttendance"] = getStatus(
        values?.userContent?.attendance
      );
      UserRights.post(obj).then((res: any) => {
        if (res?.status) {
          info(res?.result?.role + " Updated successfully ");
          onDismiss();
        } else {
          messageApi.error(res?.message);
        }
      });
    });
  };
  return (
    <>
      {contextHolder}
      <Card
        title={"User Access Rights"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setOpenDrawer(true);
                    setShow(true);
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          // className="InvTable"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            onChange: (page, pageSize) => {
              changeListParams("start", (page - 1) * pageSize);
              changeListParams("length", pageSize);
              setShouldRefresh((x) => !x);
            },
            pageSizeOptions: pageSizeOption,

            position: ["bottomRight"],
          }}
          scroll={{ y: `calc(100vh - 300px)` }}
        />
        <Drawer
          title={
            show === true ? (
              <div className="pt-2">ADD USER ACCESS RIGHT</div>
            ) : (
              <div className="pt-2">{`Update User Access of ${selectedRecord?.role}`}</div>
            )
          }
          width="40vw"
          zIndex={1005}
          onClose={() => {
            onDismiss();
            // userAccessForm.resetFields();
          }}
          open={openDrawer}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          extra={
            <Button
              icon={<CloseOutlined />}
              size="small"
              onClick={() => {
                onDismiss();
                setShow(false);
              }}
              className="border-none"
            />
          }
          className="InvDetailsDrawer"
          footer={
            <>
              {show === true ? (
                <>
                  <Button
                    className="Inv-SaveAndNewBtn"
                    // htmlType="button"
                    type="primary"
                    onClick={() => {
                      subForm();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    // htmlType="button"
                    className="Inv-SaveAndNewBtn"
                    onClick={() => {
                      onDismiss();
                      setShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="Inv-SaveAndNewBtn"
                    // htmlType="button"
                    type="primary"
                    onClick={() => {
                      updateFrom();
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    // htmlType="button"
                    className="Inv-SaveAndNewBtn"
                    onClick={() => {
                      onDismiss();
                      setShow(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </>
          }
        >
          {/* <Divider orientation="left"></Divider> */}
          <Form
            form={userAccessForm}
            labelAlign="left"
            // className="InvAddForm"
            size="large"
            colon={false}
          >
            <Row justify={"space-around"}>
              <Col md={12}>
                <Form.Item
                  label="Role"
                  name="role"
                  labelCol={{ span: 5 }}
                  rules={[
                    { required: true },
                    {
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Role"
                    readOnly={show === false}
                    ref={inputRef}
                  />
                </Form.Item>
              </Col>
              <Col md={1}></Col>
              <Col md={8}></Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              PRODUCTS
            </Divider>

            <Row justify={"space-around"}>
              <Col md={6} xs={24}>
                <Form.Item
                  labelCol={{ span: 20 }}
                  name={["userContent", "products", "cleaning"]}
                  // label="Cleaning"
                  valuePropName="checked"
                >
                  <Checkbox>Cleaning </Checkbox>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 20 }}
                  name={["userContent", "products", "stationery"]}
                  // label="Stationery"
                  valuePropName="checked"
                >
                  <Checkbox>Stationery </Checkbox>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 20 }}
                  name={["userContent", "products", "gardenOrPlants"]}
                  // label="Garden Or Plants"
                  valuePropName="checked"
                >
                  <Checkbox>Garden Or Plants </Checkbox>
                </Form.Item>
              </Col>
              <Col md={2}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>

              <Col md={6} xs={24}>
                <Form.Item
                  labelCol={{ span: 15 }}
                  name={["userContent", "products", "paidSoftware"]}
                  // label="Paid Software"
                  valuePropName="checked"
                >
                  <Checkbox>Paid Software </Checkbox>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 15 }}
                  name={["userContent", "products", "digitalProduct"]}
                  // label="Digital Product"
                  valuePropName="checked"
                >
                  <Checkbox>Digital Product </Checkbox>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 15 }}
                  name={["userContent", "products", "officeEquipments"]}
                  // label="Office Equipments"
                  valuePropName="checked"
                >
                  <Checkbox>Office Equipments</Checkbox>
                </Form.Item>
              </Col>
              <Col md={2}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>
              <Col>
                <Form.Item
                  labelCol={{ span: 1 }}
                  name={["userContent", "products", "emergencyMedicalKit"]}
                  // label="Emergency Medical Kit"
                  valuePropName="checked"
                >
                  <Checkbox>Emergency Medical Kit</Checkbox>
                </Form.Item>
                {/* <Form.Item
                labelCol={{ span: 1 }}
                name={["userContent", "products", "construction"]}
                // label="Emergency Medical Kit"
                valuePropName="checked"
              >
                <Checkbox>Construction</Checkbox>
              </Form.Item> */}
              </Col>
            </Row>
            <Divider
              orientation="left"
              orientationMargin="0"
              // style={{ margin: "10px" }}
            >
              MASTER
            </Divider>

            <Row gutter={35}>
              <Col md={6} xs={24}>
                <Form.Item
                  labelCol={{ span: 11 }}
                  name={["userContent", "master", "unit"]}
                  // label="Unit"
                  valuePropName="checked"
                >
                  <Checkbox>Unit </Checkbox>
                </Form.Item>
                <Form.Item
                  labelCol={{ span: 11 }}
                  name={["userContent", "master", "option"]}
                  // label="Option"
                  valuePropName="checked"
                >
                  <Checkbox>Option</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 11 }}
                  name={["userContent", "master", "variant"]}
                  // label="Variant"
                  valuePropName="checked"
                >
                  <Checkbox>Variant</Checkbox>
                </Form.Item>
                {/* <Form.Item
                // labelCol={{ span: 11 }}
                name={["userContent", "master", "materials"]}
                // label="Variant"
                valuePropName="checked"
              >
                <Checkbox>Materials</Checkbox>
              </Form.Item> */}
              </Col>
              <Col md={2}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  // labelCol={{ span: 17 }}
                  name={["userContent", "master", "seating"]}
                  // label="Seating"
                  valuePropName="checked"
                >
                  <Checkbox>Seating</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 17 }}
                  name={["userContent", "master", "subCategory"]}
                  // label="SubCategory"
                  valuePropName="checked"
                >
                  <Checkbox>SubCategory</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 17 }}
                  name={["userContent", "master", "productGroup"]}
                  // label="Product Group"
                  valuePropName="checked"
                >
                  <Checkbox>Product Group</Checkbox>
                </Form.Item>
                {/* <Form.Item
                // labelCol={{ span: 17 }}
                name={["userContent", "master", "constructionItem"]}
                // label="Product Group"
                valuePropName="checked"
              >
                <Checkbox>Items</Checkbox>
              </Form.Item> */}
              </Col>
              <Col md={2}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 14 }}
                  name={["userContent", "master", "assignee"]}
                  // label="Assignee"
                  valuePropName="checked"
                >
                  <Checkbox>Assignee</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 14 }}
                  name={["userContent", "master", "designation"]}
                  // label="Designation"
                  valuePropName="checked"
                >
                  <Checkbox>Designation</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 14 }}
                  name={["userContent", "master", "department"]}
                  // label="Department"
                  valuePropName="checked"
                >
                  <Checkbox>Department</Checkbox>
                </Form.Item>
                {/* <Form.Item
                // labelCol={{ span: 14 }}
                name={["userContent", "master", "measurement"]}
                // label="Department"
                valuePropName="checked"
              >
                <Checkbox>Measurement</Checkbox>
              </Form.Item> */}
              </Col>
            </Row>

            <Divider orientation="left" orientationMargin="0">
              DASHBOARD
            </Divider>
            <Row justify={"start"}>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "dashboard", "showDashboardTab3"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Approval</Checkbox>
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 8 }}
                  name={["userContent", "dashboard", "showDashboardTab1"]}
                  // label="Dashboard"
                  valuePropName="checked"
                >
                  <Checkbox>Dashboard</Checkbox>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "dashboard", "showDashboardTab2"]}
                  // label="Recent Updates"
                  valuePropName="checked"
                >
                  <Checkbox>Recent Updates</Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Divider orientation="left" orientationMargin="0">
              PROFILE
            </Divider>
            <Row justify={"start"}>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 16 }}
                  name={["userContent", "profile", "showSetting"]}
                  // label="Setting"
                  valuePropName="checked"
                >
                  <Checkbox>Setting</Checkbox>
                </Form.Item>
              </Col>

              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 16 }}
                  name={["userContent", "profile", "showTeamMember"]}
                  // label="Team Member"
                  valuePropName="checked"
                >
                  <Checkbox>Team Member</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              CONSTRUCTION
            </Divider>
            <Row justify={"start"}>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "construction", "showPurchase"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Purchase</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              CONSTRUCTION MASTER
            </Divider>
            <Row justify={"start"}>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "construction", "master", "gst"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>GST</Checkbox>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={[
                    "userContent",
                    "construction",
                    "master",
                    "cMaterialType",
                  ]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Category</Checkbox>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "construction", "master", "cItems"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>SubCategory</Checkbox>
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={[
                    "userContent",
                    "construction",
                    "master",
                    "cMeasurement",
                  ]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Measurement</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              ATTENDANCE
            </Divider>
            <Row justify={"start"}>
              <Col md={7} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "attendance", "showUserCategory"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>UserCategory Master</Checkbox>
                </Form.Item>
              </Col>
              <Col md={1}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "attendance", "showAttendanceSheet"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox> Attendance Sheet</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              CAB MASTER
            </Divider>
            <Row justify={"start"}>
              <Col md={7} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "master", "vehicle"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Vehicle</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "master", "driver"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Driver</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "master", "shift"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Shift</Checkbox>
                </Form.Item>
              </Col>
              <Col md={1}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "master", "round"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Round</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "master", "passengerType"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Passenger Type</Checkbox>
                </Form.Item>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "master", "status"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Status</Checkbox>
                </Form.Item>
              </Col>
              <Col md={3}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>
              <Col md={6}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "master", "passenger"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Passenger</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              CAB TRIP
            </Divider>
            <Row justify={"start"}>
              <Col md={7} xs={24}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "trip", "tripSchedule"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Trip Schedule</Checkbox>
                </Form.Item>
              </Col>
              <Col md={1}>
                <Divider type="vertical" style={{ height: "100%" }}></Divider>
              </Col>
              <Col md={7}>
                <Form.Item
                  // labelCol={{ span: 6 }}
                  name={["userContent", "cab", "trip", "tripLogs"]}
                  // label="Approval"
                  valuePropName="checked"
                >
                  <Checkbox>Trip Logs</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              SERVICE
            </Divider>
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  name={["userContent", "service", "showService"]}
                  // label="Service"
                  valuePropName="checked"
                >
                  <Checkbox>Service</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              VENDOR
            </Divider>
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  name={["userContent", "vendor", "showVendor"]}
                  // label="Vendor"
                  valuePropName="checked"
                >
                  <Checkbox>Vendor</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left" orientationMargin="0">
              REPORT
            </Divider>
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  name={["userContent", "report", "showReport"]}
                  // label="Report"
                  valuePropName="checked"
                >
                  <Checkbox>Report</Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Card>
    </>
  );
};
export default UserAccessRights;
