/* eslint-disable */

import {
  message,
  Input,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
  Spin,
  Popconfirm,
  Divider,
  Tag,
  Card,
  Space,
} from "antd";
const { Search } = Input;
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useRef, useState } from "react";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import MasterHistory from "../MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";

const Variant = () => {
  const inputRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const { getVariants } = useContext(ApplicationContext);
  const [variantForm] = Form.useForm();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [tableDataOriginal, setTableDataOriginal] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState("close");
  const [recordVal, setRecordVal] = useState<any>({});
  const [exist, setExist] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const [listParams, setListParams] = useState<any>({
    variantSearch: "",
    company: "",
    start: 0,
    length: 15,
  });
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [show, setShow] = useState<boolean>(false);

  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  // Message on Already exist value
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Variant Is Assigned  Can't be deleted",
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setShowDrawer("");
    setFieldChange(false);
    setLoading(false);
    variantForm.resetFields();
  };

  const subVariant = (saveTog: boolean) => {
    variantForm.validateFields().then((values) => {
      MasterService.postVariant(values).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            getVariants();
            onDismiss();
          } else {
            variantForm.resetFields();
            setShouldRefresh((x) => !x);
          }
        } else {
          errorMsg(res?.message);
        }
      });
    });
  };
  //get list of variants
  const getVariants1 = (listParams: any) => {
    setLoading(true);
    MasterService.getVariant(
      listParams.variantSearch,
      listParams.start,
      listParams.length
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items?.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r.id,
            label: r?.name,
            value: r?.id,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setTableDataOriginal([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const editRowData = (editList: any) => {
    MasterService.getVariantById(editList?.value).then(async (res: any) => {
      variantForm.setFieldsValue(res);
      setEditData(res);
      setShow(true);
      setRecordVal(editList);
      setUpdateToggle("show");
      // variantForm.setFieldValue("name", editList.label);
      await MasterService.isExist(Masters.Variant, editList.value).then(
        (res) => {
          if (res === true) {
            setExist(true);
          } else {
            setExist(false);
          }
        }
      );
    });
  };

  const updateData = () => {
    let values = variantForm.getFieldsValue(true);
    if (fieldChange) {
      variantForm.validateFields().then(async () => {
        let val = {
          id: recordVal.value,
          name: values.name.trim(),
        };
        await MasterService.postVariant(val).then((res: any) => {
          if (res?.message) {
            messageApi.error(res?.message);
          } else {
            onDismiss();
            setUpdateToggle("close");
            info("Updated Successfully");
            getVariants();
            setSelectedRowKeys("");
          }
        });
      });
    } else {
      setShow(false);
    }

    // MasterService.postVariant(val).then(() => {
    //   setUpdateToggle("close");
    //   onDismiss();
    //   info("Updated Successfully");
    // });
  };

  const deleteList = (listId: string) => {
    MasterService.isExist(Masters.Variant, listId).then((res) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getVariantById(listId).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteVariant(listId).then((res) => {
              if (res.status) {
                getVariants();
                // errorMsg("Variant is Deleted Successfully.");
                messageApi.success("Variant is deleted successfully.");
                onDismiss();
              }
            });
          } else {
            messageApi.warning("Variant added by admin can't be deleted.");
          }
        });
      }
    });
  };
  const searchVariant = (e: any) => {
    setListParams({
      variantSearch: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };

  useEffect(() => {
    getVariants1(listParams);
    inputRef.current?.focus();
  }, [shouldRefresh, listParams]);

  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === variantForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };

  // COLUMNS For Table
  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">VARIANT</span> */}
          <Search
            placeholder="Variant"
            className="InvSearchInput"
            allowClear
            size="small"
            bordered={false}
            onSearch={(e: any) => searchVariant(e)}
          />
        </div>
      ),
      dataIndex: "label",
      width: 240,

      render: (itm: any, record: any) => (
        <span
          // className="p-0 text-dark fw500"
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
    },
    {
      title: <div className="">Date </div>,

      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: "  ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            {/* Edit */}
          </Button>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record.value)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button icon={<DeleteOutlined />} type="text" danger size="small">
              {/* Delete */}
            </Button>
          </Popconfirm>
          {
            <Button
              type="text"
              icon={<HistoryOutlined />}
              size="small"
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              {/* History */}
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Variant"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow(true)}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {/* <Row style={{ marginBottom: "10px" }} justify="end">
          <Col>
            <Button type="primary" onClick={() => setShow(true)}>
              Add New
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col lg={24}>
            <Table
              size="small"
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            variantForm.resetFields();
            setUpdateToggle("close");
            setSelectedRowKeys("");
          }}
          zIndex={1005}
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // title={updateToggle === "close" ? "Add Variant" : "Update Variant"}
          title={
            updateToggle === "close" ? (
              "Add Variant"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Variant
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Variant"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag> */}
              </>
            )
          }
          // onOk={() => {
          //   subVariant();
          // }}
          footer={
            <>
              {updateToggle === "close" ? (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        variantForm.resetFields();
                        setUpdateToggle("close");
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        subVariant(true);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => {
                        subVariant(false);
                      }}
                    >
                      Save and New
                    </Button>
                  </Space>
                </div>
              ) : (
                <div>
                  <Space>
                    <Button
                      danger
                      onClick={() => {
                        setShow(false);
                        variantForm.resetFields();
                        setUpdateToggle("close");
                        setSelectedRowKeys("");
                      }}
                    >
                      Cancel
                    </Button>
                    {/* 
                <Popconfirm
                  title={
                    exist === true
                      ? "Do you wan't to update this assigned Variant"
                      : "Update Variant"
                  }
                  // onConfirm={() =>  }
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  onConfirm={() => updateData()}
                  onCancel={() => {
                    setShow(false);
                    variantForm.resetFields();
                    setUpdateToggle("close");
                  }}
                > */}
                    <Button
                      type="primary"
                      ghost
                      onClick={() => updateData()}
                      disabled={!editData?.isEditAllowed}
                    >
                      Update
                    </Button>
                  </Space>
                  {/* </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          {/* /^\S[\S\s]*$/ */}
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="variantBasic"
            className="InvAddForm"
            layout="vertical"
            form={variantForm}
            size="large"
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              label="Enter Variant"
              name="name"
              required
              rules={[
                {
                  // pattern: /^\b[a-zA-Z][^\s\W]*$/,
                  pattern: pattern,
                  message:
                    "Spaces, digits, and special symbols are not allowed at beginning or end of the input",
                },
                {
                  required: true,
                  message: "Please fill the field",
                },
                // {
                //   whitespace: true,
                // },
              ]}
            >
              <Input
                placeholder="Enter Variant"
                ref={inputRef}
                onBlur={() => {
                  CheckChanges("name");
                }}
              />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Variant}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Variant;
