import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  // Select,
  Space,
  message,
} from "antd";
import {
  CloseOutlined,
  // CloseSquareTwoTone,
  EditOutlined,
  // EditTwoTone,
  SaveOutlined,
  // SaveTwoTone,
} from "@ant-design/icons";
import ProductService, {
  Category,
  IProductApiProps,
} from "../../Services/ProductService";
import { Utils } from "../../../Utilities/Utils";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import { dayjs } from "../../../Utilities/dayjs";
// import { ProductStatusFlag } from "../../Context/ApplicationContext/ApplicationState";
import TextArea from "antd/es/input/TextArea";
import MasterService from "../../Services/MasterService";
const { RangePicker } = DatePicker;

interface IEditProductProps {
  productData: IProductApiProps;
  setIsDataSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  // subCategoryOptions: any;
}
const EditPaidSoftware = (props: IEditProductProps) => {
  const [paidSoftwareForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [editData, setEditData] = useState<any>({});
  const [enableEdit, setEnableEdit] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);

  const { selectAfter, currency, setCurrency, gstOption } =
    useContext(ApplicationContext);
  // console.log(props?.productData, "00");
  const getSubCategory = () =>
    MasterService.getSubCategoryOptions(Category.PaidSoftware, "").then(
      (res) => {
        setSubCategoryOptions(
          res?.map((r: any) => ({
            label: r.name,
            value: r.id,
          }))
        );
      }
    );

  useEffect(() => {
    getSubCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setEditData({
      ...props?.productData,
      // expiryDate:
      //   props?.productData?.expiryDate === "0001-01-01T00:00:00Z" ||
      //     props?.productData?.expiryDate === "0001-01-01T00:00:00"
      //     ? undefined
      //     : dayjs(new Date(props?.productData?.expiryDate)),
      purchaseDate:
        props?.productData?.purchaseDate === "0001-01-01T00:00:00Z" ||
        props?.productData?.purchaseDate === "0001-01-01T00:00:00"
          ? undefined
          : dayjs(new Date(props?.productData?.purchaseDate)),
      // subscription: {
      //   startDate:
      //     props?.productData?.subscription?.startDate ===
      //       "0001-01-01T00:00:00Z" ||
      //     props?.productData?.subscription?.startDate === "0001-01-01T00:00:00"
      //       ? undefined
      //       : dayjs(new Date(props?.productData?.subscription?.startDate)),
      //   endDate: props?.productData?.subscription?.endDate,
      // },
      subscription: [
        dayjs(props?.productData?.subscription?.startDate),

        dayjs(props?.productData?.subscription?.endDate),
      ],
      subCategory: {
        label: props?.productData?.subCategory?.name,
        value: props?.productData?.subCategory?.id,
      },
      gst: {
        label: props?.productData?.gst?.name,
        value: props?.productData?.gst?.id,
      },
    });
    // paidSoftwareForm.setFieldValue("subscription", [
    //   dayjs(props?.productData?.subscription?.startDate),
    //   dayjs(props?.productData?.subscription?.startDate),
    // ]);
    setCurrency(props?.productData?.currency);
  }, [props?.productData]);

  useEffect(() => {
    paidSoftwareForm.setFieldsValue(editData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, enableEdit]);

  const saveUpdatedData = () => {
    // setBtnDisable(true);
    paidSoftwareForm.validateFields().then(async (values) => {
      let val = {
        ...values,
        id: editData?.id,
        currency: currency?.value,
        // expiryDate: values?.expiryDate,
        // purchaseDate: values?.purchaseDate,
        gst: {
          id: values?.gst?.value,
          name: values?.gst?.label,
        },
        subscription:
          values?.subscription === undefined
            ? null
            : {
                startDate: values?.subscription[0],
                endDate: values?.subscription[1],
              },
        subCategory: {
          id: values?.subCategory?.value,
          name: values?.subCategory?.label,
        },
      };
      // console.log(val, "val");

      await ProductService.post(val).then((res) => {
        if (res?.status) {
          setEnableEdit(false);
          setBtnDisable(false);
          messageApi.success("PaidSoftware InFormation Updated Successfully");
          props?.setShouldRefresh((x: boolean) => !x);
          props?.setIsDataSaved(true);
        } else {
          setEnableEdit(false);
          setBtnDisable(false);
          messageApi.error(res?.message);
        }
      });
    });
  };
  const calAmount = () => {
    paidSoftwareForm.setFieldValue(
      "amount",
      parseFloat(
        (
          paidSoftwareForm.getFieldValue("numberOfStock") *
          (paidSoftwareForm.getFieldValue("pricePerUnit") === 0
            ? 1
            : paidSoftwareForm.getFieldValue("pricePerUnit")) *
          (paidSoftwareForm.getFieldValue("inrRate") === 0
            ? 1
            : paidSoftwareForm.getFieldValue("inrRate"))
        ).toFixed(2)
      )
        ? parseFloat(
            (
              paidSoftwareForm.getFieldValue("numberOfStock") *
              (paidSoftwareForm.getFieldValue("pricePerUnit") === 0
                ? 1
                : paidSoftwareForm.getFieldValue("pricePerUnit")) *
              (paidSoftwareForm.getFieldValue("inrRate") === 0
                ? 1
                : paidSoftwareForm.getFieldValue("inrRate"))
            ).toFixed(2)
          )
        : 0
    );
    // paidSoftwareForm.setFieldValue("amount", paidSoftwareForm.getFieldValue("quantity") * paidSoftwareForm.getFieldValue("rate"))
    paidSoftwareForm.setFieldValue(
      "totalPrice",
      parseFloat(
        (
          (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
            paidSoftwareForm.getFieldValue("amount") +
          paidSoftwareForm.getFieldValue("amount")
        ).toFixed(2)
      )
        ? parseFloat(
            (
              (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
                paidSoftwareForm.getFieldValue("amount") +
              paidSoftwareForm.getFieldValue("amount")
            ).toFixed(2)
          )
        : 0
    );
    paidSoftwareForm.setFieldValue(
      "gstAmount",
      parseFloat(
        (
          (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
          paidSoftwareForm.getFieldValue("amount")
        ).toFixed(2)
      )
        ? parseFloat(
            (
              (parseFloat(paidSoftwareForm.getFieldValue("gst")?.label) / 100) *
              paidSoftwareForm.getFieldValue("amount")
            ).toFixed(2)
          )
        : 0
    );
  };
  return (
    <>
      {contextHolder}
      <Row justify={"space-between"} align={"bottom"} >
        <Col className="font16 fw600">Basic Details</Col>
        {enableEdit ? (
          <Col>
            <Space>
              <Button
                // icon={<SaveTwoTone />}
                icon={<SaveOutlined />}
                size="small"
                // className="border-none font16"
                // style={{ border: "1px solid #1677ff" }}
                onClick={saveUpdatedData}
                disabled={btnDisable}
              />
              <Button
                // icon={<CloseSquareTwoTone />}
                icon={<CloseOutlined />}
                size="small"
                // className="border-none font16"
                onClick={() => {
                  setEnableEdit(false);
                }}
                disabled={btnDisable}
              />
            </Space>
          </Col>
        ) : (
          <Col>
            <Button
              // icon={<EditTwoTone />}
              icon={<EditOutlined />}
              size="small"
              // className="border-none font16"
              onClick={() => setEnableEdit(true)}
              // disabled={
              //   (props.isPending === ProductStatusFlag.Pending
              //     ? true
              //     : false) || !editData?.isEditAllowed
              // }
            />
          </Col>
        )}
      </Row>
      <Form
        form={paidSoftwareForm}
        colon={false}
        // size="small"
        wrapperCol={{ span: 14 }}
        labelCol={{ span: 5 }}
        className="InvDForm"
        labelAlign="left"
        requiredMark={false}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          // rules={[{ required: true, message: "Field can't be empty." }]}
          // required={false}
        >
          {enableEdit ? <Input autoFocus /> : editData?.name}
        </Form.Item>
        <Form.Item
          label="Sub Category"
          name="subCategory"
          // rules={[
          //   {
          //     required: true,
          //     message: "Select GST",
          //   },
          // ]}
        >
          {enableEdit ? (
            <Select
              // onSelect={() => calAmount()}
              placeholder="Select Sub Category"
              style={{ width: "100%" }}
              labelInValue
              allowClear
              options={subCategoryOptions}
              listHeight={224}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          ) : editData?.subCategory?.label === undefined || null || "" ? (
            "-"
          ) : (
            editData?.subCategory?.label
          )}
        </Form.Item>
        <Form.Item
          label="Project Name"
          name="projectName"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input project name",
          //   },
          //   {
          //     whitespace: true,
          //   },
          // ]}
        >
          {enableEdit ? (
            <Input placeholder="Enter ProjectName Name" />
          ) : (
            editData?.projectName
          )}
        </Form.Item>
        <Form.Item
          label="Subscription"
          name="subscription"
          // rules={[
          //   {
          //     required: true,
          //     message: "Select Subscription Date",
          //   },
          // ]}
        >
          {enableEdit ? (
            <RangePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          ) : (
            ` ${Utils.getFormattedDate(
              editData?.subscription?.[0]
            )}  =>   ${Utils.getFormattedDate(editData?.subscription?.[1])} `
          )}
        </Form.Item>
        <Form.Item label="Invoice Number" name="invoiceNumber">
          {enableEdit ? (
            <Input />
          ) : editData?.invoiceNumber !== null ? (
            editData?.invoiceNumber
          ) : (
            "-"
          )}
        </Form.Item>
        <Form.Item label="Purchase Date" name="purchaseDate">
          {enableEdit ? (
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              onChange={(val: any) => {
                if (val === null) {
                  paidSoftwareForm.setFieldValue("purchaseDate", undefined);
                }
              }}
            />
          ) : (
            Utils.getFormattedDate(editData?.purchaseDate, "-", false)
          )}
        </Form.Item>
        {/* <Form.Item label="Expiry Date" name="expiryDate">
          {enableEdit ? (
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              onChange={(val: any) => {
                if (val === null) {
                  paidSoftwareForm.setFieldValue("expiryDate", undefined);
                }
              }}
            />
          ) : (
            Utils.getFormattedDate(editData?.expiryDate, "-", false)
          )}
        </Form.Item> */}

        <Form.Item
          label="Qty"
          name="numberOfStock"
          rules={[
            // {
            //   required: true,
            //   message: "Enter Qty",
            // },
            {
              pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
              message: "Please enter number upto two decimal places only!",
            },
          ]}
        >
          {enableEdit ? (
            <InputNumber
              style={{ width: "100%" }}
              onChange={() => {
                calAmount();
              }}
            />
          ) : (
            editData?.numberOfStock
          )}
        </Form.Item>

        <Form.Item
          label="Rate"
          name="pricePerUnit"
          rules={[
            // {
            //   required: true,
            //   message: "Enter Rate",
            // },
            {
              pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
              message: "Please enter number upto two decimal places only!",
            },
          ]}
        >
          {enableEdit ? (
            <InputNumber
              style={{ width: "100%" }}
              addonAfter={selectAfter}
              onChange={() => {
                calAmount();
              }}
            />
          ) : (
            editData?.pricePerUnit
          )}
        </Form.Item>
        <Form.Item
          label="INR Rate"
          name="inrRate"
          rules={[
            // {
            //   required: true,
            //   message: "Enter INR Rate",
            // },
            {
              pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
              message: "Please enter number upto two decimal places only!",
            },
          ]}
        >
          {enableEdit ? (
            <InputNumber
              onChange={() => {
                calAmount();
              }}
              style={{ width: "100%" }}
            />
          ) : (
            editData?.inrRate
          )}
        </Form.Item>
        <Form.Item label="Amount" name="amount">
          {enableEdit ? (
            <InputNumber
              readOnly
              placeholder="Amount"
              style={{ width: "100%" }}
            />
          ) : (
            editData?.amount
          )}
        </Form.Item>
        <Form.Item
          label="GST"
          name="gst"
          // rules={[
          //   {
          //     required: true,
          //     message: "Select GST",
          //   },
          // ]}
        >
          {enableEdit ? (
            <Select
              onSelect={() => calAmount()}
              placeholder="Select GST"
              style={{ width: "100%" }}
              labelInValue
              allowClear
              options={gstOption}
              listHeight={224}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          ) : editData?.gst?.label === undefined ? (
            "-"
          ) : (
            `${editData?.gst?.label}%`
          )}
        </Form.Item>

        <Form.Item label="GST Amount" name="gstAmount">
          {enableEdit ? (
            <InputNumber
              type="number"
              controls={false}
              readOnly
              style={{ width: "100%" }}
            />
          ) : (
            editData?.gstAmount
          )}
        </Form.Item>

        <Form.Item label="Total Price" name="totalPrice">
          {enableEdit ? (
            <InputNumber readOnly style={{ width: "100%" }} />
          ) : (
            editData?.totalPrice
          )}
        </Form.Item>

        <Form.Item label="Description" name="description">
          {enableEdit ? (
            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          ) : editData?.description ? (
            editData?.description
          ) : (
            "-"
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default EditPaidSoftware;
